import { logActionError } from '@helpers/logger';
import { ACTION_STATUS as STATUS, DEVFOLIO_COC, SEARCH_KEYS } from '../constants';
import { API } from '../api';
import * as types from '../constants/actions';

/**
 *  Fetch the hackathon data
 * @param {string} hackathonID the uuid of the hackathon
 *
 */
export const fetchHackathonData = hackathonSlug => async dispatch => {
  /**
   * This is fired on component mount or wherever we need to
   * fill the hackathon data
   */
  dispatch(fetchHackathonDataRequest());

  try {
    const { data } = await API.hackathon.fetchHackathonData(hackathonSlug);
    dispatch(fetchHackathonDataSuccess(data));
  } catch (error) {
    dispatch(fetchHackathonDataFailure());
  }
};

const fetchHackathonDataRequest = () => ({
  type: types.FETCH_HACKATHON_DATA_REQUEST,
});

const fetchHackathonDataSuccess = payload => ({
  type: types.FETCH_HACKATHON_DATA_SUCCESS,
  payload,
});

const fetchHackathonDataFailure = () => ({
  type: types.FETCH_HACKATHON_DATA_FAILURE,
});

const fetchHackathonsStatus = payload => ({
  type: types.FETCH_ORGANIZER_HACKATHONS,
  payload,
});

/**
 *  Fetch all the organizer hackathons
 * @param {string} username the organizer's username
 * @param {boolean} shouldFetchAllDetails fetch all the hackathon detials if true
 */
export const fetchHackathons = (username, shouldFetchAllDetails = true) => async dispatch => {
  try {
    dispatch(fetchHackathonsStatus({ status: STATUS.REQUEST }));
    let data = {};
    let hackathons = [];
    // Fetch all the organizer hackathons with detailed info on the main home page
    // (this is required for percentage calculation for the hackathons)
    // and fetch only the bare minimum required info on the hackathon dashboard page
    // TODO: Refactor this to use only the basic details API for fetching organizer hackathons
    if (shouldFetchAllDetails) {
      ({ data } = await API.organizer.getDetailedOrganizerHackathons(username));
      hackathons = data.map(hackathon => mapToHackathon(hackathon));
    } else {
      ({ data } = await API.organizer.getBasicOrganizerHackathons(username));
      hackathons = data.map(hackathon => mapToBasicOrganizerHackathonDetails(hackathon));
    }

    dispatch(fetchHackathonsStatus({ status: STATUS.SUCCESS, data: hackathons }));
  } catch (error) {
    dispatch(fetchHackathonsStatus({ status: STATUS.FAILURE }));
    logActionError(types.FETCH_HACKATHON_DATA_FAILURE, error, { username, shouldFetchAllDetails });
  }
};

/**
 * Map the basic oganizer hackahton details to client
 * @param {object} hackathon the hackathon object
 * @returns mapped hackathon output
 */
const mapToBasicOrganizerHackathonDetails = hackathon => ({
  ...hackathon,
  // Split the tabs string received from the API
  // to find the tabs the organizer can access
  tabs: hackathon?.tabs?.split(',') || [],
});

const sendReminderEmailsStatus = payload => ({
  type: types.SEND_REMINDER_EMAILS,
  payload,
});

export const sendReminderEmails = hackathonSlug => async dispatch => {
  try {
    dispatch(sendReminderEmailsStatus({ status: STATUS.REQUEST }));

    const { data } = await API.organizer.sendReminderEmails(hackathonSlug);

    dispatch(
      sendReminderEmailsStatus({
        status: STATUS.SUCCESS,
        lastReminderEmailsSent: data?.reminder_email_sent_at,
      })
    );
  } catch (error) {
    dispatch(sendReminderEmailsStatus({ status: STATUS.FAILURE }));
    logActionError(types.SEND_REMINDER_EMAILS, error, { hackathonSlug });
  }
};

const fetchSearchKeyStatus = payload => ({
  type: types.FETCH_SEARCH_KEYS,
  payload,
});

const keyAPI = {
  [SEARCH_KEYS.ADMIN]: API.hackathon.fetchAdminTabKey,
  [SEARCH_KEYS.VOLUNTEER]: API.hackathon.fetchVolunteerTabKey,
  [SEARCH_KEYS.REVIEW]: API.hackathon.fetchReviewTabKey,
};

/**
 *  Fetch search key for a given tab
 * @param {string} hackathonSlug the hackathons's slug
 * @param {boolean} tab the tab for which the search key needs to be fetched
 */
export const fetchSearchKey = (hackathonSlug, tab) => async dispatch => {
  try {
    dispatch(fetchSearchKeyStatus({ status: STATUS.REQUEST }));
    // Fetch the key for the required tab
    const {
      data: { token },
    } = await keyAPI[tab](hackathonSlug);

    // Store the created at time for the key
    const createdAt = new Date().toISOString();

    const keyData = {
      [tab]: {
        key: token,
        createdAt,
      },
    };

    dispatch(
      fetchSearchKeyStatus({
        status: STATUS.SUCCESS,
        keyData,
      })
    );
  } catch (error) {
    dispatch(fetchSearchKeyStatus({ status: STATUS.FAILURE }));
    logActionError(types.FETCH_SEARCH_KEYS, error, { hackathonSlug, tab });
  }
};

export const mapToHackathon = hackathon => {
  return {
    name: hackathon?.name || '',
    type: hackathon?.type || null,
    tagline: hackathon?.tagline || '',
    about: hackathon?.desc || '',
    approxParticipants: hackathon?.approx_participant || '',
    location: hackathon?.location || '',
    rsvpBeforeTime: hackathon?.hackathon_setting?.rsvp_time / 24 || '',
    minTeamSize: hackathon?.team_min || '',
    maxTeamSize: hackathon?.team_size || '',
    contactEmail: hackathon?.hackathon_setting?.contact_email || '',
    contactEmailVerified: hackathon?.hackathon_setting?.email_verified || false,
    facebook: hackathon?.hackathon_setting?.facebook || '',
    twitter: hackathon?.hackathon_setting?.twitter || '',
    instagram: hackathon?.hackathon_setting?.instagram || '',
    discord: hackathon?.hackathon_setting?.discord || '',
    slack: hackathon?.hackathon_setting?.slack || '',
    telegram: hackathon?.hackathon_setting?.telegram || '',
    farcaster: hackathon?.hackathon_setting?.farcaster || '',
    isOnline: hackathon?.is_online || false,
    isOnlineWithReview: hackathon?.hackathon_setting?.review || false,
    isPaid: hackathon?.hackathon_setting?.paid || false,
    website: hackathon?.hackathon_setting?.site || '',
    linkedin: hackathon?.hackathon_setting?.linkedin || '',
    coc: hackathon?.hackathon_setting?.coc_url || '',
    primaryColor: hackathon?.hackathon_setting?.primary_color || '',
    logo: hackathon?.hackathon_setting?.logo || '',
    favicon: hackathon?.favicon || '',
    cover: hackathon?.cover_img || '',
    applicationsOpenAt: hackathon?.hackathon_setting?.reg_starts_at || '',
    applicationsCloseAt: hackathon?.hackathon_setting?.reg_ends_at || '',
    startsAt: hackathon?.starts_at || '',
    endsAt: hackathon?.ends_at || '',
    timezone: hackathon?.timezone || '',
    status: hackathon?.status || '',
    slug: hackathon?.slug || '',
    uuid: hackathon?.uuid || '',
    useDevfolioCoc: hackathon?.hackathon_setting?.coc_url === DEVFOLIO_COC,
    verified: hackathon?.verified,
    subdomain: hackathon?.hackathon_setting?.subdomain || '',
    role: hackathon?.user_hackathon?.role || '',
    tabs: hackathon?.user_hackathon?.tabs?.split(',') || [],
    winnerAnnounceAt: hackathon?.hackathon_setting?.winner_announce_at || '',
    city: hackathon?.city || '',
    state: hackathon?.state || '',
    country: hackathon?.country || '',
    themes: hackathon?.themes?.map(theme => theme?.name) || [],
    judgingEnabled: hackathon?.hackathon_setting?.judging_enabled,
    onlineJudgingEnabled: hackathon?.hackathon_setting?.online_judging_enabled,
    sponsorJudgingEnabled: hackathon?.hackathon_setting?.sponsor_judging_enabled,
    onChainCredentialsEnabled: hackathon?.hackathon_setting?.on_chain_credentials_enabled,
    kycVerificationDetails: {
      mouSignatoryTeam: hackathon?.hackathon_setting?.mou_signatory_team || '',
      mouSignatoryName: hackathon?.hackathon_setting?.mou_signatory_name || '',
      mouSignatoryTitle: hackathon?.hackathon_setting?.mou_signatory_title || '',
      mouSignatoryEmailId: hackathon?.hackathon_setting?.mou_signatory_email_id || '',
      mouPlaceOfSigning: hackathon?.hackathon_setting?.mou_place_of_signing || '',
      mouSponsorTiers: hackathon?.hackathon_setting?.mou_sponsor_tiers || '',
      organizerPhoneNumber: hackathon?.hackathon_setting?.organizer_phone_number || '',
      website: hackathon?.hackathon_setting?.site || '',
      partnerLogosAdded: hackathon?.hackathon_setting?.partner_logos_added || false,
      applyNowIntegrated: hackathon?.hackathon_setting?.apply_now_integrated || false,
      kycSubmitted: hackathon?.hackathon_setting?.kyc_submitted || false,
    },
    winnersAnnounced: hackathon?.hackathon_setting?.winner_announced || false,
    judges:
      hackathon?.judges?.map(judge => ({
        company: {
          name: judge?.company?.name ?? undefined,
          domain: judge?.company?.domain ?? undefined,
          logo: judge?.company?.logo ?? undefined,
        },
        avatar: judge?.profile_img || '',
        about: judge?.about || '',
        job: judge?.job_title || '',
        email: judge?.email || '',
        type: judge?.type || (hackathon?.hackathon_setting?.online_judging_enabled ? 'ONLINE' : 'OFFLINE'),
        name: judge?.name || '',
        profile: judge?.website || '',
        twitter: judge?.twitter || '',
        linkedin: judge?.linkedin || '',
        instagram: judge?.instagram || '',
        farcaster: judge?.farcaster || '',
        position: judge?.order || 0,
        uuid: judge.uuid,
        featured: judge?.featured || false,
      })) || [],
    events:
      hackathon?.events?.map(event => ({
        uuid: event.uuid,
        title: event?.title || '',
        description: event?.description || '',
        type: event?.type || '',
        url: event?.url || '',
        meetingUrl: event?.meeting_url || '',
        startsAt: event?.starts_at || '',
        endsAt: event?.ends_at || '',
        location: event?.location || '',
        googleMapsLocationURL: event?.google_maps_location_url || '',
        groupUUID: event?.hackathon_event_group?.uuid || undefined,
        speakers:
          event?.speakers?.map(({ speaker }) => ({
            uuid: speaker.uuid,
            name: speaker?.name || '',
            twitter: speaker?.twitter || '',
            telegram: speaker?.telegram || '',
            slack: speaker?.slack || '',
            profile: speaker?.website || '',
            order: speaker?.order || 0,
            medium: speaker?.medium || '',
            linkedin: speaker?.linkedin || '',
            jobTitle: speaker?.job_title || '',
            instagram: speaker?.instagram || '',
            facebook: speaker?.facebook || '',
            discord: speaker?.discord || '',
            profileImg: speaker?.profile_img || '',
          })) || [],
      })) || [],
    eventGroups: hackathon?.event_groups?.map(grp => ({ uuid: grp.uuid, name: grp.name, order: grp.order })) ?? [],
    tracks: hackathon?.tracks || [],
  };
};
