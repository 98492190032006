import { combineReducers } from 'redux';

import admin from './admin';
import analytics from './analytics';
import authentication from './authentication';
import checkin from './checkin';
import fare from './fare';
import feedback from './feedback';
import filter from './filter';
import hackathon from './hackathon';
import organizer from './organizer';
import participant from './participant';
import quiz from './quiz';
import root from './root';
import squad from './squad';
import ticket from './ticket';
import user from './user';
import logistics from './logistics';

import * as searchReducers from './search';

const app = combineReducers({
  ...searchReducers,
  admin,
  analytics,
  authentication,
  checkin,
  fare,
  feedback,
  filter,
  hackathon,
  organizer,
  participant,
  quiz,
  root,
  squad,
  ticket,
  user,
  logistics,
});

export default app;
