import React from 'react';
import Media from 'react-media';
import { matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from '@devfolioco/genesis';
import { toast } from 'react-toastify';
import { SERVER_ERRORS } from '@constants/errors';
import 'react-toastify/dist/ReactToastify.css';

class OfflinePlugin extends React.Component {
  state = { isOnline: window ? window.navigator.onLine : false };

  offlinetoastId = null;

  componentDidMount() {
    const { history } = this.props;
    this.updateNetworkStatusEventListeners(history.location);
    this.handleOAuthErrors();
    /**
     * @description This function will listen for path change
     */
    history.listen(this.updateNetworkStatusEventListeners);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!snapshot) {
      return;
    }
    const { isOnline } = snapshot;
    const content = (
      <div>
        <strong>{isOnline ? 'ONLINE' : 'OFFLINE'}</strong>
        <div>{isOnline ? 'Back Online' : 'Changes you make will not be saved'}</div>
      </div>
    );

    const toastId = toast(
      content,
      Object.assign(
        {
          autoClose: isOnline ? 2000 : false,
          onOpen: isOnline ? this.onlineCallback : () => this.offlineCallback(toastId),
          closeButton: isOnline,
        },
        !isOnline && { type: toast.TYPE.ERROR }
      )
    );
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { isOnline } = this.state;

    if (prevState.isOnline !== isOnline) {
      return { isOnline };
    }

    return null;
  }

  /**
   *
   * @description Conditionally add netweork status event listener to the window
   */
  updateNetworkStatusEventListeners = ({ pathname }) => {
    const includedPaths = ['/new/:hackathonUuid', '/update/:hackathonUuid'];
    if (matchPath(pathname, { path: includedPaths })) {
      this.addNetworkStatusEventListeners();
    } else {
      this.removeNetworkStatusEventListeners();
    }
  };

  /**
   *
   * @description Add network status event listener to the window
   */
  addNetworkStatusEventListeners = () => {
    window.addEventListener('offline', this.setOffline);
    window.addEventListener('online', this.setOnline);
  };

  /**
   *
   * @description Remove network status event listener to the window
   */
  removeNetworkStatusEventListeners = () => {
    window.removeEventListener('offline', this.setOffline);
    window.removeEventListener('online', this.setOnline);
  };

  setOnline = () => {
    this.setState({ isOnline: true });
  };

  setOffline = () => {
    this.setState({ isOnline: false });
  };

  onlineCallback = () => {
    toast.dismiss(this.offlineToastId);
    this.offlineToastId = null;
  };

  offlineCallback = toastId => {
    this.offlineToastId = toastId;
  };

  handleOAuthErrors = () => {
    const { location, history } = this.props;
    const urlSearchParams = new URLSearchParams(location.search);
    const error = urlSearchParams.get('error');
    const isFromDiscordConnect = urlSearchParams.get('has_connected_discord');
    if (error === SERVER_ERRORS.accountAlreadyConnected && typeof isFromDiscordConnect === 'string') {
      const content = (
        <div>
          <strong>Discord Already linked</strong>
          <div style={{ marginTop: 4 }}>
            This Discord account is already linked to a different Devfolio account. You will either need to disconnect
            it from the original account or connect to a different Discord account.
          </div>
        </div>
      );
      toast(content, {
        type: toast.TYPE.ERROR,
        bodyStyle: {
          width: '470px',
        },
        autoClose: 7000,
      });

      history.replace({
        pathname: location.pathname,
        search: '',
      });
    }
  };

  render() {
    const { children } = this.props;
    return (
      <Media query="(max-width: 767px)">
        {matches => (
          <>
            <ToastContainer style={{ width: matches ? '350px' : '450px' }} />
            {children}
          </>
        )}
      </Media>
    );
  }
}

export default withRouter(OfflinePlugin);
