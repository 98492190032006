import { Logo } from '@devfolioco/genesis';
import Media from 'react-media';
import React from 'react';
import Title from 'react-document-title';

import FiveHundred from '../../../public/assets/500-desktop.png';
import FiveHundredMobile from '../../../public/assets/500-mobile.png';

import { Container, Text } from './style';

const ServerError = () => (
  <Title title="500 | Devfolio">
    <Container>
      <Logo style={{ marginBottom: 16 }} />
      <Media
        query="(max-width: 767px)"
        render={() => (
          <>
            <img src={FiveHundredMobile} alt="Internal Server Error" style={{ width: '96vw', padding: '36px 0' }} />
            <Text>
              Looks like our servers need some cooling.
              <br />
              Please try again in a while.
            </Text>
          </>
        )}
      />
      <Media
        query="(min-width: 768px)"
        render={() => (
          <>
            <div style={{ maxWidth: 960, padding: 48 }}>
              <img src={FiveHundred} alt="Internal Server Error" style={{ width: '100%', height: 'auto' }} />
            </div>
            <Text>Looks like our servers need some cooling. Please try again in a while.</Text>
          </>
        )}
      />
    </Container>
  </Title>
);

export default ServerError;
