import * as Sentry from '@sentry/browser';

import { NODE_ENV, SENTRY_DSN } from 'constants/environment';
import packageJson from '../../package.json';

export const init = () => {
  if (NODE_ENV !== 'test') {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: NODE_ENV,
      release: packageJson.version,
    });
  }
};
