import { ACTION_STATUS as STATUS } from '../constants';
import * as types from '../constants/actions';

const initialState = {
  error: '',
  data: {
    result: [],
    count: 0,
    pages: 0,
  },
  curid: '',
  status: {
    fareFetch: STATUS.READY,
    farePost: STATUS.READY,
  },
};

const fare = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_FARE: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          data: {
            ...payload?.data,
            result: [...state.data.result, ...payload?.data?.result],
          },
          status: {
            ...state.status,
            fareFetch: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fareFetch: payload.status,
        },
      };
    }
    case types.POST_FARE: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          curid: payload?.id,
          status: {
            ...state.status,
            farePost: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          farePost: payload.status,
        },
      };
    }
    default:
      return state;
  }
};

export default fare;
