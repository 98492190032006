import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';

const initialState = {
  checklistStats: [],
  countByDays: {
    data: [],
  },
  daysUntilDisabled: 3,
  filterCount: 0,
  totalApplications: 0,
  filter: {
    college: [],
    country: [],
    gender: [],
    isa: [],
  },
  liveStats: {
    expected: 0,
    checkedIn: {
      total: 0,
      individual: 0,
      team: 0,
    },
  },
  submittedForReview: {
    individual: 0,
    team: 0,
    total: 0,
  },
  projectStats: {
    hackersLeft: 0,
    hackersSubmitted: 0,
    projectsSubmitted: 0,
  },
  reviewed: {
    total: {
      accepted: 0,
      notAccepted: 0,
      waitlisted: 0,
      unreviewed: 0,
    },
    individual: {
      accepted: 0,
      notAccepted: 0,
      waitlisted: 0,
      unreviewed: 0,
    },
    team: {
      accepted: 0,
      notAccepted: 0,
      waitlisted: 0,
      unreviewed: 0,
    },
  },
  rsvp: {
    missed: 0,
    no: 0,
    yes: 0,
    pending: 0,
  },
  totalApplicantStatus: {
    accepted: 0,
    notAccepted: 0,
    notSubmitted: 0,
    total: 0,
    unreviewed: 0,
    waitlisted: 0,
  },
  reimbursementStats: {
    amount: {
      currency: 'INR',
      reimbursed: 0,
      remaining: 0,
      total: 0,
    },
    people: {
      reimbursed: 0,
      remaining: 0,
      total: 0,
    },
  },
  tShirtStats: {
    xs: 0,
    s: 0,
    m: 0,
    l: 0,
    xl: 0,
    xxl: 0,
    xxxl: 0,
  },
  stats: {
    accept: 0,
    acceptSent: 0,
    checkIn: 0,
    notAccept: 0,
    notAcceptSent: 0,
    notSubmit: 0,
    reimburse: 0,
    rsvp: 0,
    submit: 0,
    total: 0,
    waitlist: 0,
    waitlistSent: 0,
    inclusive: {
      accept: 0,
      acceptSent: 0,
      applied: 0,
      checkIn: 0,
      notAccept: 0,
      notAcceptSent: 0,
      reimburse: 0,
      rsvp: 0,
      submit: 0,
      waitlist: 0,
      waitlistSent: 0,
      withdraw: 0,
    },
  },
  educationStreamStats: [],
  educationDegreeStats: [],
  collegeStats: [],
  graduationStats: [],
  professionStats: [],
  cityStats: [],
  countryStats: [],
  genderStats: [],
  ageStats: [],
  status: {
    fetchAnalytics: STATUS.READY,
    fetchCountStats: STATUS.READY,
    fetchLiveStats: STATUS.READY,
    fetchReimbursementStats: STATUS.READY,
    fetchTShirtStats: STATUS.READY,
    fetchEducationStats: STATUS.READY,
    fetchLocationStats: STATUS.READY,
    fetchGenderAndAgeStats: STATUS.READY,
  },
};

const analytics = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ANALYTICS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          ...payload.data,
          status: {
            ...state.status,
            fetchAnalytics: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchAnalytics: payload.status,
        },
      };
    }

    case types.UPDATE_REALTIME_ANALYTICS: {
      const { payload } = action;
      const areAllFiltersEmpty = Object.keys(state.filter).every(key => state.filter[key].length === 0);
      return {
        ...state,
        ...payload,
        // The filterCount is equal to total number of applications if there
        // are no active filters applied.
        ...(areAllFiltersEmpty && { filterCount: payload.totalApplications }),
      };
    }

    case types.APPLY_FILTER: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          filterCount: payload.count,
          status: {
            ...state.status,
            applyFilter: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          applyFilter: payload.status,
        },
      };
    }

    case types.SET_FILTER: {
      const { payload } = action;
      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.name]: payload.value,
        },
      };
    }

    case types.CLEAR_FILTERS: {
      return {
        ...state,
        filter: {
          college: [],
          country: [],
          gender: [],
          isa: [],
        },
      };
    }

    case types.FETCH_LIVE_STATS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          checklistStats: [...initialState.checklistStats, ...payload.checklistStats],
          liveStats: {
            ...initialState.liveStats,
            ...payload.liveStats,
          },
          status: {
            ...state.status,
            fetchLiveStats: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchLiveStats: payload.status,
        },
      };
    }

    case types.FETCH_REIMBURSEMENT_STATS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          reimbursementStats: {
            ...initialState.reimbursementStats,
            ...payload.reimbursementStats,
          },
          status: {
            ...state.status,
            fetchReimbursementStats: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchReimbursementStats: payload.status,
        },
      };
    }

    case types.FETCH_TSHIRT_STATS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          tShirtStats: {
            ...initialState.tShirtStats,
            ...payload.tShirtStats,
          },
          status: {
            ...state.status,
            fetchTShirtStats: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchTShirtStats: payload.status,
        },
      };
    }

    case types.FETCH_COUNT_STATS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        const { data } = payload;

        return {
          ...state,
          countByDays: {
            data,
          },
          status: {
            ...state.status,
            fetchCountStats: payload.status,
          },
        };
      }

      // Request
      return {
        ...state,
        status: {
          ...state.status,
          fetchCountStats: payload.status,
        },
      };
    }

    case types.FETCH_STATS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        const { data } = payload;

        return {
          ...state,
          stats: data,
          status: {
            ...state.status,
            fetchStats: payload.status,
          },
        };
      }

      // Request
      return {
        ...state,
        status: {
          ...state.status,
          fetchStats: payload.status,
        },
      };
    }
    case types.FETCH_EDUCATION_STATS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        const { stats } = payload;

        return {
          ...state,
          ...stats,
          status: {
            ...state.status,
            fetchEducationStats: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchEducationStats: payload.status,
        },
      };
    }

    case types.FETCH_LOCATION_STATS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        const { cityStats, countryStats } = payload;

        return {
          ...state,
          cityStats,
          countryStats,
          status: {
            ...state.status,
            fetchLocationStats: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchLocationStats: payload.status,
        },
      };
    }

    case types.FETCH_GENDER_AND_AGE_STATS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        const { genderStats, ageStats } = payload;

        return {
          ...state,
          genderStats,
          ageStats,
          status: {
            ...state.status,
            fetchGenderAndAgeStats: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchGenderAndAgeStats: payload.status,
        },
      };
    }

    case types.UPDATE_LIVE_STATS: {
      return {
        ...state,
        liveStats: {
          ...state.liveStats,
          checkedIn: {
            ...state.liveStats.checkedIn,
            total: action.payload.checkedIn ? state.liveStats.checkedIn.total + 1 : state.liveStats.checkedIn.total - 1,
          },
        },
      };
    }

    case types.UPDATE_CHECKLIST_STATS: {
      const {
        payload: { label, value },
      } = action;
      const checklistIndex = state.checklistStats?.findIndex(({ name }) => name === label);

      if (checklistIndex === -1) {
        return state;
      }

      const currentCount = state.checklistStats[checklistIndex].count;
      const updatedItem = {
        name: label,
        count: value ? currentCount + 1 : currentCount - 1,
      };
      return {
        ...state,
        checklistStats: [
          ...state.checklistStats.slice(0, checklistIndex),
          updatedItem,
          ...state.checklistStats.slice(checklistIndex + 1),
        ],
      };
    }

    case types.LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
};

export default analytics;
