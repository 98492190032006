import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
`;

const defaults = {
  getColor: appearance => {
    switch (appearance) {
      case 'danger':
        return '#FF3838';
      case 'primary':
        return '#3770ff';
      default:
        return '#125045';
    }
  },
};

export const ButtonLink = styled.button`
  background-color: transparent;
  border: none;
  color: ${props => defaults.getColor(props.appearance)};
  cursor: pointer;
  display: inline-block;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1em;
  font-weight: 600;
  margin: 0;
  opacity: 0.8;
  outline: none;
  padding: 0;
  &:hover {
    opacity: 1;
  }
`;
