import { ACTION_STATUS as STATUS, TABS, ROLE } from '../constants';
import * as types from '../constants/actions';

const initialState = {
  activeMemberUsername: '',
  members: [],
  member: {
    displayName: null,
    displayShortName: null,
    email: null,
    firstName: null,
    lastName: null,
    profileImageUrl: null,
    username: null,
  },
  status: {
    addSquadMember: STATUS.READY,
    deleteSquadMember: STATUS.READY,
    fetchSquadMembers: STATUS.READY,
    searchMember: STATUS.READY,
    updateSquadMember: STATUS.READY,
    addOrganizer: STATUS.READY,
  },
  error: {
    addSquadMember: '',
    deleteSquadMember: '',
    fetchSquadMembers: '',
    searchMember: '',
    updateSquadMember: '',
    addOrganizer: '',
  },
};

const feedback = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_SQUAD_MEMBER: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        const member = { ...state.member };
        member.tabs = payload?.tabs || [];

        return {
          ...state,
          members: [...state.members, member],
          status: {
            ...state.status,
            addSquadMember: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          addSquadMember: payload.status,
        },
      };
    }

    case types.ADD_ORGANIZER: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        const member = { ...state.member, role: ROLE.ORGANIZER };
        member.tabs = TABS;

        return {
          ...state,
          members: [...state.members, member],
          status: {
            ...state.status,
            addOrganizer: payload.status,
          },
        };
      }

      if (payload.status === STATUS.FAILURE) {
        return {
          ...state,
          error: {
            ...state.error,
            addOrganizer: payload.error,
          },
          status: {
            ...state.status,
            addOrganizer: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          addOrganizer: payload.status,
        },
      };
    }

    case types.UPDATE_SQUAD_MEMBER: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          activeMemberUsername: '',
          members: [...state.members].map(member => {
            if (member?.username !== payload.memberUsername) {
              return member;
            }

            return {
              ...member,
              tabs: payload.tabs,
            };
          }),
          status: {
            ...state.status,
            updateSquadMember: payload.status,
          },
        };
      }

      return {
        ...state,
        activeMemberUsername: payload?.activeMemberUsername,
        status: {
          ...state.status,
          updateSquadMember: payload.status,
        },
      };
    }

    case types.DELETE_SQUAD_MEMBER: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        const updatedMembers = [...state.members].filter(member => member?.username !== payload.memberUsername);

        return {
          ...state,
          members: updatedMembers,
          activeMemberUsername: '',
          status: {
            ...state.status,
            deleteSquadMember: payload.status,
          },
        };
      }

      return {
        ...state,
        activeMemberUsername: payload?.activeMemberUsername,
        status: {
          ...state.status,
          deleteSquadMember: payload.status,
        },
      };
    }

    case types.FETCH_SQUAD_MEMBERS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          members: payload?.members || [],
          status: {
            ...state.status,
            fetchSquadMembers: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchSquadMembers: payload.status,
        },
      };
    }

    case types.SEARCH_MEMBER: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          member: {
            ...initialState.member,
            ...payload.member,
          },
          status: {
            ...state.status,
            searchMember: payload.status,
          },
        };
      }

      if (payload.status === STATUS.FAILURE) {
        return {
          ...state,
          member: {
            ...initialState.member,
          },
          error: {
            ...state.error,
            searchMember: payload.error,
          },
          status: {
            ...state.status,
            searchMember: payload.status,
          },
        };
      }

      return {
        ...state,
        error: {
          ...state.error,
          searchMember: '',
        },
        status: {
          ...state.status,
          searchMember: payload.status,
        },
      };
    }

    case types.CLEAR_SEARCH_RESULT:
      return {
        ...state,
        member: {
          ...initialState.member,
        },
        error: {
          ...state.error,
          searchMember: '',
        },
        status: {
          ...state.status,
          searchMember: STATUS.READY,
        },
      };

    case types.LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
};

export default feedback;
