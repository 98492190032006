import { Paragraph as P, Logo } from '@devfolioco/genesis';
import React from 'react';
import { connect } from 'react-redux';
import Title from 'react-document-title';

import { logger } from 'helpers/logger';
import { Container, ButtonLink } from './style';
import ServerError from '../../views/ServerError';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI, and log to Sentry
    this.setState({ hasError: true });

    // Report the UI error
    logger.error('ui', error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children, serverError } = this.props;

    // Show the default error in case of a error caught in ErrorBoundary
    if (hasError) {
      return (
        <Title title="Organizer Dashboard | Devfolio">
          <Container>
            <Logo />
            <P style={{ marginTop: 16 }}>
              Something went wrong. Please try&nbsp;
              <ButtonLink appearance="primary" onClick={() => window.location.reload(true)}>
                refreshing
              </ButtonLink>
              &nbsp;the page.
            </P>
          </Container>
        </Title>
      );
    }

    // Show the 500 Sever Error when a server error has occoured
    if (serverError) {
      return <ServerError />;
    }

    return children;
  }
}

const mapStateToProps = ({ user }) => ({
  serverError: user.serverError,
});

export default connect(mapStateToProps)(ErrorBoundary);
