import React from 'react';
import { Text } from '@devfolioco/genesis';

/**
 * @type any
 */
const Label = ({ children, weight = 'regular', style, marginBottom = 8, color = 'grey-7' }) => (
  <div style={{ marginBottom, ...style }}>
    <Text size={12} font="secondary" weight={weight} color={color} letterSpacing="0.6px" uppercase upgrade>
      {children}
    </Text>
  </div>
);

export default Label;
