import { GRAPHQL_ROOT, GRAPHQL_WEBSOCKET_ROOT } from '@constants/environment';
import { GraphQLClient, gql } from 'graphql-request';
import { createClient } from 'graphql-ws';
import type {
  UpdateWinnersOrganizersSchwagShipmentMutation,
  UpdateWinnersOrganizersSchwagShipmentMutationVariables,
  SubmitSchwagFulfillmentMutation,
  SubmitSchwagFulfillmentMutationVariables,
} from '../interfaces/graphql';

// Create a client
export const client = new GraphQLClient(GRAPHQL_ROOT, {
  credentials: 'include',
});

export const subscriptionClient = createClient({
  url: GRAPHQL_WEBSOCKET_ROOT,
});

export const GRAPHQL_QUERIES = {
  getHackathonBrands: (input: string) =>
    client.request(
      gql`
        query FetchHackathonBrands($query: String!) {
          hackathon_brands(where: { name: { _ilike: $query } }) {
            uuid
            name
          }
        }
      `,
      { query: `%${input}%` }
    ),
  getHackathonEvents: ({ hackathonUUID }: { hackathonUUID: string }) =>
    client.request(
      gql`
        query HackathonEvents($uuid: String!) {
          hackathons(where: { uuid: { _eq: $uuid } }) {
            hackathon_event_groups(order_by: { order: asc }) {
              uuid
              name
              order
            }

            events(order_by: { starts_at: asc }) {
              uuid
              title
              description
              type
              url: _url
              meeting_url
              starts_at
              ends_at
              location
              google_maps_location_url
              hackathon_event_group {
                uuid
                name
              }
              speakers {
                speaker {
                  name
                  uuid
                  twitter
                  telegram
                  slack
                  website
                  order
                  medium
                  linkedin
                  job_title
                  instagram
                  facebook
                  discord
                  profile_img: _profile_img
                }
              }
            }
          }
        }
      `,
      { uuid: hackathonUUID }
    ),
};

export const GRAPHQL_MUTATIONS = {
  updateWinnersAndOrganizers: ({
    winners,
    organizers,
    schwagShipmentUUID,
  }: { schwagShipmentUUID: string } & Pick<
    UpdateWinnersOrganizersSchwagShipmentMutationVariables,
    'winners' | 'organizers'
  >) =>
    client.request<
      UpdateWinnersOrganizersSchwagShipmentMutation,
      UpdateWinnersOrganizersSchwagShipmentMutationVariables
    >(
      gql`
        mutation UpdateWinnersOrganizersSchwagShipment($uuid: String!, $winners: jsonb!, $organizers: jsonb!) {
          update_hackathon_schwags(
            where: { uuid: { _eq: $uuid } }
            _set: { organizers_email: $organizers, winners: $winners }
          ) {
            returning {
              organizers_email
              winners
              email_sent
              enable
              uuid
            }
          }
        }
      `,
      { winners, organizers, uuid: schwagShipmentUUID }
    ),
  sendFormsForSchwagShipment: ({ hackathonUUID }: { hackathonUUID: string }) =>
    client.request<SubmitSchwagFulfillmentMutation, SubmitSchwagFulfillmentMutationVariables>(
      gql`
        mutation SubmitSchwagFulfillment($uuid: String!) {
          submitSchwagFulfilmentForm(hackathon_uuid: $uuid) {
            emails_sent
          }
        }
      `,
      { uuid: hackathonUUID }
    ),
  createHackathonBrand: ({ brandName }: { brandName: string }) =>
    client.request(
      gql`
        mutation InsertHackathonBrands($brandName: String!) {
          insert_hackathon_brands_one(object: { name: $brandName }) {
            uuid
            name
          }
        }
      `,
      { brandName }
    ),
  addHackathonEdition: ({
    hackathonUUID,
    brandUUID,
    editionValue,
  }: {
    hackathonUUID: string;
    brandUUID: string;
    editionValue: number;
  }) =>
    client.request(
      gql`
        mutation AddHackathonEdition($args: add_hackathon_edition_args!) {
          add_hackathon_edition(args: $args) {
            uuid
          }
        }
      `,
      {
        args: {
          brand_uuid: brandUUID,
          edition_value: editionValue,
          hackathon_uuid: hackathonUUID,
        },
      }
    ),
  deleteHackathonEdition: ({ hackathonUUID, brandUUID }: { hackathonUUID: string; brandUUID: string }) =>
    client.request(
      gql`
        mutation RemoveHackathonEdition($args: remove_hackathon_edition_args!) {
          remove_hackathon_edition(args: $args) {
            uuid
          }
        }
      `,
      {
        args: {
          brand_uuid: brandUUID,
          hackathon_uuid: hackathonUUID,
        },
      }
    ),
};
