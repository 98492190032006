// Action Types
export const SERVER_ERROR = 'SERVER_ERROR';

// USER SPECIFIC
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';
export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';

export const SET_AUTHENTICATION_ERROR = 'SET_AUTHENTICATION_ERROR';
export const CLEAR_AUTHENTICATION_ERRORS = 'CLEAR_AUTHENTICATION_ERRORS';

// FILTER
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_FILTER_ITEM = 'UPDATE_FILTER_ITEM';

export const FETCH_ADDRESS_REQUEST = 'FETCH_ADDRESS_REQUEST';
export const FETCH_ADDRESS_SUCCESS = 'FETCH_ADDRESS_SUCCESS';
export const FETCH_ADDRESS_FAILURE = 'FETCH_ADDRESS_FAILURE';

export const FETCH_COLLEGE_REQUEST = 'FETCH_COLLEGE_REQUEST';
export const FETCH_COLLEGE_SUCCESS = 'FETCH_COLLEGE_SUCCESS';
export const FETCH_COLLEGE_FAILURE = 'FETCH_COLLEGE_FAILURE';

// PARTICIPANT
export const FETCH_PARTICIPANTS_REQUEST = 'FETCH_PARTICIPANTS_REQUEST';
export const FETCH_PARTICIPANTS_SUCCESS = 'FETCH_PARTICIPANTS_SUCCESS';
export const FETCH_PARTICIPANTS_FAILURE = 'FETCH_PARTICIPANTS_FAILURE';

export const FETCH_PARTICIPANT_INFO_REQUEST = 'FETCH_PARTICIPANT_INFO_REQUEST';
export const FETCH_PARTICIPANT_INFO_SUCCESS = 'FETCH_PARTICIPANT_INFO_SUCCESS';
export const FETCH_PARTICIPANT_INFO_FAILURE = 'FETCH_PARTICIPANT_INFO_FAILURE';

export const FETCH_TEAM_INFO = 'FETCH_TEAM_INFO';

export const FETCH_TEAMS_REQUEST = 'FETCH_TEAMS_REQUEST';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAILURE = 'FETCH_TEAMS_FAILURE';

export const FETCH_STATS_REQUEST = 'FETCH_STATS_REQUEST';
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS';
export const FETCH_STATS_FAILURE = 'FETCH_STATS_FAILURE';

export const UPDATE_PARTICIPANT_STATUS_REQUEST = 'UPDATE_PARTICIPANT_STATUS_REQUEST';
export const UPDATE_PARTICIPANT_STATUS_SUCCESS = 'UPDATE_PARTICIPANT_STATUS_SUCCESS';
export const UPDATE_PARTICIPANT_STATUS_FAILURE = 'UPDATE_PARTICIPANT_STATUS_FAILURE';

export const SEND_EMAILS = 'SEND_EMAILS';
export const UPDATE_CURRENT_PARTICIPANT = 'UPDATE_CURRENT_PARTICIPANT';

export const UPDATE_ALL_INDIVIDUALS = 'UPDATE_ALL_INDIVIDUALS';
export const UPDATE_ALL_TEAMS = 'UPDATE_ALL_TEAMS';

export const UPDATE_INDIVIDUALS = 'UPDATE_INDIVIDUALS';
export const UPDATE_TEAMS = 'UPDATE_TEAMS';

export const UPDATE_TEAM_STATUS = 'UPDATE_TEAM_STATUS';

export const BULK_UPDATE_PARTICIPANT_STATUS = 'BULK_UPDATE_PARTICIPANT_STATUS';
export const FETCH_CURRENT_PARTICIPANT_OR_TEAM_REVIEW_HISTORY = 'FETCH_CURRENT_PARTICIPANT_OR_TEAM_REVIEW_HISTORY';

// HACKATHON
export const FETCH_CHECKLIST = 'FETCH_CHECKLIST';
export const FETCH_HACKATHON_DATA_FAILURE = 'FETCH_HACKATHON_DATA_FAILURE';
export const FETCH_HACKATHON_DATA_REQUEST = 'FETCH_HACKATHON_DATA_REQUEST';
export const FETCH_HACKATHON_DATA_SUCCESS = 'FETCH_HACKATHON_DATA_SUCCESS';
export const FETCH_MY_HACKATHONS = 'FETCH_MY_HACKATHONS';
export const FETCH_SEARCH_KEYS = 'FETCH_SEARCH_KEYS';

// PROJECTS
export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const DELETE_PROJECT = 'DELETE_PROJECT';

// ANALYTICS
export const APPLY_FILTER = 'APPLY_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const FETCH_ANALYTICS = 'FETCH_ANALYTICS';
export const FETCH_COUNT_STATS = 'FETCH_COUNT_STATS';
export const FETCH_LIVE_STATS = 'FETCH_LIVE_STATS';
export const UPDATE_LIVE_STATS = 'UPDATE_LIVE_STATS';
export const UPDATE_CHECKLIST_STATS = 'UPDATE_CHECKLIST_STATS';
export const FETCH_REIMBURSEMENT_STATS = 'FETCH_REIMBURSEMENT_STATS';
export const FETCH_STATS = 'FETCH_STATS';
export const FETCH_TSHIRT_STATS = 'FETCH_TSHIRT_STATS';
export const SEND_REMINDER_EMAILS = 'SEND_REMINDER_EMAILS';
export const SET_FILTER = 'SET_FILTER';
export const FETCH_EDUCATION_STATS = 'FETCH_EDUCATION_STATS';
export const FETCH_LOCATION_STATS = 'FETCH_LOCATION_STATS';
export const FETCH_GENDER_AND_AGE_STATS = 'FETCH_GENDER_AND_AGE_STATS';

export const UPDATE_REALTIME_ANALYTICS = 'UPDATE_REALTIME_ANALYTICS';

// CHECKIN
export const FETCH_CHECKIN_PARTICIPANTS = 'FETCH_CHECKIN_PARTICIPANTS';
export const FETCH_HACKER_INFO = 'FETCH_HACKER_INFO';
export const PAGINATION = 'PAGINATION';
export const SEARCH = 'SEARCH';
export const SEARCH_PARTICIPANT = 'SEARCH_PARTICIPANT';
export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST';
export const UPDATE_HACKER = 'UPDATE_HACKER';
export const UPDATE_HACKER_CONFERENCE_CHECKIN = 'UPDATE_HACKER_CONFERENCE_CHECKIN';
export const UPDATE_HACKER_SUBMITTED_CONSENT = 'UPDATE_HACKER_SUBMITTED_CONSENT';
export const UPDATE_NOTE = 'UPDATE_NOTE';

// Admin
export const CHANGE_APPLICATION_DEADLINE = 'CHANGE_APPLICATION_DEADLINE';
export const DOWNLOAD_CSV = 'DOWNLOAD_CSV';
export const EXTEND_RSVP_DEADLINE = 'EXTEND_RSVP_DEADLINE';
export const EXTEND_SUBMISSION_DEADLINE = 'EXTEND_SUBMISSION_DEADLINE';
export const EXTEND_USER_RSVP_DEADLINE = 'EXTEND_USER_RSVP_DEADLINE';
export const JOIN_TEAM = 'JOIN_TEAM';
export const LEAVE_TEAM = 'LEAVE_TEAM';
export const MAKE_ADMIN = 'MAKE_ADMIN';
export const SEND_ANNOUNCEMENT = 'SEND_ANNOUNCEMENT';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';

// FEEDBACK
export const FETCH_FEEDBACK = 'FETCH_FEEDBACK';
export const FETCH_REVIEWS = 'FETCH_REVIEWS';
export const FETCH_FEEDBACK_STATS = 'FETCH_FEEDBACK_STATS';
export const SEND_FEEDBACK_REMINDER_EMAILS = 'SEND_FEEDBACK_REMINDER_EMAILS';

// SQUAD
export const ADD_SQUAD_MEMBER = 'ADD_SQUAD_MEMBER';
export const CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';
export const DELETE_SQUAD_MEMBER = 'DELETE_SQUAD_MEMBER';
export const FETCH_SQUAD_MEMBERS = 'FETCH_SQUAD_MEMBERS';
export const SEARCH_MEMBER = 'SEARCH_MEMBER';
export const UPDATE_SQUAD_MEMBER = 'UPDATE_SQUAD_MEMBER';
export const VERIFY_INVITE_ADD_SQUAD = 'VERIFY_INVITE_ADD_SQUAD';
export const ADD_ORGANIZER = 'ADD_ORGANIZER';

// USER
export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const FETCH_USER_HACKATHONS = 'FETCH_USER_HACKATHONS';

// FARE
export const FETCH_FARE = 'FETCH_FARE';
export const POST_FARE = 'POST_FARE';

// ROOT
export const FETCH_COLLEGES = 'FETCH_COLLEGES';
export const FETCH_SKILLS = 'FETCH_SKILLS';
export const FETCH_HACKATHONS = 'FETCH_HACKATHONS';
export const FETCH_HASHTAGS = 'FETCH_HASHTAGS';

export const UPDATE_SKILL = 'UPDATE_SKILL';
export const UPDATE_COLLEGE = 'UPDATE_COLLEGE';
export const UPDATE_HASHTAG = 'UPDATE_HASHTAG';

export const DELETE_SKILL = 'DELETE_SKILL';
export const DELETE_COLLEGE = 'DELETE_COLLEGE';

export const DELETE_HASHTAG = 'DELETE_HASHTAG';

export const VERIFY_HACKATHON = 'VERIFY_HACKATHON';
export const PRIVATIZE_HACKATHON = 'PRIVATIZE_HACKATHON';
export const TOGGLE_PAID_HACKATHON = 'TOGGLE_PAID_HACKATHON';

export const FETCH_MAILING_LISTS = 'FETCH_MAILING_LISTS';
export const FETCH_USER_MAILING_LIST = 'FETCH_USER_MAILING_LIST';
export const UPDATE_MAILING_LIST = 'UPDATE_MAILING_LIST';
export const ROOT_SEND_EMAILS = 'ROOT_SEND_EMAILS';
export const UPDATE_HACKATHON_DETAIL = 'UPDATE_HACKATHON_DETAIL';

export const FETCH_FAQS = 'FETCH_FAQS';
export const ADD_FAQ = 'ADD_FAQ';
export const DELETE_FAQ = 'DELETE_FAQ';
export const UPDATE_FAQ = 'UPDATE_FAQ';

export const MAKE_HACKATHON_OFFICIAL_AND_FEATURED = 'MAKE_HACKATHON_OFFICIAL_AND_FEATURED';
export const FETCH_ALL_USER_INFO = 'FETCH_ALL_USER_INFO';

export const MAKE_HACKATHON_FELLOWSHIP = 'MAKE_HACKATHON_FELLOWSHIP';

export const FETCH_HACKATHON_BRANDS = 'FETCH_HACKATHON_BRANDS';
export const CREATE_HACKATHON_BRAND = 'CREATE_HACKATHON_BRAND';

export const ADD_HACKATHON_EDITION = 'ADD_HACKATHON_EDITION';
export const DELETE_HACKATHON_EDITION = 'DELETE_HACKATHON_EDITION';

export const UPDATE_HACKATHON_MAIL_SUBSCRIPTIONS = 'UPDATE_HACKATHON_MAIL_SUBSCRIPTIONS';

export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const UPDATE_COMPANY_FAVICON = 'UPDATE_COMPANY_FAVICON';

export const UPDATE_USER_BAN_STATUS = 'UPDATE_USER_BAN_STATUS';

// ORGANIZER
export const UPDATE_SAVE_STATUS = 'UPDATE_SAVE_STATUS';

export const UPLOAD_LOGO = 'UPLOAD_LOGO';
export const UPLOAD_COVER = 'UPLOAD_COVER';
export const UPLOAD_FAVICON = 'UPLOAD_FAVICON';
export const DELETE_IMAGE = 'DELETE_IMAGE';

export const ADD_PRIZE = 'ADD_PRIZE';
export const UPDATE_PRIZE = 'UPDATE_PRIZE';
export const DELETE_PRIZE = 'DELETE_PRIZE';
export const TOGGLE_WINNERS_ANNOUNCED = 'TOGGLE_WINNERS_ANNOUNCED';
export const TOGGLE_WINNERS_PUBLISH_CHECK = 'TOGGLE_WINNERS_PUBLISH_CHECK';

export const ADD_EVENT = 'ADD_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

export const ADD_EVENT_GROUP = 'ADD_EVENT_GROUP';
export const UPDATE_EVENT_GROUP = 'UPDATE_EVENT_GROUP';
export const DELETE_EVENT_GROUP = 'DELETE_EVENT_GROUP';

export const ADD_TRACK = 'ADD_TRACK';
export const UPDATE_TRACK = 'UPDATE_TRACK';
export const DELETE_TRACK = 'DELETE_TRACK';

export const ADD_JUDGE = 'ADD_JUDGE';
export const UPDATE_JUDGE = 'UPDATE_JUDGE';
export const DELETE_JUDGE = 'DELETE_JUDGE';

export const CREATE_HACKATHON = 'CREATE_HACKATHON';
export const UPDATE_HACKATHON = 'UPDATE_HACKATHON';
export const DELETE_HACKATHON = 'DELETE_HACKATHON';

export const UPDATE_HACKATHON_SETUP = 'UPDATE_HACKATHON_SETUP';
export const CLEAR_HACKATHON_SETUP = 'CLEAR_HACKATHON_SETUP';

export const FETCH_ORGANIZER_HACKATHONS = 'FETCH_ORGANIZER_HACKATHONS';

export const FETCH_HACKATHON = 'FETCH_HACKATHON';

export const FETCH_SPONSORS = 'FETCH_SPONSORS';
export const ADD_SPONSOR_TIER = 'ADD_SPONSOR_TIER';
export const ADD_SPONSOR = 'ADD_SPONSOR';
export const DELETE_SPONSOR = 'DELETE_SPONSOR';
export const DELETE_SPONSOR_TIER = 'DELETE_SPONSOR_TIER';
export const UPDATE_SPONSOR_POSITION = 'UPDATE_SPONSOR_POSITION';
export const SWAP_TIERS = 'SWAP_TIERS';

export const FETCH_EXTRA_FIELDS = 'FETCH_EXTRA_FIELDS';
export const ADD_EXTRA_FIELD = 'ADD_EXTRA_FIELD';
export const DELETE_EXTRA_FIELD = 'DELETE_EXTRA_FIELD';

export const SEND_CONTACT_EMAIL_VERIFICATION_OTP = 'SEND_CONTACT_EMAIL_VERIFICATION_OTP';
export const VERIFY_CONTACT_EMAIL = 'VERIFY_CONTACT_EMAIL';

export const FETCH_BUILDER_STATS = 'FETCH_BUILDER_STATS';

// QUIZ
export const ADD_QUESTION = 'ADD_QUESTION';

export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const FETCH_QUIZZES = 'FETCH_QUIZZES';

export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const UPDATE_QUIZ = 'UPDATE_QUIZ';

export const DELETE_QUESTION = 'DELETE_QUESTION';

export const UPDATE_CURRENT_QUIZ = 'UPDATE_CURRENT_QUIZ';

export const CREATE_RESOURCE = 'CREATE_RESOURCE';
export const FETCH_RESOURCES = 'FETCH_RESOURCES';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';

// SEARCH
export const CLEAR_UNREVIEWED_PARTICIPANTS = 'CLEAR_UNREVIEWED_PARTICIPANTS';

// TICKETS
export const FETCH_TICKETS = 'FETCH_TICKETS';
export const ADD_TICKET = 'ADD_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const DELETE_TICKET = 'DELETE_TICKET';

export const FETCH_TICKETS_STATS = 'FETCH_TICKETS_STATS';

export const REFUND_TICKETS = 'REFUND_TICKETS';

export const UPDATE_HACKATHON_TICKET_INFO = 'UPDATE_HACKATHON_TICKET_INFO';

export const UPLOAD_TICKET_IMAGE = 'UPLOAD_TICKET_IMAGE';

export const FETCH_IDEAS = 'FETCH_IDEAS';
export const UPDATE_IDEA = 'UPDATE_IDEA';
export const DELETE_IDEA = 'DELETE_IDEA';
export const ADD_WINNER = 'ADD_WINNER';
export const DELETE_WINNER = 'DELETE_WINNER';

// EMAIL TEMPLATES
export const FETCH_EMAIL_TEMPLATES = 'FETCH_EMAIL_TEMPLATES';
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';
export const UPDATE_ITEM_POSITION = 'UPDATE_ITEM_POSITION';
export const FETCH_SPONSOR_PRIZE_TEMPLATE = 'FETCH_SPONSOR_PRIZE_TEMPLATE';
export const UPDATE_SPONSOR_PRIZE_TEMPLATE = 'UPDATE_SPONSOR_PRIZE_TEMPLATE';
export const UPDATE_FEATURED_IMAGE = 'UPDATE_FEATURED_IMAGE';

// LOGISTITCS
export const FETCH_SCHWAG_SHIPMENT_DETAILS = 'FETCH_SCHWAG_SHIPMENT_DETAILS';
export const ADD_WINNER_TO_SCHWAG_SHIPMENT = 'ADD_WINNER_TO_SCHWAG_SHIPMENT';
export const REMOVE_WINNER_FROM_SCHWAG_SHIPMENT = 'REMOVE_WINNER_FROM_SCHWAG_SHIPMENT';
export const UPDATE_ORGANIZERS_IN_SCHWAG_SHIPMENT = 'UPDATE_ORGANIZERS_IN_SCHWAG_SHIPMENT';
export const REMOVE_ORGANIZER_FROM_SCHWAG_SHIPMENT = 'REMOVE_ORGANIZER_FROM_SCHWAG_SHIPMENT';
export const UPDATE_WINNERS_AND_ORGANIZERS_IN_SCHWAG_SHIPMENT = 'UPDATE_WINNERS_AND_ORGANIZERS_IN_SCHWAG_SHIPMENT';
export const SEND_SCHWAG_SHIPMENT_FORMS = 'SEND_SCHWAG_SHIPMENT_FORMS';
