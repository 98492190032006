import React from 'react';
import { Formik } from 'formik';
import { Text, TextField, Button, Modal, RichTextEditor, Select } from '@devfolioco/genesis';
import Label from 'components/Label';
import DateTimePicker from 'components/DateTimePicker';
import { ERRORS } from 'constants/errors';
import addEventValidation from 'constants/addEventValidation';
import { replaceTimezoneInUTC } from '@helpers';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '@devfolioco/helpers';
import { WAIT_TIME } from 'constants/settings';
import debounce from 'lodash/debounce';
import { AnyAction } from 'redux';
import { OrganizerStoreI } from 'reducers/organizer';
import TimeZone from './TimeZone';
import { addEvent, updateEvent } from '../../../actions/organizer';
import { ACTION_STATUS as STATUS } from '../../../constants';
import MultiSelect from '../../../components/MultiSelect';
import { API } from '../../../api/internal';
import { Event, EVENT_TYPES, EventGroupI } from './Events';

const hasError = (error, formikValues, touched) => {
  if (error) {
    return { status: error && error !== ERRORS.invalidDate, error };
  }

  if (touched === 'startsAt' || touched === 'endsAt') {
    if (formikValues?.endsAt !== '' && formikValues?.startsAt === '') {
      return { status: true, error: 'Event start date must be filled first' };
    }
  }

  return null;
};

export const Error = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <div style={{ marginTop: 4 }}>
    <Text size={14} color="red-5" upgrade>
      {children}
    </Text>
  </div>
);

interface AddEventModalProps {
  event: Event;
  eventGroups: EventGroupI[];
  hackathonUUID: string;
  timezone: string;
  onClose?: () => void;
}

const AddEventModal = ({ eventGroups, event, hackathonUUID, onClose, timezone }: AddEventModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const addEventStatus = useSelector((state: { organizer: OrganizerStoreI }) => state.organizer.status.addEvent);
  const prevAddEventStatus = usePrevious(addEventStatus);

  const updateEventStatus = useSelector((state: { organizer: OrganizerStoreI }) => state.organizer.status.updateEvent);
  const prevUpdateEventStatus = usePrevious(updateEventStatus);

  const groupOptions = [
    {
      label: 'Timeline',
      value: 'default',
    },
    ...eventGroups.map(grp => ({ label: grp.name, value: grp.uuid.toString() })),
  ];

  React.useEffect(() => {
    if (
      (addEventStatus !== prevAddEventStatus && addEventStatus === STATUS.SUCCESS) ||
      (updateEventStatus !== prevUpdateEventStatus && updateEventStatus === STATUS.SUCCESS)
    ) {
      onClose?.();
    }
  }, [addEventStatus, updateEventStatus, onClose, prevAddEventStatus, prevUpdateEventStatus]);

  const getSpeakerOptions = React.useCallback(
    (input, callback) => {
      if (!input) {
        callback([]);
      }
      const promise = API.organizer.searchJudges(hackathonUUID, input);
      promise.then(response =>
        callback(
          response.data.map(speaker => ({
            label: speaker.name,
            value: speaker.uuid,
          }))
        )
      );
    },
    [hackathonUUID]
  );

  const handleFormikSubmit = values => {
    const eventToAdd = {
      ...values,
      speakers: values.speakers ?? [],
      prevSpeakers: event?.speakers,
      isPublicEvent: true,
      isURLPublic: true,
      groupUUID: values.groupUUID ?? null,
      url: values.url?.trim()?.length > 0 ? values.url : undefined,
      meetingUrl: values.meetingUrl?.trim()?.length > 0 ? values.meetingUrl : undefined,
      location: values.location?.trim()?.length > 0 ? values.location : undefined,
      googleMapsLocationURL:
        values.googleMapsLocationURL?.trim()?.length > 0 ? values.googleMapsLocationURL : undefined,
    };

    if (eventToAdd.uuid !== '') {
      dispatch((updateEvent(hackathonUUID, eventToAdd) as unknown) as AnyAction);
    } else {
      dispatch((addEvent(hackathonUUID, eventToAdd) as unknown) as AnyAction);
    }
  };

  const handleDateChange = (name, value, formikProps) => {
    const { handleChange } = formikProps;
    if (timezone !== '') {
      handleChange({
        target: {
          name,
          value: replaceTimezoneInUTC(value, timezone),
        },
      });
    }
  };

  return (
    <Modal width={600} onClose={() => onClose?.()}>
      <Formik
        initialValues={event}
        validationSchema={addEventValidation}
        onSubmit={values => handleFormikSubmit(values)}
      >
        {props => {
          const {
            values,
            dirty,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isValid,
          } = props;
          const shouldDisableSubmitButton = !(dirty && isValid);
          return (
            <div style={{ padding: 40, width: '100%' }}>
              <form onSubmit={handleSubmit}>
                <Text upgrade size={24} weight="bold">
                  {event.uuid ? 'Update Event' : 'Add a new event'}
                </Text>

                <TextField
                  label="title *"
                  placeholder="e.g. The Rise of Crypto"
                  name="title"
                  value={values.title}
                  style={{ marginTop: 32 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={500}
                  error={{ ...(errors.title && touched.title && { [errors.title]: true }) }}
                  required
                />

                <Label style={null}>Event group</Label>

                <Select
                  name="groupUUID"
                  onChange={handleChange}
                  options={groupOptions}
                  selected={values.groupUUID ?? 'default'}
                  placeholder="Event Group"
                />

                <Label style={{ marginTop: 24 }}>Event type</Label>
                <Select
                  name="type"
                  onChange={handleChange}
                  options={EVENT_TYPES}
                  selected={values.type}
                  placeholder="Type"
                />
                <TextField
                  label="url"
                  placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                  name="url"
                  value={values.url ?? ''}
                  style={{ marginTop: 24 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={{ ...(errors.url && touched.url && { [errors.url]: true }) }}
                />
                <TextField
                  label="Meeting URL"
                  placeholder="https://meet.google.com/abc-123-xyz"
                  name="meetingUrl"
                  value={values.meetingUrl ?? ''}
                  style={{ marginTop: 24 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={{ ...(errors.meetingUrl && touched.meetingUrl && { [errors.meetingUrl]: true }) }}
                />
                <TextField
                  label="Location"
                  placeholder="Room 1"
                  name="location"
                  value={values.location ?? ''}
                  style={{ marginTop: 24 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={{ ...(errors.location && touched.location && { [errors.location]: true }) }}
                />
                <TextField
                  label="Google Maps Location URL"
                  placeholder="https://maps.app.goo.gl/wfEz9hdVXwBEFsCYA"
                  name="googleMapsLocationURL"
                  value={values.googleMapsLocationURL ?? ''}
                  style={{ marginTop: 24 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={{
                    ...(errors.googleMapsLocationURL &&
                      touched.googleMapsLocationURL && { [errors.googleMapsLocationURL]: true }),
                  }}
                />
                <Label style={null}>Speakers (Add speakers via the Speakers & Judges tab)</Label>
                <MultiSelect
                  name="speakers"
                  placeholder="Start typing the name of a speaker"
                  loadOptions={debounce(getSpeakerOptions, WAIT_TIME)}
                  onChange={data => setFieldValue('speakers', data ?? [])}
                  value={values.speakers}
                  isCreatable={false}
                  isMulti
                  isClearable
                />
                {errors.speakers && (
                  <Text color="red-4" size="smaller" style={{ marginTop: 4 }}>
                    {errors.speakers}
                  </Text>
                )}
                <RichTextEditor
                  label="Description"
                  name="description"
                  value={values.description}
                  style={{ marginTop: 32 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={5000}
                  defaultValue={0}
                  isResizable
                  error={{ ...(errors.description && touched.description && { [errors.description]: true }) }}
                />
                {typeof timezone === 'string' && (
                  <React.Fragment>
                    <Label style={null}>Hackathon Timezone</Label>
                    <TimeZone disabled value={{ label: timezone, value: timezone }} />
                  </React.Fragment>
                )}
                <Label style={{ marginTop: 24 }}>Start time *</Label>
                <DateTimePicker
                  name="startsAt"
                  value={values.startsAt}
                  onChange={(name, value) => handleDateChange(name, value, props)}
                  onBlur={handleBlur}
                  isInvalid={hasError(errors.startsAt, values, 'startsAt')?.status}
                  timezone={timezone}
                  disabled={false}
                />
                <Error>
                  {hasError(errors.startsAt, values, 'startsAt')?.status &&
                    hasError(errors.startsAt, values, 'startsAt')?.error}
                </Error>
                <Label style={{ marginTop: 24 }}>End time</Label>
                <DateTimePicker
                  name="endsAt"
                  value={values.endsAt}
                  onChange={(name, value) => handleDateChange(name, value, props)}
                  onBlur={handleBlur}
                  isInvalid={hasError(errors.endsAt, values, 'endsAt')?.status}
                  timezone={timezone}
                  disabled={false}
                />
                <Error>
                  {hasError(errors.endsAt, values, 'endsAt')?.status &&
                    hasError(errors.endsAt, values, 'endsAt')?.error}
                </Error>
                <div style={{ marginTop: 32, display: 'flex' }}>
                  <Button
                    stretch
                    type="button"
                    appearance="secondary"
                    style={{ marginRight: 16 }}
                    onClick={() => onClose?.()}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={addEventStatus === STATUS.REQUEST || updateEventStatus === STATUS.REQUEST}
                    disabled={shouldDisableSubmitButton}
                    stretch
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddEventModal;
