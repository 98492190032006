import moment from 'moment';
import { logActionError } from '@helpers/logger';
import { ACTION_STATUS as STATUS, DEGREE_TYPE } from '../constants';
import { API } from '../api';
import { getDisplayName, getShortDisplayName } from '../helpers';
import * as types from '../constants/actions';

const fetchUserInfoStatus = payload => ({
  type: types.FETCH_USER_INFO,
  payload,
});

/**
 * Fetch user information
 *
 * @param {string} username
 */
export const fetchUserInfo = username => async dispatch => {
  try {
    dispatch(fetchUserInfoStatus({ status: STATUS.REQUEST }));

    const { data } = await API.user.fetchUserInfo(username);

    dispatch(fetchUserInfoStatus({ status: STATUS.SUCCESS, user: mapToUser(data) }));
  } catch (error) {
    dispatch(fetchUserInfoStatus({ status: STATUS.FAILURE }));
    logActionError(types.FETCH_USER_INFO, error, { username });
  }
};

export const mapToUser = data => {
  return {
    allergies: (data?.allergies?.length && data?.allergies.split(',')) || [],
    bio: data?.bio,
    city: data?.contact?.city,
    country: data?.contact?.country,
    displayName: getDisplayName(data),
    displayShortName: getShortDisplayName(data),
    dob: data?.dob,
    domainExpertise: (data?.extras?.domain_expertise || '').split(',') || [],
    education: data?.education || [],
    email: data?.email || null,
    emergencyContactName: data?.extras?.emergency_contact_name,
    emergencyContactNumber: data?.extras?.emergency_contact_number,
    experience: data?.experience || [],
    firstName: data?.first_name || null,
    gender: data?.gender || 'n',
    hasNoFormalEducation: !data?.extras?.is_education || false,
    isEmailVerified: data?.email_verified || false,
    isPhoneNumberVerified: data?.phone_number_verified || false,
    lastName: data?.last_name || null,
    opportunities: (data?.opportunities || '').split(',') || [],
    phoneNumber: data?.phone_number || null,
    pinCode: data?.pinCode,
    profileImageUrl: data?.profile_image || null,
    profiles: data?.profiles || [],
    refCode: data?.extras?.ref_code,
    refCount: data?.extras?.ref_count,
    resumeUrl: data?.extras?.resume,
    shirtSize: data?.extras?.t_shirt_size,
    skills: data?.skills || [],
    state: data?.contact?.state,
    status: data?.status ?? '',
    username: data?.username,
    uuid: data?.uuid,

    educationInstitution: data?.education?.[0]?.college?.name || null,
    degreeType: DEGREE_TYPE[data?.education?.[0]?.degree_type] || null,
    fieldOfStudy: data?.education?.[0]?.education_field?.name || null,
    graduation:
      (data?.education?.[0]?.graduation_year &&
        moment(data?.education?.[0]?.graduation_year, 'YYYY-MM-DD').format('MMMM, YYYY')) ||
      null,
  };
};

const fetchUserHackathonsStatus = payload => ({
  type: types.FETCH_USER_HACKATHONS,
  payload,
});

/**
 * Fetch user hackathons
 *
 * @param {string} username
 */
export const fetchUserHackathons = username => async dispatch => {
  try {
    dispatch(fetchUserHackathonsStatus({ status: STATUS.REQUEST }));

    const { data } = await API.user.getUserHackathons(username);

    dispatch(fetchUserHackathonsStatus({ status: STATUS.SUCCESS, hackathons: mapToHackathon(data) }));
  } catch (error) {
    dispatch(fetchUserHackathonsStatus({ status: STATUS.FAILURE }));
    logActionError(types.FETCH_USER_HACKATHONS, error, { username });
  }
};

export const mapToHackathon = hackathons => {
  return hackathons.map(hackathon => ({
    name: hackathon?.hackathon?.name,
    role: hackathon?.role,
    slug: hackathon?.hackathon?.slug,
    status: hackathon?.status,
    tabs: hackathon?.tabs || [],
    displayTabs: hackathon?.tabs || '',
    team: {
      ...hackathon?.team,
      name: hackathon?.team?.name,
      uuid: hackathon?.team?.uuid,
      code: hackathon?.team?.code,
    },
    uuid: hackathon?.hackathon?.uuid,
    mailingLists: {
      unsubscribeAnnouncement: hackathon?.unsubscribe_announcement,
      unsubscribeSubmitProfileReview: hackathon?.unsubscribe_submit_profile_review,
    },
  }));
};
