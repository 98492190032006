import { logActionError } from '@helpers/logger';
import { clearDevfolioAuth } from '@devfolioco/helpers';
import { API } from '../api';
import { clearStorage } from '../helpers/localStorage';
import * as types from '../constants/actions';

// MISC.
export const clearAuthenticationErrors = () => ({
  type: types.CLEAR_AUTHENTICATION_ERRORS,
});

export const authenticationError = payload => ({
  type: types.SET_AUTHENTICATION_ERROR,
  payload,
});

// LOGOUT
const logOutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
});

export const logOut = (doesClearSession = false) => async dispatch => {
  /**
   * LogOut User Thunk:
   * Logs the user out, by clearing the devfolio auth local storage
   * object and removing the user session
   * @param {boolean} doesClearSession - we'll send a clear session
   * request to the server incase the user is logging out via the nav.
   * In other cases, such as when the access token is expired, or a
   * 401 is encountered, doesClearSession remains false so as to prevent
   * recursive logOut requests.
   */
  try {
    if (doesClearSession) {
      await API.authentication.logOut();
    }
    clearStorage();
    clearDevfolioAuth();

    dispatch(logOutSuccess());
  } catch (error) {
    logActionError(types.LOGOUT_SUCCESS, error);
  }
};
