import * as types from '../constants/actions';

const logistics = (state = {}, action) => {
  switch (action.type) {
    case types.LOGOUT_SUCCESS:
      return {};

    default:
      return state;
  }
};

export default logistics;
