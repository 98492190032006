import isEqual from 'lodash/isEqual';
import { ACTION_STATUS } from '../constants';
import * as types from '../constants/actions';

const initialState = {
  count: 0,
  error: null,
  loading: false,
  result: [],
  searchQuery: '',
  totalPages: 0,
};

export const searchReducer = name => {
  return (state = initialState, action) => {
    const searchName = name.toUpperCase();

    switch (action.type) {
      case `${searchName}_${types.SEARCH}`: {
        const { searchQuery, error, loading } = action.payload;

        // Need to compare deep equality because searchQuery is an object
        // and was being compared using strict equality which caused bugs
        if (!isEqual(searchQuery, state.searchQuery) && loading) {
          return {
            ...initialState,
            loading,
            searchQuery,
          };
        }

        if (error) {
          return {
            ...state,
            count: state.count,
            error,
            searchQuery: state.searchQuery,
          };
        }

        if (loading) {
          return {
            ...state,
            count: state.count,
            loading,
            searchQuery: state.searchQuery,
          };
        }

        return {
          ...state,
          ...action.payload,
          searchQuery: state.searchQuery,
          result: [...state.result, ...action.payload.result],
        };
      }

      case types.UPDATE_USER_STATUS: {
        if (searchName === 'TEAMS' && action.payload.status === ACTION_STATUS.SUCCESS) {
          return {
            ...state,
            result: state.result.map(team => {
              if (team.code === action.payload.teamCode) {
                return {
                  ...team,
                  members: team.members.map(member => {
                    if (member.username === action.payload.username) {
                      return {
                        ...member,
                        status: action.payload.participantStatus,
                        applicationStatus: action.payload.participantStatus,
                      };
                    }
                    return member;
                  }),
                };
              }
              return team;
            }),
          };
        }
        return state;
      }

      case types.UPDATE_PARTICIPANT_STATUS_SUCCESS: {
        const { payload } = action;
        const { view, participant, status } = payload;

        if (
          searchName === 'ALLPARTICIPANTS' ||
          searchName === 'TEAMS' ||
          searchName === 'INDIVIDUALS' ||
          searchName === 'CHECKEDINPARTICIPANTS'
        ) {
          if (searchName === 'ALLPARTICIPANTS' && state.count) {
            return {
              ...state,
              result: state.result?.map(item => {
                // When the team ID matches the updated participant ID
                // update the team status and applicationStatus
                if (item?.team?.uuid && participant?.uuid && item?.team?.uuid === participant?.uuid) {
                  item.applicationStatus = status;
                  item.team.status = status;

                  return item;
                }

                // When a hacker username or uuid matches, update the status
                if (item?.username === participant?.username || item?.uuid === participant?.uuid) {
                  item.applicationStatus = status;
                  item.status = status;

                  return item;
                }

                return item;
              }),
            };
          }

          if (searchName === 'TEAMS' && view === 'team' && state.count) {
            return {
              ...state,
              result: state.result?.map(item => {
                if (item?.uuid && participant?.uuid && item?.uuid === participant?.uuid) {
                  item.status = status;

                  return item;
                }

                return item;
              }),
            };
          }

          if (searchName === 'INDIVIDUALS' && view === 'individual' && state.count) {
            return {
              ...state,
              result: state.result?.map(item => {
                if (
                  (item?.username && participant?.username && item?.username === participant?.username) ||
                  (item?.uuid && participant?.uuid && item?.uuid === participant?.uuid)
                ) {
                  item.applicationStatus = status;
                  item.status = status;

                  return item;
                }

                return item;
              }),
            };
          }

          if (searchName === 'CHECKEDINPARTICIPANTS' && state.count) {
            return {
              ...state,
              result: state.result?.map(item => {
                if (item?.username && participant?.username && item?.username === participant?.username) {
                  item.status = status;

                  return item;
                }

                return item;
              }),
            };
          }

          return state;
        }

        return state;
      }

      case types.CLEAR_UNREVIEWED_PARTICIPANTS: {
        if (searchName === 'UNREVIEWEDPARTICIPANTS') {
          return initialState;
        }

        return state;
      }

      case types.EXTEND_USER_RSVP_DEADLINE: {
        const { status } = action.payload;
        if (searchName === 'ALLPARTICIPANTS' && state.count && status === ACTION_STATUS.SUCCESS) {
          return {
            ...state,
            result: state.result?.map(item => {
              if (item.username && action.payload.username && item.username === action.payload.username) {
                item.rsvpTime = action.payload.rsvpTime;
                return item;
              }
              return item;
            }),
          };
        }
        return state;
      }

      case types.LOGOUT_SUCCESS:
        return { ...initialState };

      default:
        return state;
    }
  };
};

export const searchActionCreator = name => {
  return response => {
    return {
      type: `${name.toUpperCase()}_${types.SEARCH}`,
      payload: {
        count: response?.data?.count || 0,
        error: response?.error || null,
        loading: response?.loading || false,
        result: response?.data?.result || [],
        searchQuery: response?.searchQuery || '',
        totalPages: response?.data?.pages || 1,
      },
    };
  };
};
