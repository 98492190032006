import React from 'react';
import { useSelector } from 'react-redux';
import useAction from '@hooks/useAction';
import { usePrevious } from '@devfolioco/helpers';
import { fetchUserInfo } from 'actions/user';
import { isAuthenticated } from '@helpers';
import { ACTION_STATUS as STATUS } from '@constants';
import { useLDClient } from 'launchdarkly-react-client-sdk';

/**
 * This custom hook is used to fetch the basic user info
 * @returns {boolean} Whether the user info is loading right now
 */
export const useIdentifyUser = () => {
  const uuid = useSelector(({ authentication }) => authentication.uuid);
  const dispatchFetchUserData = useAction(fetchUserInfo);
  const fetchUserInfoStatus = useSelector(({ user }) => user.status.fetchUserInfo);
  const userData = useSelector(({ user }) => user.user);
  const previousFetchUserInfoStatus = usePrevious(fetchUserInfoStatus);
  const ldClient = useLDClient();

  // Fetch the user info when the component is mounted
  // if the user is authenticated
  React.useEffect(() => {
    if (isAuthenticated()) {
      dispatchFetchUserData(uuid);
    }
  }, [dispatchFetchUserData, uuid]);

  // Once the user details are fetched successfully
  // Identify the user to launch darkly with the details received from the API
  React.useEffect(() => {
    if (previousFetchUserInfoStatus !== fetchUserInfoStatus && fetchUserInfoStatus === STATUS.SUCCESS) {
      ldClient?.identify({
        key: userData.uuid,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
      });
    }
  }, [
    fetchUserInfoStatus,
    ldClient,
    previousFetchUserInfoStatus,
    userData.email,
    userData.firstName,
    userData.lastName,
    userData.uuid,
  ]);

  return fetchUserInfoStatus === STATUS.REQUEST;
};
