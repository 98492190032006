import React from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { StylesConfig, OptionTypeBase, Props, Theme } from 'react-select';

const customSelectStyles = (isMulti: boolean | undefined): StylesConfig<OptionTypeBase, boolean> => ({
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  container: provided => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => {
    const { isFocused, hasValue, options, selectProps } = state;
    const { isLoading } = selectProps;

    /**
     * This selects the first container inside input to give it a
     * background color whenever the input is in focus. This would be
     * applied only in the case of single select.
     *
     * For example - When the single react select has a value say 'SomeValue'
     * we will add a background just to the 'SomeValue' text so that it
     * appears selected when the user is updating its value. For more context
     * check this issue - https://github.com/devfolioco/organizer-dashboard/issues/82
     *
     * |¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯|
     * | |SomeValue|                            |
     * |________________________________________|
     */
    const getControlFocusedBackground = (): Record<string, unknown> => {
      if (isMulti) {
        return {};
      }
      return {
        '> div > div:first-of-type': {
          backgroundColor: isFocused && !isLoading && hasValue && options.length === 0 ? '#d2e0ff' : 'transparent',
        },
      };
    };

    return {
      ...provided,
      backgroundColor: isFocused ? '#ffffff' : '#fcfcfc',
      borderWidth: 2,
      borderColor: isFocused ? '#3770ff' : '#e4eaeb',
      boxShadow: 'none',
      outline: 'none',
      '&:hover': {
        borderColor: isFocused ? '#3770ff' : '#e4eaeb',
      },
      ...getControlFocusedBackground(),
    };
  },
  valueContainer: provided => ({
    ...provided,
    color: '#273339',
    fontFamily: 'Nunito Sans, sans-serif',
    fontSize: 18,
    height: '100%',
    lineHeight: '24px',
    minHeight: isMulti ? 52 : 44,
    padding: '4px 16px',
  }),
  option: (provided, state) => {
    const color = '#273339';
    let backgroundColor = '#fff';

    if (state.isSelected) {
      backgroundColor = '#BACDF7';
    } else if (state.isFocused) {
      backgroundColor = '#F2F2F3';
    }

    return { ...provided, color, backgroundColor, padding: 16, fontFamily: 'Nunito Sans, sans-serif' };
  },
  noOptionsMessage: provided => ({
    ...provided,
    fontFamily: 'Nunito Sans, sans-serif',
  }),
  loadingMessage: provided => ({
    ...provided,
    fontFamily: 'Nunito Sans, sans-serif',
  }),
  menu: provided => ({
    ...provided,
    border: 'none',
    borderRadius: '0 0 3px 3px',
    boxShadow: '0 5px 16px 0 rgba(0, 0, 0, 0.05)',
    margin: '16px 0',
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#4785FF',
    borderRadius: '8px',
    boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08)',
    padding: '4px',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#ffffff',
    fontFamily: 'Nunito Sans',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    padding: !state.data.isFixed ? '3px 3px 3px 12px !important' : '3px 12px 3px 12px !important',
  }),
  multiValueRemove: (provided, state) => {
    return {
      ...provided,
      alignItems: 'center',
      backgroundColor: '#132CB9',
      borderRadius: '50%',
      boxSizing: 'border-box',
      color: '#fff',
      display: !state.data.isFixed ? 'flex' : 'none',
      margin: 6,
      paddingLeft: 2,
      paddingRight: 2,
    };
  },
  clearIndicator: () => ({
    display: 'none',
  }),
  loadingIndicator: provided => ({
    ...provided,
    backgroundColor: 'transparent !important',
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      opacity: 0.5,
    };
  },
});

interface MultiSelectProps extends Props<OptionTypeBase, boolean> {
  isCreatable: boolean;
  name?: string;
  placeholder?: string;
  loadOptions?: any;
  onChange?: any;
  value?: any;
  isMulti?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  defaultOptions?: any;
}

const customTheme = (theme: Theme): Theme => {
  return {
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 48,
      menuGutter: 0,
    },
  };
};

const MultiSelect = ({
  defaultOptions,
  name,
  loadOptions,
  onChange,
  isCreatable,
  isClearable = false,
  isMulti,
  isDisabled,
  placeholder,
  ...rest
}: MultiSelectProps): React.ReactElement => {
  return isCreatable ? (
    <AsyncCreatableSelect
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      name={name}
      onChange={onChange}
      placeholder={placeholder || 'Start Typing!'}
      styles={customSelectStyles(isMulti)}
      isMulti={!!isMulti}
      cacheOptions
      isClearable={isClearable}
      isDisabled={!!isDisabled}
      theme={customTheme}
      {...rest}
    />
  ) : (
    <AsyncSelect
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      name={name}
      onChange={onChange}
      placeholder={placeholder || 'Start Typing!'}
      styles={customSelectStyles(isMulti)}
      cacheOptions
      isClearable={isClearable}
      isMulti={!!isMulti}
      isDisabled={!!isDisabled}
      theme={customTheme}
      {...rest}
    />
  );
};

export default MultiSelect;
