import momentTimezone from 'moment-timezone';
import React from 'react';
import MultiSelect from 'components/MultiSelect';
import MenuList from './MenuList';

export default class TimeZone extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      allTimezones: momentTimezone.tz.names().map(name => ({
        label: name,
        value: name,
      })),
    };
  }

  filterTimezones = inputValue => {
    const { allTimezones } = this.state;
    return allTimezones.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.filterTimezones(inputValue));
      }, 0);
    });

  render() {
    const { value, onChange, disabled } = this.props;
    const { allTimezones } = this.state;

    return (
      <MultiSelect
        placeholder=""
        isDisabled={disabled || false}
        value={value}
        onChange={onChange}
        defaultOptions={allTimezones}
        loadOptions={this.promiseOptions}
        components={{ MenuList }}
      />
    );
  }
}
