import uniqBy from 'lodash/uniqBy';
import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';

const initialState = {
  allCities: [],
  allColleges: [],
  allCountries: [],
  currentFilter: {
    city: [],
    college: [],
    country: [],
    notCountry: [],
    gender: [],
    status: [],
    view: 'all',
    ai_assisted_status: [],
  },
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ADDRESS_SUCCESS: {
      const { result } = action.payload;
      const address = { allCities: [], allCountries: [] };

      result.forEach(resultAddress => {
        if (typeof resultAddress.city === 'string' && resultAddress.city?.length) {
          address.allCities.push(resultAddress.city);
        }

        if (typeof resultAddress.country === 'string' && resultAddress.country?.length) {
          address.allCountries.push(resultAddress.country);
        }
      });

      const addressess = {};

      result.forEach(add => {
        if (add?.country && add.city) {
          const { country, city } = add;

          if (typeof country === 'string' && typeof city === 'string' && country?.length && city?.length) {
            if (!addressess[country]) {
              addressess[country] = [];
            }

            addressess[country] = [...addressess[country], city];
          }
        }
      });

      Object.keys(addressess).forEach(key => {
        addressess[key] = [...new Set(addressess[key])];
      });

      return {
        ...state,
        allAddress: addressess,
        allCities: uniqBy(address.allCities, name => name.toLowerCase()),
        allCountries: uniqBy(address.allCountries, name => name.toLowerCase()),
      };
    }

    case types.FETCH_COLLEGE_SUCCESS: {
      const { result } = action.payload;
      const allColleges = result.map(college => college.name);

      return {
        ...state,
        allColleges: [...new Set(allColleges)],
      };
    }

    case types.UPDATE_FILTER_ITEM: {
      const { name, value } = action.payload;
      if (name === 'country') {
        let allCities = [];

        if (value.length > 0) {
          value.forEach(country => {
            allCities = [...allCities, ...(state.allAddress?.[country] ?? [])];
          });
        } else {
          Object.values(state.allAddress).forEach(v => {
            allCities.push(...v);
          });
        }

        return {
          ...state,
          allCities,
          currentFilter: {
            ...state.currentFilter,
            [name]: value,
          },
        };
      }

      return {
        ...state,
        currentFilter: {
          ...state.currentFilter,
          [name]: value,
        },
      };
    }

    case types.UPDATE_FILTER: {
      return {
        ...state,
        currentFilter: {
          ...state.currentFilter,
          ...action.payload,
        },
      };
    }

    case types.BULK_UPDATE_PARTICIPANT_STATUS: {
      const { payload } = action;
      const { status, toStatus } = payload;

      if (status === STATUS.SUCCESS) {
        return {
          ...state,
          currentFilter: {
            ...state.currentFilter,
            status: [toStatus],
          },
        };
      }

      return state;
    }

    case types.LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
};

export default filter;
