import { ACTION_STATUS as STATUS } from '../constants';
import * as types from '../constants/actions';

const initialState = {
  activeHackerUsername: '',
  error: '',
  csvError: '',
  status: {
    changeApplicationDeadline: STATUS.READY,
    downloadCSV: STATUS.READY,
    extendRsvpDeadline: STATUS.READY,
    extendSubmissionDeadline: STATUS.READY,
    extendUserRsvpDeadline: STATUS.READY,
    joinTeam: STATUS.READY,
    leaveTeam: STATUS.READY,
    makeAdmin: STATUS.READY,
    sendAnnouncement: STATUS.READY,
    updateUserStatus: STATUS.READY,
  },
};

const admin = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_APPLICATION_DEADLINE:
      return {
        ...state,
        status: {
          ...state.status,
          changeApplicationDeadline: action.payload.status,
        },
      };

    case types.EXTEND_RSVP_DEADLINE:
      return {
        ...state,
        status: {
          ...state.status,
          extendRsvpDeadline: action.payload.status,
        },
      };

    case types.EXTEND_USER_RSVP_DEADLINE:
      return {
        ...state,
        activeHackerUsername: action?.payload?.username,
        status: {
          ...state.status,
          extendUserRsvpDeadline: action.payload.status,
        },
      };

    case types.EXTEND_SUBMISSION_DEADLINE:
      return {
        ...state,
        status: {
          ...state.status,
          extendSubmissionDeadline: action.payload.status,
        },
      };

    case types.SEND_ANNOUNCEMENT:
      return {
        ...state,
        status: {
          ...state.status,
          sendAnnouncement: action.payload.status,
        },
      };

    case types.DOWNLOAD_CSV: {
      const { payload } = action;
      if (payload.status === STATUS.FAILURE) {
        return {
          ...state,
          csvError: payload.error,
          status: {
            ...state.status,
            downloadCSV: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          downloadCSV: payload.status,
        },
      };
    }

    case types.JOIN_TEAM:
      return {
        ...state,
        activeHackerUsername: action?.payload?.username,
        error: action.payload.status === STATUS.FAILURE ? action.payload?.error : '',
        status: {
          ...state.status,
          joinTeam: action.payload.status,
        },
      };

    case types.LEAVE_TEAM:
      return {
        ...state,
        activeHackerUsername: action?.payload?.username,
        error: action.payload.status === STATUS.FAILURE ? action.payload?.error : '',
        status: {
          ...state.status,
          leaveTeam: action.payload.status,
        },
      };

    case types.MAKE_ADMIN:
      return {
        ...state,
        activeHackerUsername: action?.payload?.username,
        error: action.payload.status === STATUS.FAILURE ? action.payload?.error : '',
        status: {
          ...state.status,
          makeAdmin: action.payload.status,
        },
      };

    case types.UPDATE_USER_STATUS:
      return {
        ...state,
        activeHackerUsername: action?.payload?.username,
        status: {
          ...state.status,
          updateUserStatus: action.payload.status,
        },
      };
    default:
      return state;
  }
};

export default admin;
