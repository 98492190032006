import { ACTION_STATUS } from '@constants';
import * as types from '../constants/actions';

export const initialState = {
  error: '',
  isAuthenticated: false,
  isLoading: false,
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case types.SIGNIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        error: action.payload,
      };
    case types.SIGNIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.SIGNIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      };
    case types.CLEAR_AUTHENTICATION_ERRORS:
    case types.LOGOUT_SUCCESS:
      return { ...initialState };
    case types.SET_AUTHENTICATION_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case types.FETCH_USER_INFO: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          username: payload.user.username,
          isAuthenticated: true,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default authentication;
