import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { logActionError } from '@helpers/logger';
import { getThemeUUIDFromName, getDefaultTheme } from '@helpers/hackathonThemes';
import { toast } from 'react-toastify';
import { GRAPHQL_QUERIES } from '../api/graphql';
import {
  ACTION_STATUS as STATUS,
  STATE,
  SCOPES,
  SERVER_PARAM,
  OTHER_LINK,
  APPLICATION_FIELDS,
  SAVE,
} from '../constants';
import { API, EXTERNAL_API } from '../api';
import {
  getState,
  getError,
  hasHackathonProperties,
  hasHackathonSettingsProperties,
  getFileType,
  swapElements,
  getUploadFileConfig,
  trimAllStringValues,
  getArrayDifference,
  getDifferenceBykey,
} from '../helpers';
import * as types from '../constants/actions';

import { mapToHackathon } from './hackathon';
import { SERVER_ERRORS, ERRORS } from '../constants/errors';

const uploadLogoAction = payload => ({
  type: types.UPLOAD_LOGO,
  payload,
});

/**
 * This async action handles the logo upload flow by
 * @param {string} hackathonSlug - slug of the hackathon to be updated
 * @param {File} file - the accepted file
 */
export const uploadLogo = (hackathonSlug, file) => async dispatch => {
  dispatch(uploadLogoAction({ status: STATUS.REQUEST }));
  dispatch(updateSaveStatus(SAVE.PROGRESS));

  try {
    const { type, mimeType } = getFileType(file);

    const { data } = await API.organizer.getLogoUploadURL(hackathonSlug, type);
    const { signedUrl, url } = data;

    // Upload the document to the DB
    await EXTERNAL_API.uploadFile(signedUrl, file, getUploadFileConfig({ contentType: mimeType, isPrivate: false }));

    const {
      data: { logo: logoURL },
    } = await API.organizer.updateHackathonSettings(hackathonSlug, { logo: url });

    dispatch(uploadLogoAction({ status: STATUS.SUCCESS, hackathonSlug, logo: logoURL }));
    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    dispatch(uploadLogoAction({ status: STATUS.FAILURE }));
    dispatch(updateSaveStatus(SAVE.FAILURE));
    logActionError(types.UPLOAD_LOGO, error, { hackathonSlug });
  }
};

const uploadFaviconAction = payload => ({
  type: types.UPLOAD_FAVICON,
  payload,
});

export const uploadFavicon = (hackathonUUID, file) => async dispatch => {
  dispatch(uploadFaviconAction({ status: STATUS.REQUEST }));
  dispatch(updateSaveStatus(SAVE.PROGRESS));

  try {
    const { type, mimeType } = getFileType(file);

    const { data } = await API.organizer.getFaviconUploadURL(hackathonUUID, type);
    const { signedUrl, url } = data;

    await EXTERNAL_API.uploadFile(
      signedUrl,
      file,
      getUploadFileConfig({
        contentType: mimeType,
        isPrivate: false,
      })
    );

    const {
      data: { favicon },
    } = await API.organizer.updateHackathon(hackathonUUID, { favicon: url });

    dispatch(uploadFaviconAction({ status: STATUS.SUCCESS, favicon }));
    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    dispatch(uploadFaviconAction({ status: STATUS.FAILURE }));
    dispatch(updateSaveStatus(SAVE.FAILURE));
    logActionError(types.UPLOAD_FAVICON, error, { hackathonUUID });
  }
};

const uploadCoverAction = payload => ({
  type: types.UPLOAD_COVER,
  payload,
});

/**
 * This async action handles the cover image upload flow
 * @param {string} hackathonSlug - slug of the hackathon to be updated
 * @param {File} file - the accepted file
 */
export const uploadCoverImage = (hackathonSlug, file) => async dispatch => {
  dispatch(uploadCoverAction({ status: STATUS.REQUEST }));
  dispatch(updateSaveStatus(SAVE.PROGRESS));

  try {
    const { type, mimeType } = getFileType(file);

    const { data } = await API.organizer.getCoverImageUploadURL(hackathonSlug, type);
    const { signedUrl, url } = data;

    // Upload the document to the DB
    await EXTERNAL_API.uploadFile(signedUrl, file, getUploadFileConfig({ contentType: mimeType, isPrivate: false }));

    const {
      data: { cover_img },
    } = await API.organizer.updateHackathon(hackathonSlug, { cover: url });

    dispatch(uploadCoverAction({ status: STATUS.SUCCESS, hackathonSlug, cover: cover_img }));
    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    dispatch(uploadCoverAction({ status: STATUS.FAILURE }));
    dispatch(updateSaveStatus(SAVE.FAILURE));
    logActionError(types.UPLOAD_COVER, error, { hackathonSlug });
  }
};

const deleteImageAction = payload => ({
  type: types.DELETE_IMAGE,
  payload,
});

export const deleteImage = (hackathonSlug, image) => async dispatch => {
  try {
    dispatch(deleteImageAction({ status: STATUS.REQUEST }));
    if (image === 'logo') {
      await API.organizer.updateHackathonSettings(hackathonSlug, { logo: null });
    } else {
      await API.organizer.updateHackathon(hackathonSlug, { [image]: null });
    }

    dispatch(deleteImageAction({ status: STATUS.SUCCESS, image }));
  } catch (error) {
    dispatch(deleteImageAction({ status: STATUS.FAILURE }));
    logActionError(types.DELETE_IMAGE, error, { hackathonSlug, image });
  }
};

const fetchFaqsAction = payload => ({
  type: types.FETCH_FAQS,
  payload,
});

export const fetchFaqs = hackathonSlug => async dispatch => {
  try {
    dispatch(fetchFaqsAction({ status: STATUS.REQUEST }));
    const { data } = await API.root.fetchFaqs(hackathonSlug);

    const mappedFaqs = data?.map(faq => ({
      question: faq?.question,
      answer: faq?.answer,
      uuid: faq.uuid,
      position: faq?.order || 0,
    }));

    dispatch(fetchFaqsAction({ status: STATUS.SUCCESS, faqs: mappedFaqs, hackathonSlug }));
  } catch (error) {
    dispatch(fetchFaqsAction({ status: STATUS.FAILURE }));
    logActionError(types.FETCH_FAQS, error, { hackathonSlug });
  }
};

const addFaqAction = payload => ({
  type: types.ADD_FAQ,
  payload,
});

export const addFaq = (hackathonSlug, faq) => async dispatch => {
  try {
    dispatch(addFaqAction({ status: STATUS.REQUEST, faq }));
    const {
      hackathonSetup: { faqs },
    } = getState(STATE.ORGANIZER);

    const faqPosition = (faqs?.length || 0) + 1;
    const trimmedFAQ = trimAllStringValues(faq);
    const { data } = await API.root.addFaq(hackathonSlug, { ...trimmedFAQ, order: faqPosition });
    dispatch(addFaqAction({ status: STATUS.SUCCESS, faq: { ...trimmedFAQ, uuid: data.uuid, position: faqPosition } }));
  } catch (error) {
    dispatch(addFaqAction({ status: STATUS.FAILURE }));
    logActionError(types.ADD_FAQ, error, { faq, hackathonSlug });
  }
};

const updateFaqAction = payload => ({
  type: types.UPDATE_FAQ,
  payload,
});

export const updateFaq = (hackathonSlug, faq) => async dispatch => {
  try {
    dispatch(updateFaqAction({ status: STATUS.REQUEST, faq }));
    const trimmedFAQ = trimAllStringValues(faq);
    await API.root.updateFaq(hackathonSlug, faq.uuid, trimmedFAQ);
    dispatch(updateFaqAction({ status: STATUS.SUCCESS, faq: trimmedFAQ }));
  } catch (error) {
    dispatch(updateFaqAction({ status: STATUS.FAILURE }));
    logActionError(types.UPDATE_FAQ, error, { faq, hackathonSlug });
  }
};

const deleteFaqAction = payload => ({
  type: types.DELETE_FAQ,
  payload,
});

export const deleteFaq = (hackathonSlug, faqId) => async dispatch => {
  try {
    dispatch(deleteFaqAction({ status: STATUS.REQUEST }));

    await API.root.deleteFaq(hackathonSlug, faqId);

    dispatch(deleteFaqAction({ status: STATUS.SUCCESS, faqUUID: faqId }));
  } catch (error) {
    dispatch(deleteFaqAction({ status: STATUS.FAILURE }));
    logActionError(types.DELETE_FAQ, error, { faqId, hackathonSlug });
  }
};

const addJudgeAction = payload => ({
  type: types.ADD_JUDGE,
  payload,
});

export const addJudge = (hackathonSlug, judge) => async dispatch => {
  try {
    const processedJudgeObject = trimAllStringValues(judge);

    const {
      hackathonSetup: { judges },
    } = getState(STATE.ORGANIZER);
    dispatch(addJudgeAction({ status: STATUS.REQUEST, judge: processedJudgeObject }));

    let profileImage = '';
    const { type, mimeType } = getFileType(processedJudgeObject.avatar);
    const judgePosition = (judges?.length || 0) + 1;

    const { data } = await API.organizer.addJudge(hackathonSlug, { ...processedJudgeObject, order: judgePosition });

    if (typeof judge.avatar === 'object') {
      const {
        data: { url, signedUrl },
      } = await API.organizer.getJudgeUploadImageUrl(hackathonSlug, data.uuid, type);

      await EXTERNAL_API.uploadFile(
        signedUrl,
        judge.avatar,
        getUploadFileConfig({ contentType: mimeType, isPrivate: false })
      );

      const {
        data: { profile_img },
      } = await API.organizer.updateJudge(hackathonSlug, { ...processedJudgeObject, uuid: data.uuid, avatar: url });
      profileImage = profile_img;
    }

    dispatch(
      addJudgeAction({
        status: STATUS.SUCCESS,
        judge: { ...processedJudgeObject, avatar: profileImage, uuid: data.uuid, position: judgePosition },
      })
    );
  } catch (error) {
    const err = getError(error);

    if (err?.message === SERVER_ERRORS.judgeAlreadyExists) {
      dispatch(addJudgeAction({ status: STATUS.FAILURE, error: ERRORS.judgeErrors.judgeAlreadyExists }));
      return;
    }

    if (err?.message === SERVER_ERRORS.judgeUserHackathonAlreadyExists) {
      let errorMessage;
      const { role } = err.source;

      switch (role) {
        case 'organizer':
          errorMessage = ERRORS.judgeErrors.userHackathonAlreadyExistsErrors.organizer;
          break;

        case 'hacker':
          errorMessage = ERRORS.judgeErrors.userHackathonAlreadyExistsErrors.hacker;
          break;

        default:
          errorMessage = err?.message ?? '';
      }

      dispatch(updateJudgeAction({ status: STATUS.FAILURE, error: errorMessage }));
      return;
    }

    dispatch(addJudgeAction({ status: STATUS.FAILURE, error: err?.message ?? '' }));
    logActionError(types.ADD_JUDGE, error, { judge, hackathonSlug });
  }
};

const updateJudgeAction = payload => ({
  type: types.UPDATE_JUDGE,
  payload,
});

export const updateJudge = (hackathonSlug, judge) => async dispatch => {
  try {
    const processedJudgeObject = trimAllStringValues(judge);
    dispatch(updateJudgeAction({ status: STATUS.REQUEST, judge: processedJudgeObject }));
    let profileImage = processedJudgeObject.avatar;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { avatar: _, ...judgeWithoutAvatar } = processedJudgeObject;
    await API.organizer.updateJudge(hackathonSlug, judgeWithoutAvatar);

    if (typeof processedJudgeObject.avatar === 'object') {
      const { type, mimeType } = getFileType(processedJudgeObject.avatar);
      const {
        data: { url, signedUrl },
      } = await API.organizer.getJudgeUploadImageUrl(hackathonSlug, processedJudgeObject.uuid, type);

      await EXTERNAL_API.uploadFile(
        signedUrl,
        processedJudgeObject.avatar,
        getUploadFileConfig({ contentType: mimeType, isPrivate: false })
      );

      const {
        data: { profile_img },
      } = await API.organizer.updateJudge(hackathonSlug, { ...processedJudgeObject, avatar: url });
      profileImage = profile_img;
    }

    dispatch(updateJudgeAction({ status: STATUS.SUCCESS, judge: { ...processedJudgeObject, avatar: profileImage } }));
  } catch (error) {
    const err = getError(error);

    if (err?.message === SERVER_ERRORS.judgeAlreadyExists) {
      dispatch(updateJudgeAction({ status: STATUS.FAILURE, error: ERRORS.judgeErrors.judgeAlreadyExists }));
      return;
    }

    if (err?.message === SERVER_ERRORS.judgeUserHackathonAlreadyExists) {
      let errorMessage;
      const { role } = err.source;

      switch (role) {
        case 'organizer':
          errorMessage = ERRORS.judgeErrors.userHackathonAlreadyExistsErrors.organizer;
          break;

        case 'hacker':
          errorMessage = ERRORS.judgeErrors.userHackathonAlreadyExistsErrors.hacker;
          break;

        default:
          errorMessage = err?.message ?? '';
      }

      dispatch(updateJudgeAction({ status: STATUS.FAILURE, error: errorMessage }));
      return;
    }

    dispatch(updateJudgeAction({ status: STATUS.FAILURE, error: err?.message ?? '' }));
    logActionError(types.UPDATE_JUDGE, error, { judge, hackathonSlug });
  }
};

const deleteJudgeAction = payload => ({
  type: types.DELETE_JUDGE,
  payload,
});

export const deleteJudge = (hackathonSlug, judgeId) => async dispatch => {
  try {
    dispatch(deleteJudgeAction({ status: STATUS.REQUEST }));

    await API.organizer.deleteJudge(hackathonSlug, judgeId);

    dispatch(deleteJudgeAction({ status: STATUS.SUCCESS, judgeId }));
  } catch (error) {
    dispatch(deleteJudgeAction({ status: STATUS.FAILURE }));
    logActionError(types.DELETE_JUDGE, error, { judgeId, hackathonSlug });
  }
};

const createHackathonAction = payload => ({
  type: types.CREATE_HACKATHON,
  payload,
});

export const createHackathon = hackathon => async dispatch => {
  try {
    dispatch(createHackathonAction({ status: STATUS.REQUEST, hackathon }));

    const { name, timezone, selected, collegeUUID, company } = hackathon;
    const hackathonName = name.trim();

    const isOnline = selected === 'online';
    const isOnlineWithReview = selected === 'online_with_review';

    // Destructure the required settings as per the conditions
    const modeSpecificSettings = {
      ...(isOnlineWithReview ? { isOnlineWithReview } : {}),
    };

    const { data } = await API.organizer.createHackathon({
      name: hackathonName,
    });

    const promises = [];
    promises.push(
      API.organizer.updateHackathon(data.uuid, {
        timezone: hackathon.timezone,
        isOnline,
        ...(typeof collegeUUID === 'string' ? { collegeUUID } : {}),
        ...(typeof company?.name === 'string' && typeof company?.domain === 'string' ? { company } : {}),
      })
    );

    // Update the relevant settings
    promises.push(API.organizer.updateHackathonSettings(data.uuid, { ...modeSpecificSettings, rsvpBeforeTime: 3 }));

    promises.push(API.organizer.addTheme(data.uuid, getThemeUUIDFromName(getDefaultTheme())));
    promises.push(
      API.organizer.updateHackathonRequiredFields(data.uuid, {
        [SCOPES.ABOUT]: [SERVER_PARAM.bio],
        [SCOPES.LINKS]: [OTHER_LINK],
        [SCOPES.CONTACT]: [SERVER_PARAM.city],
        [SCOPES.SKILLS]: [SERVER_PARAM.hackerType, SERVER_PARAM.skills],
      })
    );

    await Promise.all(promises);

    dispatch(
      createHackathonAction({
        status: STATUS.SUCCESS,
        hackathon: {
          name: hackathonName,
          timezone,
          isOnline,
          isOnlineWithReview,
          slug: data.slug,
          uuid: data.uuid,
          subdomain: data.subdomain,
        },
      })
    );
  } catch (error) {
    const err = getError(error);
    if (err?.message === SERVER_ERRORS.unprocessableRequest) {
      const serverError = err?.source[Object.keys(err?.source)[0]]?.msg;
      if (serverError === SERVER_ERRORS.hackathonAlreadyExists) {
        dispatch(createHackathonAction({ status: STATUS.FAILURE, error: ERRORS.hackathonAlreadyExists }));
        return;
      }
    }
    dispatch(createHackathonAction({ status: STATUS.FAILURE, error: err?.message }));
    logActionError(types.CREATE_HACKATHON, error, { hackathon });
  }
};

const updateHackathonAction = payload => ({
  type: types.UPDATE_HACKATHON,
  payload,
});

const updateSaveStatus = payload => ({
  type: types.UPDATE_SAVE_STATUS,
  payload,
});

export const updateHackathon = (hackathonSlug, hackathon, updateOnSuccess = true) => async dispatch => {
  try {
    const promises = [];
    dispatch(updateHackathonAction({ status: STATUS.REQUEST, hackathon }));
    dispatch(updateSaveStatus(SAVE.PROGRESS));
    const updatedHackathonObj = Object.keys(hackathon).reduce((acc, key) => {
      if (hackathon[key] === '') {
        return {
          ...acc,
          [key]: null,
        };
      }
      if (typeof hackathon[key] === 'string') {
        return {
          ...acc,
          [key]: hackathon[key].trim(),
        };
      }

      return {
        ...acc,
        [key]: hackathon[key],
      };
    }, {});

    if (hasHackathonProperties(hackathon)) {
      promises.push(API.organizer.updateHackathon(hackathonSlug, updatedHackathonObj));
    }

    const { application: applicationFields, uuid: hackathonUUID, themes } = getState(STATE.ORGANIZER).hackathonSetup;

    // Get the array difference of themes to
    // understand what was removed and what was added.
    const [newlyAddedThemes, removedThemes] = getArrayDifference(themes, hackathon.themes);

    if (newlyAddedThemes.length > 0) {
      newlyAddedThemes.forEach(theme =>
        promises.push(API.organizer.addTheme(hackathonSlug, getThemeUUIDFromName(theme)))
      );
    }

    if (removedThemes.length > 0) {
      removedThemes.forEach(theme =>
        promises.push(API.organizer.deleteTheme(hackathonSlug, getThemeUUIDFromName(theme)))
      );
    }

    const replacedFields = replaceContactFields(applicationFields);

    promises.push(API.organizer.updateHackathonRequiredFields(hackathonUUID, replacedFields));

    if (hasHackathonSettingsProperties(hackathon)) {
      promises.push(API.organizer.updateHackathonSettings(hackathonSlug, updatedHackathonObj));
    }

    await Promise.all(promises);

    dispatch(updateHackathonAction({ status: STATUS.SUCCESS, hackathon, updateOnSuccess }));
    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    dispatch(updateHackathonAction({ status: STATUS.FAILURE }));
    dispatch(updateSaveStatus(SAVE.FAILURE));
    logActionError(types.UPDATE_HACKATHON, error, { hackathon, hackathonSlug, updateOnSuccess });
  }
};

const ABOUT_FIELDS = [
  SERVER_PARAM.username,
  SERVER_PARAM.firstName,
  SERVER_PARAM.lastName,
  SERVER_PARAM.dob,
  SERVER_PARAM.gender,
  SERVER_PARAM.shirtSize,
  SERVER_PARAM.bio,
  SERVER_PARAM.ethereumAddress,
];

const CONTACT_FIELDS = [
  SERVER_PARAM.email,
  SERVER_PARAM.phoneNumber,
  SERVER_PARAM.emergencyContactName,
  SERVER_PARAM.emergencyContactNumber,
];

const PERMANENT_FIELDS = [SERVER_PARAM.username, SERVER_PARAM.email, SERVER_PARAM.firstName, SERVER_PARAM.lastName];

const replaceContactFields = updatedApplication => {
  const applicationFields = cloneDeep(updatedApplication);
  const contactFields = applicationFields[SCOPES.CONTACT].filter(field => CONTACT_FIELDS.includes(field));
  applicationFields[SCOPES.CONTACT] = applicationFields[SCOPES.CONTACT].filter(
    field => !CONTACT_FIELDS.includes(field)
  );
  applicationFields[SCOPES.ABOUT] = [...applicationFields[SCOPES.ABOUT], ...contactFields];
  applicationFields[SCOPES.ABOUT] = applicationFields[SCOPES.ABOUT].filter(field => !PERMANENT_FIELDS.includes(field));
  return pickBy(applicationFields, x => (typeof x === 'object' ? x.length : true));
};

const getOrderIndex = (element, orderArray) => {
  const index = orderArray.indexOf(element);
  return index >= 0 ? index : orderArray.length - 1;
};

export const updateHackathonRequiredFields = (hackathonUUID, name, value) => async dispatch => {
  const {
    hackathonSetup: { application },
  } = getState(STATE.ORGANIZER);
  const orderArray = Object.keys(APPLICATION_FIELDS[name]);
  if (Array.isArray(value)) {
    value.sort((a, b) => {
      return getOrderIndex(a, orderArray) - getOrderIndex(b, orderArray);
    });
  }
  const updatedApplication = { ...application, [name]: value };
  dispatch(
    updateHackathonAction({
      hackathon: { application: updatedApplication },
      updateOnSuccess: true,
    })
  );
};

const INITIAL_APPLICATION = {
  [SCOPES.ABOUT]: [],
  [SCOPES.EDUCATION]: false,
  [SCOPES.SKILLS]: [],
  [SCOPES.LINKS]: [],
  [SCOPES.CONTACT]: [],
  [SCOPES.USER_HACKATHON]: [],
};

const processHackathonScope = hackathonScope => {
  const about = hackathonScope.find(scope => scope.resource === SCOPES.ABOUT);
  const contactFields = about.fields.filter(({ name }) => !ABOUT_FIELDS.includes(name));
  about.fields = about.fields.filter(({ name }) => ABOUT_FIELDS.includes(name));

  const contact = hackathonScope.find(scope => scope.resource === SCOPES.CONTACT);
  if (contact) {
    contact.fields = [...contact.fields, ...contactFields];
  } else {
    hackathonScope = [...hackathonScope, { resource: SCOPES.CONTACT, fields: contactFields }];
  }

  let education = hackathonScope.find(scope => scope.resource === SCOPES.EDUCATION);
  education = !!education?.fields[0]?.required;

  const requiredFields = hackathonScope.reduce((acc, scope) => {
    return {
      ...acc,
      [scope.resource]:
        scope.resource === SCOPES.EDUCATION
          ? education
          : scope.fields.filter(({ required }) => required).map(({ name }) => name),
    };
  }, {});
  return { ...INITIAL_APPLICATION, ...requiredFields };
};

const fetchHackathonAction = payload => ({
  type: types.FETCH_HACKATHON,
  payload,
});

export const fetchHackathon = hackathonSlug => async dispatch => {
  try {
    const { username } = getState(STATE.AUTHENTICATION);
    dispatch(fetchHackathonAction({ status: STATUS.REQUEST }));

    const [
      { data },
      {
        data: { hackathonScope },
      },
      { data: extraFields },
      { data: judges },
      { hackathons: hackathonEvents },
      { data: hackathonTracks },
    ] = await Promise.all([
      API.organizer.getHackathon(hackathonSlug),
      API.organizer.fetchHackathonRequiredFields(username, hackathonSlug),
      API.organizer.fetchExtraFields(hackathonSlug),
      API.organizer.fetchJudges(hackathonSlug),
      GRAPHQL_QUERIES.getHackathonEvents({ hackathonUUID: hackathonSlug }),
      API.organizer.fetchHackathonTracks(hackathonSlug),
    ]);

    const mappedExtraFields = extraFields.map(extraField => ({ ...extraField, position: extraField.order || 0 }));

    const application = { ...processHackathonScope(hackathonScope.scope), [SCOPES.EXTRA_FIELDS]: mappedExtraFields };

    dispatch(
      fetchHackathonAction({
        status: STATUS.SUCCESS,
        hackathon: {
          ...mapToHackathon({
            ...data,
            judges,
            events: hackathonEvents?.[0]?.events,
            event_groups: hackathonEvents?.[0]?.hackathon_event_groups,
            tracks: hackathonTracks,
          }),
          application,
        },
      })
    );
  } catch (error) {
    dispatch(fetchHackathonAction({ status: STATUS.FAILURE }));
    logActionError(types.FETCH_HACKATHON, error, { hackathonSlug });
  }
};

export const updateHackathonSetup = values => dispatch => {
  const { hackathonSetup } = getState(STATE.ORGANIZER);
  const changedFields = {};

  Object.keys(hackathonSetup).forEach(key => {
    if (hackathonSetup[key] !== values[key] && values[key] !== undefined) {
      changedFields[key] = values[key];
    }
  });

  dispatch(updateHackathon(hackathonSetup.uuid, changedFields, false));
};

export const clearHackathonSetup = () => ({
  type: types.CLEAR_HACKATHON_SETUP,
});

const deleteHackathonAction = payload => ({
  type: types.DELETE_HACKATHON,
  payload,
});

export const deleteHackathon = hackathonUuid => async dispatch => {
  try {
    dispatch(deleteHackathonAction({ status: STATUS.REQUEST, hackathonUuid }));
    await API.organizer.deleteHackathon(hackathonUuid);

    dispatch(deleteHackathonAction({ status: STATUS.SUCCESS, hackathonUuid }));
  } catch (error) {
    dispatch(deleteHackathonAction({ status: STATUS.FAILURE, hackathonUuid }));
    logActionError(types.DELETE_HACKATHON, error, { hackathonUuid });
  }
};

const fetchSponsorsStatus = payload => ({
  type: types.FETCH_SPONSORS,
  payload,
});

export const fetchSponsors = slug => async dispatch => {
  try {
    dispatch(fetchSponsorsStatus({ status: STATUS.REQUEST }));

    const { data } = await API.organizer.getAllSponsors(slug);

    if (Array.isArray(data) && data.length) {
      data.sort((a, b) => a.position - b.position);
      data.forEach(tier => {
        const { sponsors } = tier;
        if (Array.isArray(sponsors) && sponsors.length) {
          sponsors.sort((a, b) => a.position - b.position);
        }
      });
    }

    dispatch(fetchSponsorsStatus({ status: STATUS.SUCCESS, data }));
  } catch (error) {
    dispatch(fetchSponsorsStatus({ status: STATUS.FAILURE }));
    logActionError(types.FETCH_SPONSORS, error, { slug });
  }
};

const addSponsorTierStatus = payload => ({
  type: types.ADD_SPONSOR_TIER,
  payload,
});

export const addSponsorTier = (slug, tier) => async dispatch => {
  try {
    dispatch(addSponsorTierStatus({ status: STATUS.REQUEST }));

    const {
      hackathonSetup: { sponsors },
    } = getState(STATE.ORGANIZER);

    const filteredTier = { ...tier };
    let data = {};
    if (!tier.uuid) {
      filteredTier.position = sponsors?.length + 1 || 1;
      ({ data } = await API.organizer.addSponsorTier(slug, filteredTier));
      data.sponsors = [];
    } else {
      ({ data } = await API.organizer.updateSponsorTier(slug, tier.uuid, filteredTier));
    }

    dispatch(addSponsorTierStatus({ status: STATUS.SUCCESS, data, tierAdded: !tier.uuid }));
  } catch (error) {
    dispatch(addSponsorTierStatus({ status: STATUS.FAILURE }));
    logActionError(types.ADD_SPONSOR_TIER, error, { tier, slug });
  }
};

const addSponsorStatus = payload => ({
  type: types.ADD_SPONSOR,
  payload,
});

const uploadSponsorLogo = async (hackathonUUID, tierUUID, sponsorUUID, logo) => {
  const { type, mimeType } = getFileType(logo);
  const {
    data: { url, signedUrl },
  } = await API.organizer.getSponsorLogoUploadURL(hackathonUUID, tierUUID, sponsorUUID, type);

  await EXTERNAL_API.uploadFile(signedUrl, logo, getUploadFileConfig({ contentType: mimeType, isPrivate: false }));

  return url;
};

export const addSponsor = (hackathonUUID, tierUUID, sponsor) => async dispatch => {
  try {
    dispatch(addSponsorStatus({ status: STATUS.REQUEST }));

    const {
      hackathonSetup: { sponsors },
    } = getState(STATE.ORGANIZER);

    const currentTier = sponsors.find(tier => tier.uuid === tierUUID);

    const filteredSponsor = pickBy(sponsor, x => (typeof x === 'string' ? x.trim() : !!x));
    let data = {};
    if (!sponsor.uuid) {
      filteredSponsor.position = currentTier?.sponsors?.length + 1 || 1;
      const { logo, ...restSponsor } = filteredSponsor;
      const {
        data: { uuid },
      } = await API.organizer.addSponsor(hackathonUUID, tierUUID, restSponsor);
      const logoURL = await uploadSponsorLogo(hackathonUUID, tierUUID, uuid, logo);
      ({ data } = await API.organizer.updateSponsor(hackathonUUID, tierUUID, uuid, { logo: logoURL }));
    } else {
      const { logo, ...restSponsor } = filteredSponsor;
      if (logo instanceof File) {
        const logoURL = await uploadSponsorLogo(hackathonUUID, tierUUID, sponsor.uuid, logo);
        restSponsor.logo = logoURL;
      }
      ({ data } = await API.organizer.updateSponsor(hackathonUUID, tierUUID, sponsor.uuid, restSponsor));
    }

    dispatch(addSponsorStatus({ status: STATUS.SUCCESS, data, sponsorAdded: !sponsor.uuid, tierUUID }));
  } catch (error) {
    dispatch(addSponsorStatus({ status: STATUS.FAILURE }));
    logActionError(types.ADD_SPONSOR, error, { tierUUID, sponsor, hackathonUUID });
  }
};

const deleteSponsorStatus = payload => ({
  type: types.DELETE_SPONSOR,
  payload,
});

export const deleteSponsor = (hackathonUUID, tierUUID, sponsorUUID) => async dispatch => {
  try {
    dispatch(deleteSponsorStatus({ status: STATUS.REQUEST }));

    await API.organizer.deleteSponsor(hackathonUUID, tierUUID, sponsorUUID);

    dispatch(deleteSponsorStatus({ status: STATUS.SUCCESS, tierUUID, sponsorUUID }));
  } catch (error) {
    dispatch(deleteSponsorStatus({ status: STATUS.FAILURE }));
    logActionError(types.DELETE_SPONSOR, error, { tierUUID, sponsorUUID, hackathonUUID });
  }
};

const deleteSponsorTierStatus = payload => ({
  type: types.DELETE_SPONSOR_TIER,
  payload,
});

export const deleteSponsorTier = (hackathonUUID, tierUUID) => async dispatch => {
  try {
    dispatch(deleteSponsorTierStatus({ status: STATUS.REQUEST }));

    await API.organizer.deleteSponsorTier(hackathonUUID, tierUUID);

    dispatch(deleteSponsorTierStatus({ status: STATUS.SUCCESS, tierUUID }));
  } catch (error) {
    dispatch(deleteSponsorTierStatus({ status: STATUS.FAILURE }));
    logActionError(types.DELETE_SPONSOR_TIER, error, { tierUUID, hackathonUUID });
  }
};

const updateSponsorPositionStatus = payload => ({
  type: types.UPDATE_SPONSOR_POSITION,
  payload,
});

const getChangedElements = updatedElements => {
  const elementsWithNewPosition = updatedElements.map((element, index) => {
    if (element.position === index + 1) {
      return { ...element };
    }
    return { ...element, newPosiiton: index + 1 };
  });
  const elementsWithFixedPosition = updatedElements.map((element, index) => ({ ...element, position: index + 1 }));
  const elementsWithChangedPositions = elementsWithNewPosition.filter(element => !!element.newPosiiton);
  return [elementsWithChangedPositions, elementsWithFixedPosition];
};

export const updateSponsorPosition = (hackathonUUID, tierUUID, updatedSponsors) => async dispatch => {
  try {
    const [changedSponsors, fixedPositionSponsors] = getChangedElements(updatedSponsors);
    dispatch(updateSponsorPositionStatus({ status: STATUS.REQUEST, updatedSponsors: fixedPositionSponsors, tierUUID }));
    dispatch(updateSaveStatus(SAVE.PROGRESS));
    if (changedSponsors.length) {
      const promises = changedSponsors.map(sponsor =>
        API.organizer.updateSponsor(hackathonUUID, tierUUID, sponsor.uuid, { position: sponsor.newPosiiton })
      );
      await Promise.all(promises);
    }

    dispatch(updateSponsorPositionStatus({ status: STATUS.SUCCESS }));
    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    dispatch(updateSponsorPositionStatus({ status: STATUS.FAILURE }));
    dispatch(updateSaveStatus(SAVE.FAILURE));
    logActionError(types.UPDATE_SPONSOR_POSITION, error, { tierUUID, updatedSponsors, hackathonUUID });
  }
};

const swapTiersStatus = payload => ({
  type: types.SWAP_TIERS,
  payload,
});

export const swapTiers = (hackathonUUID, tierIndex1, tierIndex2) => async dispatch => {
  try {
    const {
      hackathonSetup: { sponsors: tiers },
    } = getState(STATE.ORGANIZER);
    const tier1 = tiers[tierIndex1];
    const tier2 = tiers[tierIndex2];
    dispatch(swapTiersStatus({ status: STATUS.REQUEST, updatedTiers: swapElements(tiers, tierIndex1, tierIndex2) }));
    dispatch(updateSaveStatus(SAVE.PROGRESS));

    await API.organizer.updateSponsorTier(hackathonUUID, tier1.uuid, { position: tierIndex2 + 1 });
    await API.organizer.updateSponsorTier(hackathonUUID, tier2.uuid, { position: tierIndex1 + 1 });

    dispatch(swapTiersStatus({ status: STATUS.SUCCESS }));
    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    dispatch(swapTiersStatus({ status: STATUS.FAILURE }));
    dispatch(updateSaveStatus(SAVE.FAILURE));
    logActionError(types.SWAP_TIERS, error, { tierIndex1, tierIndex2, hackathonUUID });
  }
};

const addExtraFieldStatus = payload => ({
  type: types.ADD_EXTRA_FIELD,
  payload,
});

export const addExtraField = (slug, extraFieldObj) => async dispatch => {
  try {
    dispatch(addExtraFieldStatus({ status: STATUS.REQUEST }));
    // Extract extraFields array
    const {
      hackathonSetup: {
        application: { extraFields },
      },
    } = getState(STATE.ORGANIZER);

    let filteredExtraFieldObj = {
      ...extraFieldObj,
      value: extraFieldObj?.value?.map(value => value?.trim()).join(','),
    };

    const extraQuestionsPosition = (extraFields?.length || 0) + 1;

    filteredExtraFieldObj = trimAllStringValues(filteredExtraFieldObj);
    filteredExtraFieldObj = pickBy(filteredExtraFieldObj, x => typeof x !== 'object');

    if (filteredExtraFieldObj.type !== 'file') {
      filteredExtraFieldObj = omit(filteredExtraFieldObj, ['file_type']);
    }
    if (!filteredExtraFieldObj.hasOwnProperty('order')) {
      filteredExtraFieldObj = { ...filteredExtraFieldObj, order: extraQuestionsPosition };
    }

    if (!extraFieldObj.uuid) {
      await API.organizer.addExtraField(slug, filteredExtraFieldObj);
    } else {
      await API.organizer.updateExtraField(slug, extraFieldObj.uuid, filteredExtraFieldObj);
    }
    const { data } = await API.organizer.fetchExtraFields(slug);
    dispatch(addExtraFieldStatus({ status: STATUS.SUCCESS, data, slug }));
  } catch (error) {
    dispatch(addExtraFieldStatus({ status: STATUS.FAILURE }));
    logActionError(types.ADD_EXTRA_FIELD, error, { extraFieldObj, slug });
  }
};

const deleteExtraFieldStatus = payload => ({
  type: types.DELETE_EXTRA_FIELD,
  payload,
});

export const deleteExtraField = (slug, extraFieldUUID) => async dispatch => {
  try {
    dispatch(deleteExtraFieldStatus({ status: STATUS.REQUEST }));
    await API.organizer.deleteExtraField(slug, extraFieldUUID);
    const { data } = await API.organizer.fetchExtraFields(slug);
    dispatch(deleteExtraFieldStatus({ status: STATUS.SUCCESS, data, slug }));
  } catch (error) {
    dispatch(deleteExtraFieldStatus({ status: STATUS.FAILURE }));
    logActionError(types.DELETE_EXTRA_FIELD, error, { extraFieldUUID, slug });
  }
};

/**
 * The organizer dashboard sections whose
 * elements can be sorted
 */
export const SORTABLE_ITEMS = {
  JUDGES: {
    key: 'judges',
    api: API.organizer.updateJudgePosition,
  },

  FAQ: {
    key: 'faqs',
    api: API.root.updateFaq,
  },

  PRIZES: {
    key: 'prizes',
    api: API.organizer.updatePrizePosition,
  },

  EXTRA_QUESTIONS: {
    key: 'extra_questions',
    api: API.organizer.updateExtraFieldPosition,
  },
};

const updateItemPositionStatus = payload => ({
  type: types.UPDATE_ITEM_POSITION,
  payload,
});

/**
 * This action updates the position of the possible items
 * from SORTABLE_ITEMS
 * @param {string} hackathonUUID Hackahton ID
 * @param {Array<Items>} updatedItems Items with updated order
 * @param {object} itemType The SORTABLE_ITEM to be updated
 */
export const updateItemPosition = (hackathonUUID, updatedItems, itemType) => async dispatch => {
  try {
    const [changedItems, allItemsWithUpdatedPositions] = getChangedElements(updatedItems);
    dispatch(
      updateItemPositionStatus({
        status: STATUS.REQUEST,
        updatedItems: allItemsWithUpdatedPositions,
        itemKey: itemType.key,
      })
    );
    dispatch(updateSaveStatus(SAVE.PROGRESS));

    if (changedItems.length) {
      const promises = changedItems.map(item => itemType.api(hackathonUUID, item.uuid, { order: item.newPosiiton }));
      await Promise.all(promises);
    }

    dispatch(updateItemPositionStatus({ status: STATUS.SUCCESS }));
    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    logActionError(types.UPDATE_ITEM_POSITION, error, { hackathonUUID, updatedItems, itemType });
    dispatch(updateItemPositionStatus({ status: STATUS.FAILURE }));
    dispatch(updateSaveStatus(SAVE.FAILURE));
  }
};

const sendContactEmailVerificationOTPStatus = payload => ({
  type: types.SEND_CONTACT_EMAIL_VERIFICATION_OTP,
  payload,
});

export const sendContactEmailVerificationOTP = (hackathonUUID, email) => async dispatch => {
  try {
    dispatch(sendContactEmailVerificationOTPStatus({ status: STATUS.REQUEST }));
    await API.organizer.sendContactEmailVerificationOTP(hackathonUUID, email);
    dispatch(sendContactEmailVerificationOTPStatus({ status: STATUS.SUCCESS }));
  } catch (error) {
    dispatch(sendContactEmailVerificationOTPStatus({ status: STATUS.FAILURE }));
    logActionError(types.SEND_CONTACT_EMAIL_VERIFICATION_OTP, error, { hackathonUUID });
  }
};

const verifyContactEmailOTPStatus = payload => ({
  type: types.VERIFY_CONTACT_EMAIL,
  payload,
});

export const verifyContactEmailOTP = (hackathonUUID, email, otp) => async dispatch => {
  try {
    dispatch(verifyContactEmailOTPStatus({ status: STATUS.REQUEST }));
    await API.organizer.verifyContactEmailOTP(hackathonUUID, email, otp);
    dispatch(verifyContactEmailOTPStatus({ status: STATUS.SUCCESS, email }));
  } catch (error) {
    dispatch(verifyContactEmailOTPStatus({ status: STATUS.FAILURE }));
    logActionError(types.VERIFY_CONTACT_EMAIL, error, { hackathonUUID });
  }
};

const computeAndApplySpeakerChanges = async ({ hackathonUUID, eventUUID, oldSpeakers = [], newSpeakers = [] }) => {
  const addedSpeakers = getDifferenceBykey(newSpeakers, oldSpeakers, 'value');

  const removedSpeakers = getDifferenceBykey(oldSpeakers, newSpeakers, 'value');

  const promises = [];
  if (addedSpeakers.length) {
    addedSpeakers.forEach(speaker => {
      promises.push(API.organizer.addSpeakerToEvent(hackathonUUID, speaker.value, eventUUID));
    });
  }
  if (removedSpeakers.length) {
    removedSpeakers.forEach(speaker => {
      promises.push(API.organizer.deleteSpeakerFromEvent(hackathonUUID, speaker.value, eventUUID));
    });
  }

  await Promise.all(promises);
};

const addEventAction = payload => ({
  type: types.ADD_EVENT,
  payload,
});

export const addEvent = (hackathonSlug, event) => async dispatch => {
  try {
    const {
      hackathonSetup: { judges },
    } = getState(STATE.ORGANIZER);

    const processedEventObject = trimAllStringValues(event);
    dispatch(addEventAction({ status: STATUS.REQUEST, event: processedEventObject }));

    // Filter empty values to null as per API Payload specs
    const payload = {
      ...processedEventObject,
      endsAt: processedEventObject.endsAt || null,
      description: processedEventObject.description || null,
      groupUUID: processedEventObject.groupUUID === 'default' ? null : processedEventObject.groupUUID,
    };

    // Add the event
    const response = await API.organizer.addHackathonEvent(hackathonSlug, payload);
    const createdEventUUID = response.data.uuid;

    const newSpeakers = processedEventObject.speakers;

    const newSpeakerDetails = judges?.filter(judge => newSpeakers?.map(speaker => speaker.value).includes(judge.uuid));

    if (newSpeakers !== null) {
      // The speakers have changed
      computeAndApplySpeakerChanges({ hackathonUUID: hackathonSlug, eventUUID: createdEventUUID, newSpeakers })
        .then(() => {
          dispatch(
            addEventAction({
              status: STATUS.SUCCESS,
              event: { ...processedEventObject, uuid: createdEventUUID, speakers: newSpeakerDetails },
            })
          );
        })
        .catch(() => {
          dispatch(addEventAction({ status: STATUS.FAILURE }));
          toast('The event could not be created successfully. Please try again.', { type: toast.TYPE.ERROR });
        });
    } else {
      // The speakers haven't changed
      dispatch(addEventAction({ status: STATUS.SUCCESS, event: processedEventObject }));
    }
  } catch (error) {
    toast('The event could not be created. Please try again.', { type: toast.TYPE.ERROR });
    dispatch(addEventAction({ status: STATUS.FAILURE }));
    logActionError(types.ADD_EVENT, error, { event, hackathonSlug });
  }
};

const updateEventAction = payload => ({
  type: types.UPDATE_EVENT,
  payload,
});

export const updateEvent = (hackathonSlug, event) => async dispatch => {
  try {
    const {
      hackathonSetup: { judges },
    } = getState(STATE.ORGANIZER);

    const processedEventObject = trimAllStringValues(event);
    dispatch(updateEventAction({ status: STATUS.REQUEST, event: processedEventObject }));

    // Filter empty values to null as per API Payload specs
    const payload = {
      ...processedEventObject,
      endsAt: processedEventObject.endsAt || null,
      description: processedEventObject.description || null,
      groupUUID: processedEventObject.groupUUID === 'default' ? null : processedEventObject.groupUUID,
    };

    await API.organizer.updateHackathonEvent(hackathonSlug, payload);

    const oldSpeakers = processedEventObject.prevSpeakers;
    const newSpeakers = processedEventObject.speakers;

    const newSpeakerDetails = judges?.filter(judge => newSpeakers?.map(speaker => speaker.value).includes(judge.uuid));

    if (oldSpeakers !== null && newSpeakers !== null && !isEqual(newSpeakers, oldSpeakers)) {
      computeAndApplySpeakerChanges({ hackathonUUID: hackathonSlug, eventUUID: event.uuid, oldSpeakers, newSpeakers })
        .then(() => {
          dispatch(
            updateEventAction({
              status: STATUS.SUCCESS,
              event: { ...processedEventObject, speakers: newSpeakerDetails },
            })
          );
        })
        .catch(() => {
          dispatch(updateEventAction({ status: STATUS.FAILURE }));
          toast('The event could not be created successfully. Please try again.', { type: toast.TYPE.ERROR });
        });
    } else {
      dispatch(
        updateEventAction({
          status: STATUS.SUCCESS,
          event: { ...processedEventObject, speakers: newSpeakerDetails },
        })
      );
    }
  } catch (error) {
    toast('The event could not be updated. Please try again.', { type: toast.TYPE.ERROR });
    dispatch(updateEventAction({ status: STATUS.FAILURE }));
    logActionError(types.UPDATE_EVENT, error, { event, hackathonSlug });
  }
};

const deleteEventAction = payload => ({
  type: types.DELETE_EVENT,
  payload,
});

export const deleteEvent = (hackathonSlug, eventUUID) => async dispatch => {
  try {
    dispatch(deleteEventAction({ status: STATUS.REQUEST }));

    await API.organizer.deleteHackathonEvent(hackathonSlug, eventUUID);

    dispatch(deleteEventAction({ status: STATUS.SUCCESS, eventUUID }));
  } catch (error) {
    toast('The event could not be deleted. Please try again.', { type: toast.TYPE.ERROR });
    dispatch(deleteEventAction({ status: STATUS.FAILURE }));
    logActionError(types.DELETE_EVENT, error, { eventUUID, hackathonSlug });
  }
};

// Event Groups Reducer

export const initializeEventGroup = hackathonSlug => async dispatch => {
  const initialGroups = [
    {
      name: 'Pre-Event',
      order: 1,
    },
    {
      name: 'Event',
      order: 2,
    },
  ];

  try {
    initialGroups.forEach(async group => {
      dispatch(addEventGroupAction({ status: STATUS.REQUEST, eventGroup: group }));

      // Add the event
      const response = await API.organizer.addHackathonEventGroup(hackathonSlug, group);
      const createdEventGroupUUID = response.data.uuid;

      dispatch(
        addEventGroupAction({
          status: STATUS.SUCCESS,
          eventGroup: { ...group, uuid: createdEventGroupUUID },
        })
      );
    });
  } catch (error) {
    dispatch(addEventGroupAction({ status: STATUS.FAILURE }));
  }
};

const addEventGroupAction = payload => ({
  type: types.ADD_EVENT_GROUP,
  payload,
});

export const addEventGroup = (hackathonSlug, eventGroup) => async dispatch => {
  try {
    const processedEventGroupObject = trimAllStringValues(eventGroup);
    dispatch(addEventGroupAction({ status: STATUS.REQUEST, eventGroup: processedEventGroupObject }));
    // Add the event
    const response = await API.organizer.addHackathonEventGroup(hackathonSlug, processedEventGroupObject);
    const createdEventGroupUUID = response.data.uuid;

    dispatch(
      addEventGroupAction({
        status: STATUS.SUCCESS,
        eventGroup: { ...processedEventGroupObject, uuid: createdEventGroupUUID },
      })
    );
  } catch (error) {
    toast('The event group could not be created. Please try again.', { type: toast.TYPE.ERROR });
    dispatch(addEventGroupAction({ status: STATUS.FAILURE }));
    logActionError(types.ADD_EVENT_GROUP, error, { eventGroup, hackathonSlug });
  }
};

const updateEventGroupAction = payload => ({
  type: types.UPDATE_EVENT_GROUP,
  payload,
});

export const updateEventGroup = (hackathonSlug, updatedValues) => async dispatch => {
  try {
    // updatedValues has uuid required and any other optional field to update
    const processedEventGroupObject = trimAllStringValues(updatedValues);
    dispatch(updateEventGroupAction({ status: STATUS.REQUEST, eventGroup: processedEventGroupObject }));
    await API.organizer.updateHackathonEventGroup(hackathonSlug, processedEventGroupObject);
    dispatch(
      updateEventGroupAction({
        status: STATUS.SUCCESS,
        eventGroup: processedEventGroupObject,
      })
    );
  } catch (error) {
    toast('The event group could not be updated. Please try again.', { type: toast.TYPE.ERROR });
    dispatch(updateEventGroupAction({ status: STATUS.FAILURE }));
    logActionError(types.UPDATE_EVENT_GROUP, error, { updatedValues, hackathonSlug });
  }
};

const deleteEventGroupAction = payload => ({
  type: types.DELETE_EVENT_GROUP,
  payload,
});

export const deleteEventGroup = (hackathonSlug, eventGroupUUID) => async dispatch => {
  try {
    dispatch(deleteEventGroupAction({ status: STATUS.REQUEST }));

    await API.organizer.deleteHackathonEventGroup(hackathonSlug, eventGroupUUID);

    dispatch(deleteEventGroupAction({ status: STATUS.SUCCESS, eventGroupUUID }));
  } catch (error) {
    toast('The event group could not be deleted. Please try again.', { type: toast.TYPE.ERROR });
    dispatch(deleteEventGroupAction({ status: STATUS.FAILURE }));
    logActionError(types.DELETE_EVENT, error, { eventGroupUUID, hackathonSlug });
  }
};

const addTrackAction = payload => ({
  type: types.ADD_TRACK,
  payload,
});

export const addTrack = (hackathonUUID, track) => async dispatch => {
  try {
    dispatch(addTrackAction({ status: STATUS.REQUEST }));

    const response = await API.organizer.addHackathonTrack(hackathonUUID, track);
    const createdTrackUUID = response.data.uuid;

    dispatch(addTrackAction({ status: STATUS.SUCCESS, track: { uuid: createdTrackUUID, ...track } }));
  } catch (error) {
    dispatch(addTrackAction({ status: STATUS.FAILURE }));
    logActionError(types.ADD_TRACK, error, { track, hackathonUUID });
  }
};

const updateTrackAction = payload => ({
  type: types.UPDATE_TRACK,
  payload,
});

export const updateTrack = (hackathonUUID, track) => async dispatch => {
  try {
    dispatch(updateTrackAction({ status: STATUS.REQUEST }));

    await API.organizer.updateHackathonTrack(hackathonUUID, track);

    dispatch(updateTrackAction({ status: STATUS.SUCCESS, track }));
  } catch (error) {
    dispatch(updateTrackAction({ status: STATUS.FAILURE }));
    logActionError(types.UPDATE_TRACK, error, { track, hackathonUUID });
  }
};

const deleteTrackAction = payload => ({
  type: types.DELETE_TRACK,
  payload,
});

export const deleteTrack = (hackathonUUID, trackUUID) => async dispatch => {
  try {
    dispatch(deleteTrackAction({ status: STATUS.REQUEST }));

    await API.organizer.deleteHackathonTrack(hackathonUUID, trackUUID);

    dispatch(deleteTrackAction({ status: STATUS.SUCCESS, trackUUID }));
  } catch (error) {
    dispatch(deleteTrackAction({ status: STATUS.FAILURE }));
    logActionError(types.DELETE_TRACK, error, { trackUUID, hackathonUUID });
  }
};

export const toggleWinnersPublishCheck = payload => dispatch => {
  dispatch({
    type: types.TOGGLE_WINNERS_PUBLISH_CHECK,
    payload,
  });
};
const toggleWinnersAnnouncedStatus = payload => ({
  type: types.TOGGLE_WINNERS_ANNOUNCED,
  payload,
});

export const toggleWinnersAnnounced = (hackathonUUID, winnersAnnounced) => async dispatch => {
  try {
    dispatch(toggleWinnersAnnouncedStatus({ status: STATUS.REQUEST }));

    await API.organizer.toggleWinnersAnnounced(hackathonUUID, winnersAnnounced);

    dispatch(toggleWinnersAnnouncedStatus({ status: STATUS.SUCCESS, data: winnersAnnounced }));
  } catch (error) {
    logActionError(types.TOGGLE_WINNERS_ANNOUNCED, error, { winnersAnnounced, hackathonUUID });
    dispatch(toggleWinnersAnnouncedStatus({ status: STATUS.FAILURE }));
  }
};
