import defaultAxios from 'axios';
import qs from 'query-string';

import { API_ROOT } from 'constants/environment';
import { isValidEmail } from '@devfolioco/helpers';
import { ORDER, ROLE, VOLUNTEER_ATTRIBUTES, TEAM_TAB_ROLE } from '../constants';
import {
  encodeObjectToBase64,
  formatValue,
  generateAllWhitelistDomains,
  apiErrorStatus,
  redirectToSignin,
} from '../helpers';
import { logOut } from '../actions/authentication';
import { PAGE_LIMIT, SORT } from '../constants/settings';
import { errorHandler } from './axios';
import store from '../store';

export const logger = console;

const volunteerAttrString = qs.stringify(VOLUNTEER_ATTRIBUTES);
const axios = defaultAxios.create({ withCredentials: true });

/**
 * This is an axios response interceptor which handles the access token
 * refresh mechanism, and logs a user out incase access_token header
 * is absent in the header of a request
 */
axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;

    if (response?.status === 401 || response?.status === 403) {
      // Log the user out in any other case (when the token has not expired)
      dispatchLogOutAndRoute();
    }

    if (apiErrorStatus.internalServerError(error)) {
      store.dispatch({ type: 'SERVER_ERROR' });
    }

    return errorHandler(error);
  }
);

const dispatchLogOutAndRoute = () => {
  store.dispatch(logOut());
  redirectToSignin(true);
};

const requests = {
  delete: url => axios.delete(`${API_ROOT}${url}`),
  deleteWithData: (url, data) => axios.delete(`${API_ROOT}${url}`, data),
  get: (url, params) => axios.get(`${API_ROOT}${url}`, params),
  patch: (url, body) => axios.patch(`${API_ROOT}${url}`, body),
  post: (url, body) => axios.post(`${API_ROOT}${url}`, body),
  put: (url, body) => axios.put(`${API_ROOT}${url}`, body),
};

export const API = {
  // Authentication APIs
  authentication: {
    // User Logout
    logOut: () => requests.post('users/logout'),
  },

  // User APIs
  user: {
    // Get all the user data
    fetchUserInfo: username => requests.get(`users/${username}/basic_info`),

    searchSkills: input => requests.get(`skills/search?q=${encodeURI(input)}`),

    getResumeViewURL: (username, url) =>
      /**
       * Obtains the DO temporary resume link
       * @param {username} - username of the user
       * @param {url} - the URL of the stored resume
       */
      requests.get(`users/${username}/resume/?url=${encodeURI(url)}`),

    // Get all the hackathons a user has participated in
    getUserHackathons: username => requests.get(`users/${username}/hackathon`),

    connectDiscord: (username: string, redirectURL: string) =>
      requests.get(`users/${username}/oauth/discord/connect?redirect_url=${encodeURI(redirectURL)}`),

    disconnectDiscord: (username: string, discordProviderUUID: string) =>
      requests.delete(`users/${username}/oauth/${discordProviderUUID}`),
  },

  hackathon: {
    getHackathonUserDetails: (username, hackathonID) => requests.get(`users/${username}/hackathon/${hackathonID}`),

    // Get a specficic hackathon info
    fetchHackathonData: hackathonID => requests.get(`hackathons/${hackathonID}`),

    // Get hackathon checklist items
    fetchChecklist: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/checklists`),

    // Get Algolia tab keys
    fetchReviewTabKey: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/reviewTabKey`),
    fetchAdminTabKey: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/adminTabKey`),
    fetchVolunteerTabKey: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/volunteerTabKey`),

    // Fetch previous hackathon prizes won by a user
    fetchPreviousHackathonPrizesWon: (username, page = 1) =>
      requests.get(`users/${username}/pastWonHackathons?page=${page}`),
    fetchPreviousHackathonsAttended: (username, page = 1, limit = 100) =>
      requests.get(`users/${username}/pastHackathons?page=${page}&limit=${limit}`),
    fetchUserReviewStats: username => requests.get(`users/${username}/reviewStats`),
    fetchProfileStats: username => requests.get(`users/${username}/fetchProfileStats`),
    fetchAcceptedFellowships: username => requests.get(`users/${username}/getAcceptedFellowships`),
    fetchAcceptedGrants: username => requests.get(`users/${username}/getAcceptedGrants`),
    sendHelloSignMou: hackathonUUID => requests.post(`hackathons/${hackathonUUID}/sendMouEmail`),
  },

  organizer: {
    // Enable AI assisted review for a hackathon
    toggleIsAiAssistedReviewsEnabled: (hackathonUUID, isAiReviewsEnabled) =>
      requests.post(`hackathons/${hackathonUUID}/settings/ai_assisted_reviews_enabled`, {
        ai_assisted_reviews_enabled: isAiReviewsEnabled,
      }),

    toggleShowMoreHackathonsSetting: (hackathonUUID, showMoreHackathons) =>
      requests.post(`hackathons/${hackathonUUID}/settings/set_show_more_hackathons`, {
        show_more_hackathons: showMoreHackathons,
      }),

    toggleAllowAnnouncementsPostHackathon: (hackathonUUID, allowAnnouncementsPostHackathon) =>
      requests.post(`hackathons/${hackathonUUID}/settings/allow_announcements_post_hackathon`, {
        allow_announcements_post_hackathon: allowAnnouncementsPostHackathon,
      }),

    // Get Basic details for the organizer's hackahtons
    getBasicOrganizerHackathons: username => requests.get(`users/${username}/my_organized_hackathons`),
    // Get all details for the organizer's hackathons
    getDetailedOrganizerHackathons: username => requests.get(`users/${username}/myhackathons`),

    getPrizes: hackathonID => requests.get(`hackathons/${hackathonID}/prizes`),

    getHackathonParticipants: (hackathonID, query = {}, page = 1, attributes = {}) => {
      const participantQuery = { ...query };

      participantQuery.limit = participantQuery?.limit || PAGE_LIMIT;
      participantQuery.page = page || participantQuery?.page || 1;
      participantQuery.order = participantQuery?.order || ORDER.DESC;

      return requests.get(
        `hackathons/${hackathonID}/participants?${qs.stringify(participantQuery)}&role=hacker&${qs.stringify(
          attributes
        )}`
      );
    },

    fetchExtraFields: slug => requests.get(`hackathons/${slug}/extra_fields`),
    addExtraField: (slug, extraFieldObj) => requests.post(`hackathons/${slug}/extra_fields`, extraFieldObj),
    updateExtraField: (slug, extraFieldUUID, extraFieldObj) =>
      requests.post(`hackathons/${slug}/extra_fields/${extraFieldUUID}`, extraFieldObj),
    updateExtraFieldPosition: (slug, extraFieldUUID, extraFieldObj) =>
      requests.post(`hackathons/${slug}/extra_fields/${extraFieldUUID}`, extraFieldObj),
    deleteExtraField: (slug, extraFieldUUID) => requests.delete(`hackathons/${slug}/extra_fields/${extraFieldUUID}`),

    getAllSponsors: slug => requests.get(`hackathons/${slug}/sponsorTiers`),

    addSponsorTier: (slug, tier) => requests.post(`hackathons/${slug}/sponsorTiers`, tier),
    updateSponsorTier: (slug, tierUUID, tier) => requests.post(`hackathons/${slug}/sponsorTiers/${tierUUID}`, tier),
    deleteSponsorTier: (slug, tierUUID) => requests.delete(`hackathons/${slug}/sponsorTiers/${tierUUID}`),

    addSponsor: (slug, tierUUID, sponsor) =>
      requests.post(`hackathons/${slug}/sponsorTiers/${tierUUID}/sponsors`, sponsor),
    updateSponsor: (slug, tierUUID, sponsorUUID, sponsor) =>
      requests.post(`hackathons/${slug}/sponsorTiers/${tierUUID}/sponsors/${sponsorUUID}`, sponsor),
    getSponsorLogoUploadURL: (slug, tierUUID, sponsorUUID, fileType) =>
      requests.get(
        `hackathons/${slug}/sponsorTiers/${tierUUID}/sponsors/${sponsorUUID}/signedURL?file_type=${fileType}`
      ),
    deleteSponsor: (slug, tierUUID, sponsorUUID) =>
      requests.delete(`hackathons/${slug}/sponsorTiers/${tierUUID}/sponsors/${sponsorUUID}`),

    getHackathonUnderageParticipants: (hackathonID, query = {}) => {
      const queryString = qs.stringify(query);
      return requests.get(`hackathons/${hackathonID}/underAgeParticipants?${queryString}`);
    },

    getHackathonTeams: (hackathonID, query = {}, page = 1, attributes) => {
      const teamQuery = { ...query };

      teamQuery.limit = teamQuery?.limit || PAGE_LIMIT;
      teamQuery.page = page || teamQuery?.page || 1;
      teamQuery.order = teamQuery?.order || ORDER.DESC;

      teamQuery.user_attributes = 'username,first_name,last_name,email,status';

      const queryString = qs.stringify(teamQuery);
      return requests.get(encodeURI(`hackathons/${hackathonID}/teams?${queryString}&${qs.stringify(attributes)}`));
    },

    getParticipantData: (hackathonID, participantUsername, attributes) =>
      requests.get(`hackathons/${hackathonID}/participants/${participantUsername}?${qs.stringify(attributes)}`),

    getParticipantResumeViewURL: (participantUsername, hackathonID, url) =>
      requests.get(`hackathons/${hackathonID}/participants/${participantUsername}/resume/?url=${encodeURI(url)}`),

    getParticipantsAddress: (hackathonID, page = 1, limit = PAGE_LIMIT, attributes = 'city,country') =>
      requests.get(
        `hackathons/${hackathonID}/participantsAddress?page=${page}&attributes=${attributes}&limit=${limit}`
      ),

    getParticipantsCollege: (hackathonID, page = 1, limit = PAGE_LIMIT) =>
      requests.get(`hackathons/${hackathonID}/participantsCollege?page=${page}&limit=${limit}`),

    getParticipantsStats: hackathonID => requests.get(`hackathons/${hackathonID}/stats`),

    getParticipantTicketViewURL: (participantUsername, hackathonID, url) =>
      requests.get(`hackathons/${hackathonID}/participants/${participantUsername}/ticket/?url=${encodeURI(url)}`),

    setHackerStatus: (hackathonID, participantUsername, status) =>
      requests.post(`hackathons/${hackathonID}/participants/${participantUsername}/status`, {
        status,
      }),

    getHackerReviewHistory: (hackathonID, participantUsername) =>
      requests.get(`hackathons/${hackathonID}/participants/${participantUsername}/user_hackathon_meta?page=1&limit=10`),

    getTeamReviewHistory: (hackathonID, teamID) =>
      requests.get(`hackathons/${hackathonID}/teams/${teamID}/team_meta?page=1&limit=10`),

    setTeamStatus: (hackathonID, teamID, status) =>
      requests.post(`hackathons/${hackathonID}/teams/${teamID}/status`, {
        status,
      }),

    getTeamMembers: (hackathonID, teamID, attributes) =>
      requests.get(encodeURI(`hackathons/${hackathonID}/teams/${teamID}/members?${qs.stringify(attributes)}`)),

    sendEmails: ({ hackathonID, status, users, sendNotifications = false }) =>
      requests.post(`hackathons/${hackathonID}/participants`, {
        status,
        users,
        send_notification: sendNotifications,
      }),

    bulkUpdateStatus: (hackathonID, fromStatus, toStatus) =>
      requests.post(`hackathons/${hackathonID}/participants/status`, {
        from: fromStatus,
        to: toStatus,
      }),

    sendReminderEmails: hackathonID => requests.post(`hackathons/${hackathonID}/sendReminderEmails`),

    undoRSVP: (hackathonSlug, username) =>
      requests.post(`hackathons/${hackathonSlug}/participants/${username}/undoRSVP`),

    getLogoUploadURL: (hackathonID, fileType) =>
      requests.get(`hackathons/${hackathonID}/signed_url_logo_upload?file_type=${fileType}`),

    getFaviconUploadURL: (hackathonID, fileType) =>
      requests.get(`hackathons/${hackathonID}/signed_url_favicon_upload?file_type=${fileType}`),

    getCoverImageUploadURL: (hackathonID, fileType = 'png') =>
      requests.get(`hackathons/${hackathonID}/signed_url_cover_upload?file_type=${fileType}`),

    addTheme: (hackathonID, themeUUID) =>
      requests.post(`hackathons/${hackathonID}/themes`, {
        theme_uuid: themeUUID,
      }),

    deleteTheme: (hackathonID, themeUUID) => requests.delete(`hackathons/${hackathonID}/themes/${themeUUID}`),

    addPrize: (hackathonSlug, prize) => requests.post(`hackathons/${hackathonSlug}/prizes`, prize),

    updatePrize: (hackathonSlug, prizeUUID, prize) =>
      requests.post(`hackathons/${hackathonSlug}/prizes/${prizeUUID}`, prize),

    addPrizeSponsor: (hackathonSlug, prizeUUID, sponsorUUID) =>
      requests.post(`hackathons/${hackathonSlug}/prizes/${prizeUUID}/sponsors/${sponsorUUID}`),

    deletePrizeSponsor: (hackathonSlug, prizeUUID, sponsorUUID) =>
      requests.delete(`hackathons/${hackathonSlug}/prizes/${prizeUUID}/sponsors/${sponsorUUID}`),

    updatePrizePosition: (hackathonSlug, prizeUUID, positionObject) =>
      requests.post(`hackathons/${hackathonSlug}/prizes/${prizeUUID}`, positionObject),

    deletePrize: (hackathonSlug, prizeId) => requests.delete(`hackathons/${hackathonSlug}/prizes/${prizeId}`),

    addHackathonEvent: (
      hackathonSlug,
      {
        title,
        description,
        type,
        url,
        meetingUrl,
        startsAt,
        endsAt,
        isPublicEvent,
        isURLPublic,
        groupUUID,
        location,
        googleMapsLocationURL,
      }
    ) =>
      requests.post(`hackathons/${hackathonSlug}/events/`, {
        title,
        description,
        type,
        url,
        meeting_url: meetingUrl,
        starts_at: startsAt,
        ends_at: endsAt,
        public: isPublicEvent,
        url_public: isURLPublic,
        groupUUID,
        location,
        google_maps_location_url: googleMapsLocationURL,
      }),

    updateHackathonEvent: (
      hackathonSlug,
      {
        uuid,
        title,
        description,
        type,
        url,
        meetingUrl,
        startsAt,
        endsAt,
        isPublicEvent,
        isURLPublic,
        groupUUID,
        location,
        googleMapsLocationURL,
      }
    ) =>
      requests.post(`hackathons/${hackathonSlug}/events/${uuid}`, {
        title,
        description,
        type,
        url,
        groupUUID,
        meeting_url: meetingUrl,
        starts_at: startsAt,
        ends_at: endsAt,
        public: isPublicEvent,
        url_public: isURLPublic,
        location,
        google_maps_location_url: googleMapsLocationURL,
      }),

    deleteHackathonEvent: (hackathonSlug, eventUUID) =>
      requests.delete(`hackathons/${hackathonSlug}/events/${eventUUID}`),

    updateHackathonEventGroup: (hackathonSlug, { uuid, name, order }) =>
      requests.post(`hackathons/${hackathonSlug}/event_groups/${uuid}`, {
        name,
        order,
      }),

    addHackathonEventGroup: (hackathonSlug, { name, order }) =>
      requests.post(`hackathons/${hackathonSlug}/event_groups`, {
        name,
        order,
      }),

    deleteHackathonEventGroup: (hackathonSlug, eventGroupUUID) =>
      requests.delete(`hackathons/${hackathonSlug}/event_groups/${eventGroupUUID}`),

    addWinner: (hackathonSlug, prizeId, projectID) =>
      requests.post(`hackathons/${hackathonSlug}/prizes/${prizeId}/winners/${projectID}`),

    deleteWinner: (hackathonSlug, prizeId, projectID) =>
      requests.delete(`hackathons/${hackathonSlug}/prizes/${prizeId}/winners/${projectID}`),

    getJudgeUploadImageUrl: (slug, judgeId, fileType) =>
      requests.get(`hackathons/${slug}/judges/${judgeId}/signed_url_judge_img_upload?file_type=${fileType}`),

    fetchJudges: hackathonID => requests.get(`hackathons/${hackathonID}/judges`),

    addJudge: (hackathonSlug, judge) =>
      requests.post(
        `hackathons/${hackathonSlug}/judges`,
        Object.assign(
          {
            name: judge.name,
            job_title: judge.job,
            email: judge?.email,
            type: judge?.type,
            about: judge.about,
            website: judge?.profile,
            twitter: judge?.twitter,
            linkedin: judge?.linkedin,
            instagram: judge?.instagram,
            farcaster: judge?.farcaster,
            company_name: judge.company.name,
            company_domain: judge.company.domain,
            order: judge.order,
            eligible_for_judging: typeof judge?.email === 'string',
            featured: judge?.featured,
            send_judging_invite: judge?.sendJudgingInvite ?? false,
          },
          judge.company.logo && {
            company_logo: judge.company.logo,
          },
          typeof judge.avatar === 'string' && {
            profile_img: judge.avatar,
          }
        )
      ),

    updateJudge: (hackathonSlug, judge) =>
      requests.post(
        `hackathons/${hackathonSlug}/judges/${judge.uuid}`,
        Object.assign(
          {
            name: judge.name,
            job_title: judge.job,
            email: judge?.email,
            type: judge?.type,
            about: judge.about,
            website: judge?.profile,
            twitter: judge?.twitter,
            linkedin: judge?.linkedin,
            instagram: judge?.instagram,
            farcaster: judge?.farcaster,
            company_name: judge?.company?.name ?? null,
            company_domain: judge?.company?.domain ?? null,
            eligible_for_judging: typeof judge?.email === 'string',
            featured: judge?.featured,
            send_judging_invite: judge?.sendJudgingInvite ?? false,
          },
          judge.company.logo && {
            company_logo: judge.company.logo,
          },
          typeof judge.avatar === 'string' && {
            profile_img: judge.avatar,
          }
        )
      ),

    updateJudgePosition: (hackathonUUID, judgeUUID, positionObject) =>
      requests.post(`hackathons/${hackathonUUID}/judges/${judgeUUID}`, positionObject),

    deleteJudge: (hackathonSlug, judgeId) => requests.delete(`hackathons/${hackathonSlug}/judges/${judgeId}`),

    deleteHackathon: hackathonUuid => requests.delete(`hackathons/${hackathonUuid}`),

    createHackathon: hackathon =>
      requests.post(`hackathons/`, {
        apply_mode: 'both',
        name: hackathon?.name,
      }),

    updateHackathon: (hackathonSlug, hackathon) =>
      requests.post(`hackathons/${hackathonSlug}`, {
        apply_mode: hackathon?.applyMode,
        approx_participant: hackathon?.approxParticipants,
        cover_img: hackathon?.cover,
        favicon: hackathon?.favicon,
        desc: hackathon?.about,
        ends_at: hackathon?.endsAt,
        location: hackathon?.location,
        logo: hackathon?.logo,
        name: hackathon?.name,
        starts_at: hackathon?.startsAt,
        status: hackathon?.status,
        type: hackathon?.type,
        tagline: hackathon?.tagline,
        team_min: hackathon?.minTeamSize,
        team_size: hackathon?.maxTeamSize,
        timezone: hackathon?.timezone,
        slug: hackathon?.subdomain,
        is_online: hackathon?.isOnline,
        city: hackathon?.city,
        state: hackathon?.state,
        country: hackathon?.country,
        company_name: hackathon?.company?.name,
        company_domain: hackathon?.company?.domain,
        company_logo: hackathon?.company?.logo,
        collegeUUID: hackathon?.collegeUUID,
      }),

    updateHackathonSettings: (hackathonSlug, hackathon) =>
      requests.post(`hackathons/${hackathonSlug}/settings`, {
        logo: hackathon?.logo,
        primary_color: hackathon?.primaryColor,
        contact_email: hackathon?.contactEmail,
        reg_starts_at: hackathon?.applicationsOpenAt,
        reg_ends_at: hackathon?.applicationsCloseAt,
        review: hackathon?.isOnlineWithReview || hackathon?.review,
        eligible_entities: hackathon?.eligibleEntities,
        allow_travel_reimbursement: hackathon?.allowTravelReimbursement,
        payment_mode: hackathon?.paymentMode,
        rsvp_time: hackathon?.rsvpBeforeTime ? hackathon.rsvpBeforeTime * 24 : undefined,
        site: hackathon?.website,
        twitter: hackathon?.twitter,
        facebook: hackathon?.facebook,
        instagram: hackathon?.instagram,
        linkedin: hackathon?.linkedin,
        medium: hackathon?.medium,
        discord: hackathon?.discord,
        slack: hackathon?.slack,
        telegram: hackathon?.telegram,
        farcaster: hackathon?.farcaster,
        coc_url: hackathon?.coc,
        winner_announce_at: hackathon?.winnerAnnounceAt,
        whitelist_domain: hackathon?.whitelistDomain || generateAllWhitelistDomains(hackathon?.website),
        women_only: hackathon?.womenOnly,
        judging_enabled: hackathon?.judgingEnabled,
        leaderboard_enabled: hackathon?.leaderboardEnabled,
        judging_starts_at: hackathon?.judgingStartsAt,
        judging_ends_at: hackathon?.judgingEndsAt,
        max_judges_per_project: hackathon?.maxJudges,
        min_judges_per_project: hackathon?.minJudges,
        mou_signatory_team: hackathon?.mouSignatoryTeam,
        mou_signatory_name: hackathon?.mouSignatoryName,
        mou_signatory_title: hackathon?.mouSignatoryTitle,
        mou_signatory_email_id: hackathon?.mouSignatoryEmailId,
        mou_place_of_signing: hackathon?.mouPlaceOfSigning,
        mou_sponsor_tiers:
          hackathon?.mouSponsorTierOne ||
          hackathon?.mouSponsorTierTwo ||
          hackathon?.mouSponsorTierOneRow ||
          hackathon?.mouSponsorTierTwoRow
            ? {
                mou_sponsor_tier_one: hackathon?.mouSponsorTierOne,
                mou_sponsor_tier_one_row: hackathon?.mouSponsorTierOneRow,
                mou_sponsor_tier_two: hackathon?.mouSponsorTierTwo,
                mou_sponsor_tier_two_row: hackathon?.mouSponsorTierTwoRow,
              }
            : undefined,
        organizer_phone_number: hackathon?.organizerPhoneNumber,
        website_url: hackathon?.websiteUrl,
        partner_logos_added: hackathon?.partnerLogosAdded,
        apply_now_integrated: hackathon?.applyNowIntegrated,
        kyc_submitted: hackathon?.kycSubmitted,
        discord_guild_id: hackathon?.discordGuildID,
        discord_role: hackathon?.discordRole,
        discord_checkin_enable: hackathon?.discordCheckinEnabled,
        discord_bot_channel_name: hackathon?.discordBotChannelName,
        referral_prize_doc_link: hackathon?.referralLeaderboardPrizeURL,
        quadratic_voting_enabled: hackathon?.quadraticVotingEnabled,
        quadratic_voting_ends_at: hackathon?.quadraticVotingEndsAt,
        quadratic_voting_results_announced: hackathon?.quadraticVotingResultsAnnounced,
        quadratic_voting_prize_pool_amount: hackathon?.quadraticVotingPrizePool,
        quadratic_voting_starts_at: hackathon?.quadraticVotingStartsAt,
        mid_hackathon_checkin_enabled: hackathon?.midHackathonCheckinEnabled,
        mid_hackathon_checkin_starts_at: hackathon?.midHackathonCheckinStartsAt,
        mid_hackathon_checkin_ends_at: hackathon?.midHackathonCheckinEndsAt,
      }),

    /**
      Required Permission: HACKATHON_OTHERSETTINGS write
    */
    updateQuadraticVotingChain: (slug, chain) =>
      requests.post(`hackathons/${slug}/settings/quadratic_voting_chain`, { quadratic_voting_chain: chain }),

    /**
      Required Permission: HACKATHON_OTHERSETTINGS write
    */
    updateQuadraticVotingCurrency: (slug, currency) =>
      requests.post(`hackathons/${slug}/settings/quadratic_voting_currency`, { quadratic_voting_currency: currency }),

    getHackathon: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/my`),
    getHackathonFileViewURL: (username, hackathonID, scopeID, fileURL) => {
      const fileURLSplit = fileURL.split('.');
      const extension = fileURLSplit[fileURLSplit.length - 1];

      return requests.get(
        `hackathons/${hackathonID}/participants/${username}/files/${scopeID}?url=${fileURL}&file_type=${extension}`
      );
    },

    checkSubdomainAvailability: (uuid, subdomain) =>
      requests.get(`hackathons/${uuid}/isSlugAvailable?slug=${subdomain}`),

    fetchHackathonRequiredFields: (username, slug) => requests.get(`users/${username}/hackathon/${slug}/scope`),
    updateHackathonRequiredFields: (slug, requiredFields) =>
      requests.post(`hackathons/${slug}/requiredFields`, requiredFields),

    sendContactEmailVerificationOTP: (hackathonSlug, email) =>
      requests.post(`hackathons/${hackathonSlug}/send_contact_verification_email`, {
        email,
      }),
    verifyContactEmailOTP: (hackathonSlug, email, otp) =>
      requests.post(`hackathons/${hackathonSlug}/verify_contact_email`, {
        email,
        otp: parseInt(otp, 10),
      }),
    searchJudges: (hackathonSlug, query) => requests.get(`hackathons/${hackathonSlug}/judges/search?q=${query}`),
    searchSponsors: (hackathonSlug, query) => requests.get(`hackathons/${hackathonSlug}/sponsors/search?q=${query}`),
    addSpeakerToEvent: (hackathonSlug, judgeUUID, eventUUID) =>
      requests.post(`hackathons/${hackathonSlug}/events/${eventUUID}/speakers/${judgeUUID}`),
    deleteSpeakerFromEvent: (hackathonSlug, judgeUUID, eventUUID) =>
      requests.delete(`hackathons/${hackathonSlug}/events/${eventUUID}/speakers/${judgeUUID}`),
    fetchHackathonTracks: hackathonUUID => requests.get(`hackathons/${hackathonUUID}/tracks`),
    addHackathonTrack: (hackathonUUID, track) => requests.post(`hackathons/${hackathonUUID}/tracks`, track),
    updateHackathonTrack: (hackathonUUID, track) =>
      requests.post(`hackathons/${hackathonUUID}/tracks/${track.uuid}`, track),
    deleteHackathonTrack: (hackathonUUID, trackUUID) =>
      requests.delete(`hackathons/${hackathonUUID}/tracks/${trackUUID}`),

    addPrizeTrack: (hackathonUUID, prizeTrack) => requests.post(`hackathons/${hackathonUUID}/prize_tracks`, prizeTrack),
    updatePrizeTrack: (hackathonUUID, prizeTrackUUID, prizeTrack) =>
      requests.post(`hackathons/${hackathonUUID}/prize_tracks/${prizeTrackUUID}`, prizeTrack),
    deletePrizeTrack: (hackathonUUID, prizeTrackUUID) =>
      requests.delete(`hackathons/${hackathonUUID}/prize_tracks/${prizeTrackUUID}`),
    verifyWebsiteIntegrations: hackathonUUID => requests.post(`hackathons/${hackathonUUID}/verify/integrations`),

    toggleWinnersAnnounced: (hackathonUUID, winnersAnnounced) =>
      requests.post(`hackathons/${hackathonUUID}/settings/set_winner_announced`, {
        winner_announced: winnersAnnounced,
      }),
  },

  analytics: {
    fetchAnalytics: (
      hackathonSlug,
      queryObject,
      fields = 'total_applications,review_stats,submitted_for_review,rsvp_stats,project_stats'
    ) => {
      const queryString = qs.stringify({ role: ROLE.HACKER, ...queryObject });
      return requests.get(`hackathons/${hackathonSlug}/analytics?${queryString}&fields=${fields}`);
    },

    fetchLiveStats: (hackathonSlug, queryObject) => {
      const queryString = qs.stringify({ role: ROLE.HACKER, ...queryObject });
      return requests.get(`hackathons/${hackathonSlug}/liveStats?${queryString}`);
    },

    fetchReimbursementStats: (hackathonSlug, queryObject) => {
      const queryString = qs.stringify({ ...queryObject });
      return requests.get(`hackathons/${hackathonSlug}/reimbursementStats?${queryString}`);
    },

    fetchTShirtStats: (hackathonSlug, queryObject) => {
      const queryString = qs.stringify({ ...queryObject });
      return requests.get(`hackathons/${hackathonSlug}/tShirtSizeStats?${queryString}`);
    },

    fetchCountStats: (hackathonSlug, startDate, endDate, role = ROLE.HACKER) =>
      requests.get(`hackathons/${hackathonSlug}/countByDays?start_date=${startDate}&end_date=${endDate}&role=${role}`),

    fetchEducationStats: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/educationStats`),
    fetchLocationStats: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/locationStats`),
    fetchGenderStats: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/genderDistribution`),

    fetchAgeStats: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/ageDistribution`),
  },

  volunteer: {
    updateUserNote: (hackathonSlug, username, note) =>
      requests.post(`hackathons/${hackathonSlug}/participants/${username}/note`, {
        note,
      }),

    updateChecklist: (hackathonSlug, username, checklistId, value) =>
      requests.post(`hackathons/${hackathonSlug}/participants/${username}/checklists/${checklistId}`, {
        value,
      }),

    getParticipantData: (hackathonSlug, participantUsername) =>
      requests.get(`hackathons/${hackathonSlug}/participants/${participantUsername}?${volunteerAttrString}`),

    // Search the participants who have RSVPd, checked-in, or have been reimbursed
    // @param {string} hackathonSlug slug of the hackathon
    // @param {string} searchString either username or email
    searchParticipants: (hackathonSlug, searchString, instantSearch) => {
      const query = {
        limit: PAGE_LIMIT,
        page: 1,
        sort: SORT,
        role: encodeObjectToBase64({
          in: [ROLE.HACKER, ROLE.GUEST],
        }),
        user: encodeObjectToBase64({
          [isValidEmail(searchString) ? 'email' : 'username']: formatValue(
            searchString,
            instantSearch ? '$eq' : 'ilike'
          ),
        }),
        checklist: true,
        conference_ticket: true,
      };

      const queryString = qs.stringify(query);
      return requests.get(encodeURI(`hackathons/${hackathonSlug}/participants?${queryString}`));
    },

    updateHasSubmittedConsentForm: (hackathonSlug, username, hasSubmittedConsent = false) =>
      requests.post(`hackathons/${hackathonSlug}/participants/${username}/consentFormSubmitted`, {
        submitted: hasSubmittedConsent,
      }),

    updateConferenceCheckin: (hackathonSlug, username, checkin = false) =>
      requests.post(`hackathons/${hackathonSlug}/participants/${username}/conferenceCheckin`, {
        checkin,
      }),
  },

  admin: {
    changeApplicationDeadline: (hackathonSlug, date) =>
      requests.post(`hackathons/${hackathonSlug}/settings`, {
        reg_ends_at: date,
      }),

    extendRsvpDeadline: (hackathonSlug, date) =>
      requests.post(`hackathons/${hackathonSlug}/extendRSVP`, {
        rsvp_time: date,
      }),

    extendUserRsvpDeadline: (hackathonSlug, username, date) =>
      requests.post(`hackathons/${hackathonSlug}/participants/${username}/extendRSVP`, { rsvp_time: date }),

    extendSubmissionDeadline: (hackathonSlug, date) => requests.post(`hackathons/${hackathonSlug}`, { ends_at: date }),

    downloadCSV: (hackathonSlug, fieldQuery) =>
      requests.get(`hackathons/${hackathonSlug}/CSVData?${fieldQuery}`, { responseType: 'blob' }),

    sendAnnouncement: (hackathonSlug, obj) => requests.post(`hackathons/${hackathonSlug}/announcements`, obj),

    getPastAnnouncements: (hackathonSlug, page, limit, filters = {}, query = '') =>
      requests.get(
        `hackathons/${hackathonSlug}/announcements/?page=${page}&limit=${limit}${
          query && query?.length > 0 ? `&query=${query}` : ''
        }`,
        filters
      ),

    joinTeam: (username, hackathonID, code) => {
      if (code !== null) {
        return requests.post(`users/${username}/hackathon/${hackathonID}/joinTeam`, {
          code,
        });
      }

      return new Error();
    },

    leaveTeam: (username, hackathonID, teamID) =>
      requests.deleteWithData(`users/${username}/hackathon/${hackathonID}/leaveTeam`, {
        data: { team_id: teamID },
      }),

    sendAnnouncementEmail: (hackathonSlug, announcementUUID) =>
      requests.post(`hackathons/${hackathonSlug}/announcements/${announcementUUID}/announceViaEmail`),

    makeAdmin: (username, hackathonSlug, teamID) =>
      requests.post(`hackathons/${hackathonSlug}/teams/${teamID}/admin`, {
        username,
      }),
    removeOrganizerFromTeam: (hackathonSlug, userUUID) =>
      requests.post(`hackathons/${hackathonSlug}/remove_organizer`, {
        user_uuid: userUUID,
      }),
  },

  feedback: {
    fetchFeedback: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/feedbackResponse`),

    fetchReviews: (hackathonSlug, page = 1, limit = PAGE_LIMIT) =>
      requests.get(`hackathons/${hackathonSlug}/feedbackReview?page=${page}&limit=${limit}`),

    fetchFeedbackStats: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/feedbackStats`),

    sendReminderEmails: hackathonSlug => requests.post(`hackathons/${hackathonSlug}/feedbackReminder`),
  },

  squad: {
    fetchHackathonOrganizersAndSquad: hackathonSlug =>
      requests.get(`hackathons/${hackathonSlug}/participants?role=${TEAM_TAB_ROLE}&page=1`),

    addSquadMember: (hackathonSlug, memberUsername, tabs) =>
      requests.post(`hackathons/${hackathonSlug}/squad`, {
        username: memberUsername,
        tabs: tabs.join(','),
      }),

    addOrganizer: (hackathonSlug, memberUUID) =>
      requests.post(`hackathons/${hackathonSlug}/add_organizer`, {
        user_uuid: memberUUID,
      }),

    updateSquadMemberTabs: (hackathonSlug, memberUsername, tabs) =>
      requests.post(`hackathons/${hackathonSlug}/squad/${memberUsername}`, {
        tabs: tabs.join(','),
      }),

    deleteSquadMember: (hackathonSlug, memberUsername) =>
      requests.delete(`hackathons/${hackathonSlug}/squad/${memberUsername}`),

    acceptInvite: (username, hackathonSlug, email, token) =>
      requests.post(`hackathons/${hackathonSlug}/squad`, {
        email,
        invite_token: token,
      }),

    // Search the participants
    // @param {string} hackathonSlug slug of the hackathon
    // @param {string} username
    searchMember: (hackathonSlug, username) => {
      return requests.get(encodeURI(`hackathons/${hackathonSlug}/searchSquad?username=${username}`));
    },
  },
  fare: {
    getFares: (slug, page, query) => requests.get(`hackathons/${slug}/fare?page=${page}${query}`),
    postFares: (slug, obj) => requests.post(`hackathons/${slug}/fare`, obj),
  },

  root: {
    getAllUsers: (query = {}, page = 1, attributes = {}) => {
      const participantQuery = { ...query };

      participantQuery.limit = participantQuery?.limit || PAGE_LIMIT;
      participantQuery.page = page || participantQuery?.page || 1;
      participantQuery.order = ORDER.DESC;

      return requests.get(`users?${qs.stringify(participantQuery)}&role=hacker&${qs.stringify(attributes)}`);
    },

    getAllUserInfo: username => requests.get(`users/${username}`),

    getSkills: (query, page = 1, limit = PAGE_LIMIT) => requests.get(`skills?page=${page}&limit=${limit}${query}`),

    updateSkill: (uuid, name, description) =>
      requests.post(`skills/${uuid}`, {
        name,
        description,
      }),

    verifySkill: uuid => requests.post(`skills/${uuid}/verify`),

    unverifySkill: uuid => requests.post(`skills/${uuid}/unverify`),

    deleteSkill: uuid => requests.delete(`skills/${uuid}`),

    getColleges: (query = '', page = 1, limit = PAGE_LIMIT) =>
      requests.get(`college/all?&page=${page}&limit=${limit}${query}`),

    updateCollege: (uuid, college) =>
      requests.post(`college/${uuid}`, {
        name: college.name,
        short_name: college.short_name,
        url: college.url,
        logo: college.logo,
        city: college.city,
        state: college.state,
        country: college.country,
      }),

    addCollege: college => requests.post('college', college),

    verifyCollege: uuid => requests.post(`college/${uuid}/verify`),

    unverifyCollege: uuid => requests.post(`college/${uuid}/unverify`),

    deleteCollege: uuid => requests.delete(`college/${uuid}`),

    getHashtags: (query = '', page = 1, limit = PAGE_LIMIT) =>
      requests.get(`hashtags?page=${page}&limit=${limit}&order=${ORDER.ASC}${query}`),

    updateHashtag: (uuid, name, verified) => requests.post(`hashtags/${uuid}`, { name, verified }),

    deleteHashtag: uuid => requests.delete(`hashtags/${uuid}`),

    replaceAndDeleteHashtag: (uuid, replaceWithUUID) => requests.delete(`hashtags/${uuid}/replace/${replaceWithUUID}`),

    getHackathons: (query = '', page = 1, limit = 5) =>
      requests.get(`hackathons/all?page=${page}&limit=${limit}${query}`),

    getJobs: (page, limit, filters = {}, query = '') =>
      requests.post(
        `jobs/search?page=${page}&limit=${limit}${query && query?.length > 0 ? `&query=${query}` : ''}`,
        filters
      ),

    unverifyHackathon: slug => requests.post(`hackathons/${slug}/unverify`),

    verifyHackathon: slug => requests.post(`hackathons/${slug}/verify`),

    unprivatizeHackathon: slug => requests.post(`hackathons/${slug}/public`),

    privatizeHackathon: slug => requests.post(`hackathons/${slug}/private`),

    togglePaidHackathon: (slug, paid) => requests.post(`hackathons/${slug}/paid`, { paid }),

    updateHackathonFeaturedImage: (slug, image) =>
      requests.post(`hackathons/${slug}/featured_cover_img`, { featured_cover_img: image }),

    setV2FeaturedImage: (hackathonUUID, image) =>
      requests.post(`hackathons/${hackathonUUID}/featured_cover_img_v2`, {
        featured_cover_img_v2: image,
      }),

    toggleHackathonIsHybrid: (hackathonUUID, isHybrid) =>
      requests.post(`hackathons/${hackathonUUID}/settings/set_is_hybrid`, {
        is_hybrid: isHybrid,
      }),

    deleteProject: hackathonUUID => requests.delete(`projects/${hackathonUUID}`),

    getProjectBySlug: projectSlug => requests.get(`projects/${projectSlug}`),

    sendEmails: (slug, data) =>
      requests.post(`emails/mailing_lists/${slug}`, {
        email_template: Buffer.from(data.body).toString('base64'),
        subject: data.subject,
        from_name: data.sender,
        from_email: data.from,
        opportunities: data.opportunities.length ? data.opportunities : undefined,
        countries: data.countries.length ? data.countries : undefined,
      }),

    sendCustomBulkEmails: data =>
      requests.post(`emails/send_custom_bulk_emails`, {
        from: data.from,
        to: data.to,
        sender_name: data.sender,
        subject: data.subject,
        message: Buffer.from(data.body).toString('base64'),
      }),

    getMailingLists: (page = 1) => requests.get(`emails/mailing_lists?page=${page}`),

    getUserMailingList: username => requests.get(`users/${username}/mailing_lists`),

    updateMailingList: (username, mailingListID, subscribed) =>
      requests.post(`users/${username}/mailing_lists/${mailingListID}`, {
        subscribed,
      }),

    /**
     * @description Below API will return us an S3 URl for our email template
     * @param { hackathonUUID: This can be either hackathon uuid or hackathon slug }
     * @param { templateType: This is a email template type, they could be any of the below }
     * 1. `acceptance-offline.html`
     * 2. `acceptance-online.html`
     * 3. `rejection.html`
     * 4. `team-acceptance-offline.html`
     * 5. `team-acceptance-online.html`
     * @reference https://www.notion.so/devfolio/Hackathon-custom-email-template-213c37cfceb241eeb679d385699147d8
     */
    getSignedURLForEmailTemplate: (hackathonUUID, templateType) =>
      requests.get(
        `hackathons/${hackathonUUID}/email_templates/signed_url_email_template_upload?template_type=${encodeURI(
          `${templateType}.html`
        )}`
      ),
    getHackathonCustomEmailTemplates: hackathonUUID => requests.get(`hackathons/${hackathonUUID}/email_templates`),

    updateHackathonCustomEmailTemplates: (hackathonUUID, data) =>
      requests.post(`hackathons/${hackathonUUID}/email_templates`, data),

    deleteAllCustomEmailTemplates: hackathonUUID => requests.delete(`hackathons/${hackathonUUID}/email_templates`),

    fetchExtraFields: slug => requests.get(`hackathons/${slug}/extra_fields`),
    addExtraField: (slug, extraFieldObj) => requests.post(`hackathons/${slug}/extra_fields`, extraFieldObj),
    updateExtraField: (slug, extraFieldUUID, extraFieldObj) =>
      requests.post(`hackathons/${slug}/extra_fields/${extraFieldUUID}`, extraFieldObj),
    deleteExtraField: (slug, extraFieldUUID) => requests.delete(`hackathons/${slug}/extra_fields/${extraFieldUUID}`),

    fetchChecklists: slug => requests.get(`hackathons/${slug}/checklists`),
    addChecklist: (slug, checklistObj) => requests.post(`hackathons/${slug}/checklists`, checklistObj),
    updateChecklist: (slug, ChecklistUUID, checklistObj) =>
      requests.post(`hackathons/${slug}/checklists/${ChecklistUUID}`, checklistObj),
    deleteChecklist: (slug, checklistUUID) => requests.delete(`hackathons/${slug}/checklists/${checklistUUID}`),

    fetchFaqs: slug => requests.get(`hackathons/${slug}/faq`),
    addFaq: (slug, faqObj) => requests.post(`hackathons/${slug}/faq`, faqObj),
    updateFaq: (slug, faqUUID, faqObj) => requests.post(`hackathons/${slug}/faq/${faqUUID}`, faqObj),
    deleteFaq: (slug, faqUUID) => requests.delete(`hackathons/${slug}/faq/${faqUUID}`),

    fetchIdeas: (page, params, limit = 10) => requests.get(`ideas?page=${page}&limit=${limit}${params}`),
    updateIdea: (ideaUUID, ideaObj) => requests.post(`ideas/${ideaUUID}`, ideaObj),
    deleteIdea: ideaUUID => requests.delete(`ideas/${ideaUUID}`),

    fetchCompanies: (page, limit = 10) => requests.get(`company/fetch?page=${page}&limit=${limit}`),
    getCompanyFaviconUploadURL: (companyUUID, type) =>
      requests.get(`company/${companyUUID}/companyFaviconSignedUrl?file_type=${type}`),
    createCompany: companyObj => requests.post('company/', companyObj),
    updateCompany: (companyUUID, companyObj) => requests.post(`company/${companyUUID}`, companyObj),
    updateCompanyDetails: (companyUUID, companyDetailObj) =>
      requests.post(`company/${companyUUID}/details`, companyDetailObj),
    searchCompany: query => requests.get(`company?q=${query}`),

    makeHackathonOfficialAndFeatured: (hackathonSlug, devfolio_official, featured) =>
      requests.post(`hackathons/${hackathonSlug}/officialAndFeatured`, {
        devfolio_official,
        featured,
      }),
    makeHackathonFellowship: (hackathonSlug, fellowship) =>
      requests.post(`hackathons/${hackathonSlug}/fellowship`, {
        fellowship,
      }),
    getSponsorPrizeTemplate: () => requests.get(`emails/sponsor_prize_template`),
    updateSponsorPrizeTemplate: (sponsorPrizeTemplate, subject) =>
      requests.post(`emails/add_sponsor_prize_template`, {
        base64_data: Buffer.from(sponsorPrizeTemplate).toString('base64'),
        subject,
      }),
    updateSchwagQuantities: (hackathonUUID, winnerQuantity, organizerQuantity) =>
      requests.post(`hackathons/${hackathonUUID}/settings`, {
        schwag_winners_count: winnerQuantity,
        schwag_organizers_count: organizerQuantity,
      }),

    updateHackathonMailSubscriptions: (username, hackathonUUID, subscriptionData) =>
      requests.post(`users/${username}/hackathon/${hackathonUUID}/unsubscribe`, {
        ...subscriptionData,
      }),

    flagProject: (projectUUID, flagObj) => requests.post(`projects/${projectUUID}/set_flag`, flagObj),

    banUser: username => requests.post(`users/${username}/ban`),

    unbanUser: username => requests.post(`users/${username}/un_ban`),

    toggleOfflineJudging: (hackathonUUID, enabled) =>
      requests.post(`hackathons/${hackathonUUID}/settings/set_judging_enabled`, {
        judging_enabled: enabled,
      }),

    toggleOnlineJudging: (hackathonUUID, enabled) =>
      requests.post(`hackathons/${hackathonUUID}/settings/set_online_judging_enabled`, {
        online_judging_enabled: enabled,
      }),

    toggleSponsorJudging: (hackathonUUID, enabled) =>
      requests.post(`hackathons/${hackathonUUID}/settings/set_sponsor_judging_enabled`, {
        sponsor_judging_enabled: enabled,
      }),

    toggleOnChainCredentials: (hackathonUUID, enabled) =>
      requests.post(`hackathons/${hackathonUUID}/settings/set_on_chain_credentials_enabled`, {
        on_chain_credentials_enabled: enabled,
      }),

    fetchQVGuests: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/guests`),
    addQVGuest: (hackathonSlug, username) =>
      requests.post(`hackathons/${hackathonSlug}/guest`, {
        username,
      }),
    deleteQVGuest: (hackathonSlug, username) => requests.delete(`hackathons/${hackathonSlug}/guest/${username}`),
    getCompanies: query => requests.get(`company?q=${query}`),
    updateBrand: (hackathonUUID, brandUUID, edition, editionName) =>
      requests.post(`hackathons/${hackathonUUID}/updateHackathonBrand`, {
        edition,
        editionName,
        brandUUID,
      }),
    removeBrand: (hackathonUUID, brandUUID) =>
      requests.post(`hackathons/${hackathonUUID}/removeHackathonBrand`, {
        brandUUID,
      }),
  },

  create: {
    getHackathonLogoUploadURL: (hackathonID, fileType) =>
      requests.get(`hackathons/${hackathonID}/signed_url_logo_upload?file_type=${fileType}`),

    getHackathonCoverImageUploadURL: (hackathonID, fileType) =>
      requests.get(`hackathons/${hackathonID}/signed_url_cover_upload?file_type=${fileType}`),
  },

  quiz: {
    addQuestion: questionObj => requests.post('questions', questionObj),
    addOption: (questionUuid, value) => requests.post(`questions/${questionUuid}/options`, { value }),
    addAnswer: (questionUuid, optionUuid) =>
      requests.post(`questions/${questionUuid}/answers`, {
        option_uuid: optionUuid,
      }),
    addQuestionSource: (questionUuid, sourceObj) => requests.post(`questions/${questionUuid}/sources`, sourceObj),
    addAnswerSource: (questionUuid, answerUuid, sourceObj) =>
      requests.post(`questions/${questionUuid}/answers/${answerUuid}/sources`, sourceObj),
    fetchQuestion: questionUuid => requests.get(`questions/${questionUuid}`),
    fetchQuestions: (page = 1, limit = 10) => requests.get(`questions?page=${page}&limit=${limit}`),
    deleteQuestion: questionUuid => requests.delete(`questions/${questionUuid}`),
    updateQuestion: (questionUuid, questionObj) => requests.post(`questions/${questionUuid}`, questionObj),
    removeQuestionSkill: (questionUuid, skillUuid) => requests.delete(`questions/${questionUuid}/skills/${skillUuid}`),
    updateOption: (questionUuid, optionUuid, value) =>
      requests.post(`questions/${questionUuid}/options/${optionUuid}`, {
        value,
      }),
    updateQuestionSource: (questionUuid, sourceUuid, sourceObj) =>
      requests.post(`questions/${questionUuid}/sources/${sourceUuid}`, sourceObj),
    updateAnswer: (questionUuid, answerUuid, optionUuid) =>
      requests.post(`questions/${questionUuid}/answers/${answerUuid}`, {
        option_uuid: optionUuid,
      }),
    updateAnswerSource: (questionUuid, answerUuid, sourceUuid, sourceObj) =>
      requests.post(`questions/${questionUuid}/answers/${answerUuid}/sources/${sourceUuid}`, sourceObj),
    deleteQuestionResource: (questionUuid, resourceUuid) =>
      requests.delete(`questions/${questionUuid}/resources/${resourceUuid}`),
    deleteQuestionTag: (questionUuid, tagUuid) => requests.delete(`questions/${questionUuid}/tags/${tagUuid}`),

    createQuiz: quizObj => requests.post('quiz', quizObj),
    updateQuiz: (uuid, quizObj) => requests.post(`quiz/${uuid}`, quizObj),
    fetchQuizzes: (page = 1, limit = PAGE_LIMIT) => requests.get(`quiz?page=${page}&limit=${limit}`),
    fetchQuiz: uuid => requests.get(`quiz/${uuid}`),
    updateTheoryQuestion: (uuid, questionObj) => requests.post(`quiz/${uuid}/theoryQuestions`, questionObj),
    updateCodeQuestion: (uuid, questionObj) => requests.post(`quiz/${uuid}/codeQuestions`, questionObj),
    fetchSkillQuestions: (quizUuid, skillUuid) => requests.get(`quiz/${quizUuid}/questionCount/${skillUuid}`),

    fetchResources: (page = 1, limit = PAGE_LIMIT) => requests.get(`resources?page=${page}&limit=${limit}`),
    createResource: resourceObj => requests.post('resources', resourceObj),
    updateResource: (uuid, resourceObj) => requests.post(`resources/${uuid}`, resourceObj),
    searchResources: query => requests.get(`resources/search?q=${query}`),
  },

  ticket: {
    fetchTickets: slug => requests.get(`hackathons/${slug}/tickets`),
    addTicket: (slug, ticketObj) => requests.post(`hackathons/${slug}/tickets`, ticketObj),
    updateTicket: (slug, ticketUUID, ticketObj) => requests.post(`hackathons/${slug}/tickets/${ticketUUID}`, ticketObj),
    deleteTicket: (slug, ticketUUID) => requests.delete(`hackathons/${slug}/tickets/${ticketUUID}`),

    fetchTicketStats: slug => requests.get(`hackathons/${slug}/conferenceTicketStats`),

    refundTickets: slug => requests.post(`hackathons/${slug}/refundTickets`),

    updateHackathonTicketInfo: (hackathonSlug, hackathonTicketData) =>
      requests.post(`hackathons/${hackathonSlug}/settings`, {
        ticket_font_color: hackathonTicketData?.ticketFontColor,
        ticket_img: hackathonTicketData?.ticketImg,
        ticket_limit: hackathonTicketData?.ticketLimit,
        ticket_limit_f: hackathonTicketData?.ticketLimitFemale,
        ticket_limit_m: hackathonTicketData?.ticketLimitMale,
        ticket_msg1: hackathonTicketData?.ticketMsg1,
        ticket_msg2: hackathonTicketData?.ticketMsg2,
        ticket_refundable: hackathonTicketData?.ticketRefundable,
        razorpay_title: hackathonTicketData?.razorpayTitle,
        razorpay_desc: hackathonTicketData?.razorpayDesc,
      }),

    getTicketImageUploadURL: (hackathonSlug, fileType) =>
      requests.get(`hackathons/${hackathonSlug}/signed_url_ticket_img_upload?file_type=${fileType}`),
  },

  judging: {
    createBulkRooms: (hackathonSlug, roomCount) =>
      requests.post(`hackathons/${hackathonSlug}/judgingRooms/bulkCreate`, { number_of_rooms: roomCount }),
    createSingleRoom: hackathonSlug =>
      requests.post(`hackathons/${hackathonSlug}/judgingRooms`, {
        available: true,
      }),
    sendMagicLinkInvitesToAllJudges: hackathonSlug =>
      requests.post(`hackathons/${hackathonSlug}/sendJudgingInviteMagicLinks`),
    sendMagicLinkInviteToParticularJudge: (hackathonSlug, judgeUUID) =>
      requests.post(`hackathons/${hackathonSlug}/judges/${judgeUUID}/resendInvite`),
    autoAllotJudges: hackathonSlug => requests.post(`hackathons/${hackathonSlug}/allotJudges`),
    removeJudgeFromRoom: ({ hackathonSlug, roomUUID, judgeUUID }) =>
      requests.delete(`hackathons/${hackathonSlug}/judgingRooms/${roomUUID}/judges/${judgeUUID}`),
    addJudgeToRoom: ({ hackathonSlug, roomUUID, judgeUUID }) =>
      requests.post(`hackathons/${hackathonSlug}/judgingRooms/${roomUUID}/judges/${judgeUUID}`),
    updateRoomAvailability: ({ hackathonSlug, roomUUID, isAvailable }) =>
      requests.post(`hackathons/${hackathonSlug}/judgingRooms/${roomUUID}`, {
        available: isAvailable,
      }),
    updateDidJudgingOnboarding: (username, hackathonSlug, did_judging_onboarding) =>
      requests.post(`user/${username}/hackathon/${hackathonSlug}`, {
        did_judging_onboarding,
      }),
    updateJudgingRoom: (hackathonSlug, roomUUID, roomDetails) =>
      requests.post(`hackathons/${hackathonSlug}/judgingRooms/${roomUUID}`, {
        name: roomDetails?.name,
        available: roomDetails?.available,
        order: roomDetails?.order,
      }),
    deleteJudgingRoom: (hackathonSlug, roomUUID) =>
      requests.delete(`hackathons/${hackathonSlug}/judgingRooms/${roomUUID}`),
    sendEmailInvitesToJudges: (hackathonSlug, type) =>
      requests.post(`hackathons/${hackathonSlug}/sendJudgingInvites`, {
        type,
      }),
    fetchJudgingStats: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/judgingStats`),

    fetchRoomJudgements: (hackathonSlug, page = 1, limit = 10) =>
      requests.get(`hackathons/${hackathonSlug}/getRoomsWithJudgmentCount?page=${page}&limit=${limit}`),
    fetchRoomProjectsJudgments: (hackathonSlug, roomUUID, page = 1, limit = 10) =>
      requests.get(`hackathons/${hackathonSlug}/judgingRooms/${roomUUID}/projectJudgments?page=${page}&limit=${limit}`),
    fetchJudgmentJudges: (hackathonSlug, roomUUID, page = 1, limit = 50) =>
      requests.get(`hackathons/${hackathonSlug}/judgingRooms/${roomUUID}/judges?page=${page}&limit=${limit}`),
    fetchProjectJudgments: (hackathonSlug, projectUUIDs, filters, page = 1, limit = 10) =>
      requests.post(`hackathons/${hackathonSlug}/projectsJudgments`, {
        projectUUIDs,
        page,
        limit,
        ...(typeof filters === 'object' ? filters : {}),
      }),
    addProjectOrganizerNotes: (hackathonSlug, projectUUID, notes) =>
      requests.post(`hackathons/${hackathonSlug}/projects/${projectUUID}/notes`, { notes }),
    updateProjectPrizingEligibility: (hackathonSlug, projectUUID, isEligible) =>
      requests.post(`hackathons/${hackathonSlug}/projects/${projectUUID}/updatePrizingEligibility`, {
        eligible_for_prizing: isEligible,
      }),
    exportJudgingCSV: hackathonUUID => requests.get(`hackathons/${hackathonUUID}/roomsProjectsJudgments/csv_export`),
    addJudgesByCSV: (hackathonSlug, file, config) =>
      requests.post(`hackathons/${hackathonSlug}/judges/importFromCSV`, file, config),
  },
  onlineJudging: {
    autoAllotJudges: hackathonUUID => requests.post(`hackathons/${hackathonUUID}/autoAllotJudgesToProjects`),
    projectJudgeAllotment: (hackathonUUID, filters, limit = 10, page = 1) =>
      requests.post(`hackathons/${hackathonUUID}/projectsJudges`, {
        limit,
        page,
        ...filters,
      }),
    judgedProjectStats: hackathonUUID => requests.get(`hackathons/${hackathonUUID}/judgesTotalJudgedProjects`),
    addJudgeToProject: (hackathonUUID, projectUUID, judgeUUID) =>
      requests.post(`hackathons/${hackathonUUID}/projects/${projectUUID}/judges/${judgeUUID}`),
    removeJudgeFromProject: (hackathonUUID, projectUUID, judgeUUID) =>
      requests.delete(`hackathons/${hackathonUUID}/projects/${projectUUID}/judges/${judgeUUID}`),
    exportJudgementCSV: hackathonUUID => requests.get(`hackathons/${hackathonUUID}/projectsJudgments/csv_export`),
  },
  sponsorJudging: {
    addSponsorJudge: (hackathonSlug, sponsorTierUUID, sponsorUUID, judgeUUID) =>
      requests.post(
        `hackathons/${hackathonSlug}/sponsorTiers/${sponsorTierUUID}/sponsors/${sponsorUUID}/judges/${judgeUUID}`
      ),
    removeSponsorJudge: (hackathonSlug, sponsorTierUUID, sponsorUUID, judgeUUID) =>
      requests.delete(
        `hackathons/${hackathonSlug}/sponsorTiers/${sponsorTierUUID}/sponsors/${sponsorUUID}/judges/${judgeUUID}`
      ),
    stats: hackathonSlug => requests.get(`hackathons/${hackathonSlug}/sponsorJudgingStats`),
  },
  schwag: {
    setEligibleSchwagEntities: (hackathonSlug, eligibleEntities) =>
      requests.post(`hackathons/${hackathonSlug}/settings/set_eligible_entities`, {
        eligible_entities: eligibleEntities,
      }),
    setCustomSchwagEntityLimit: (hackathonUUID, eligibleEntities) =>
      requests.post(`hackathons/${hackathonUUID}/settings/set_max_custom_entities`, {
        max_schwag_custom_entities: eligibleEntities,
      }),
    processSchwag: (hackathonUUID, entity) => requests.post(`hackathons/${hackathonUUID}/processSchwags/${entity}`),
    setSchwagProcessState: (hackathonSlug, processState) =>
      requests.post(`hackathons/${hackathonSlug}/settings/set_schwags_process_state`, {
        schwags_process_state: processState,
      }),
    processCustomSchwag: (hackathonUUID, usernames) =>
      requests.post(`hackathons/${hackathonUUID}/processSchwags/custom`, {
        custom_entities: usernames,
      }),
    fetchAllRecipients: hackathonUUID => requests.get(`hackathons/${hackathonUUID}/getHackathonSchwagsEligibleUsers`),
    fetchMaxOrganizerCount: hackathonUUID => requests.get(`hackathons/${hackathonUUID}/max_organizers`),
  },
  search: {
    builders: (body, from = 0, limit = 10) =>
      requests.post('search/builders', {
        ...body,
        from,
        size: limit,
      }),
  },
  bounties: {
    processBounties: (hackathonUUID, type) =>
      requests.post(`hackathons/${hackathonUUID}/process_bounties`, {
        type,
      }),

    setBountyStatus: (hackathonUUID, status) =>
      requests.post(`hackathons/${hackathonUUID}/settings/set_hackathon_bounties_status`, { bounties_status: status }),
  },
  credentials: {
    insertCredential: (hackathonUUID, credential) =>
      requests.post(`hackathons/${hackathonUUID}/credentials`, {
        ...credential,
      }),
    updateCredential: (hackathonUUID, credentialUUID, credential) =>
      requests.post(`hackathons/${hackathonUUID}/credentials/${credentialUUID}`, {
        ...credential,
      }),
    deleteCredential: (hackathonUUID, credentialUUID) =>
      requests.delete(`hackathons/${hackathonUUID}/credentials/${credentialUUID}`),
    preview: (primaryColor, entityName, hackathonSlug) =>
      requests.get(
        `hackathons/credentialTemplates/preview?${new URLSearchParams({
          ...(typeof hackathonSlug === 'string'
            ? {
                hackathon_slug: hackathonSlug,
              }
            : {}),
          primary_color: primaryColor,
          entity_name: entityName,
        })}`
      ),
    setContractAddress: hackathonUUID => requests.post(`hackathons/${hackathonUUID}/set_nft_contract_address`),
    fetch: (hackathonUUID, filters, limit = 10, page = 1) =>
      requests.post(`hackathons/${hackathonUUID}/credentials/users`, {
        limit,
        page,
        ...filters,
      }),
    insertCustomUser: (hackathonUUID, credentialUUID, username) =>
      requests.post(`hackathons/${hackathonUUID}/credentials/${credentialUUID}/process/custom`, {
        usernames: [username],
      }),
    process: (hackathonUUID, credentialUUID) =>
      requests.post(`hackathons/${hackathonUUID}/credentials/${credentialUUID}/process`),
    stats: hackathonUUID => requests.get(`hackathons/${hackathonUUID}/credentials/stats`),
    markEligible: (hackathonUUID, username) =>
      requests.post(`hackathons/${hackathonUUID}/credentials/users/${username}/mark_eligible`),
    markInEligible: (hackathonUUID, username) =>
      requests.post(`hackathons/${hackathonUUID}/credentials/users/${username}/mark_ineligible`),
  },
  miscellaneous: {
    generateMouPreview: (hackathonUUID, body) =>
      requests.post(`miscellaneous/hackathons/${hackathonUUID}/mouPreview`, body),
    getAllCountries: (query: string) => requests.get(`miscellaneous/get_all_countries?query=${query}`),
  },
  jobs: {
    addJobListing: body => requests.post(`jobs`, body),
    editJobListing: (jobUUID, body) => requests.post(`jobs/${jobUUID}`, body),
  },
  kyc: {
    setUserKYCStatus: (username, status) =>
      requests.post(`users/${username}/setKYCStatus`, {
        kyc_status: status,
      }),
    setUserMaxAllowedKYCAttempts: ({ username, maxAttempts }) =>
      requests.post(`users/${username}/setMaxKYCAttempts`, {
        max_kyc_attempts: maxAttempts,
      }),
  },
};
