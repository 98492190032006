import axios from 'axios';
import { API_ROOT } from 'constants/environment';
import { externalAxios as copyAxios, errorHandler } from './axios';
import { getHostName } from '../helpers';
import { URL } from '../constants';

const getSearchString = input => getHostName(input);

const searchAxios = axios.create();

/**
 * @description This interceptor is used for handling
 * the response from the elastic search APIs
 */
searchAxios.interceptors.response.use(response => {
  let data = [];
  let nbPages = 0;
  let nbHits = 0;
  // Extract hits from the search API response if available
  if (response?.data?.hits) {
    const totalHits = response?.data?.hits;
    data = totalHits?.hits;
    nbHits = totalHits?.total?.value || 0;
    nbPages = Math.ceil(nbHits / 20) || 0;
    // If hits is not available than use the suggest
    // array if available in the API response
  } else {
    data = response?.data?.suggest?.result?.[0]?.options;
  }

  // Initialize the resulting response to the default values
  const result = {
    hits: [],
    nbPages,
    nbHits,
  };

  // If data is a valid array than flatten the output
  // by removing _source returned from the API for every object
  if (Array.isArray(data)) {
    result.hits = data.map(({ _source: source }) => source);
  }
  return result;
}, errorHandler);

const searchRequest = (endpoint, data) => searchAxios.post(`${API_ROOT}search/${endpoint}`, data);

const EXTERNAL_API = {
  searchCompanies: input => {
    const searchString = getSearchString(input);
    return copyAxios.get(`${URL.CLEARBIT_AUTOCOMPLETE}${searchString}`);
  },
  // Upload the file
  uploadFile: (signedURL, data, config) => copyAxios.put(signedURL, data, config),
};

const SEARCH_API = {
  searchColleges: query => searchRequest('colleges', { q: query }),
  searchHashtags: query => searchRequest('hashtags', { q: query }),
  searchParticipants: (token, query, searchTeams = false) =>
    searchRequest('participants', {
      q: query,
      token,
      size: 5,
      ...(searchTeams && {
        filter: 'team',
      }),
    }),
  searchProjects: ({ from = 0, size = 10, query = undefined, hackathonSlug = undefined, showWinners = false }) =>
    searchRequest('projects', {
      from,
      size,
      q: query,
      ...(typeof hackathonSlug === 'string' && {
        hackathon_slugs: [hackathonSlug],
      }),
      ...(showWinners && {
        filter: 'winners',
      }),
    }),
};

export { EXTERNAL_API, SEARCH_API };
