import { ACTION_STATUS as STATUS } from '../constants';
import * as types from '../constants/actions';

export const initialState = {
  checklist: [],
  allHackers: [],
  hacker: {
    allergies: [],
    checklist: [],
    conferenceCheckin: false,
    conferenceTicket: false,
    displayName: null,
    displayShortName: null,
    dob: null,
    email: null,
    firstName: null,
    hasSubmittedConsent: false,
    lastName: null,
    note: null,
    profileImageUrl: null,
    status: null,
    team: null,
    username: null,
  },
  status: {
    fetchChecklist: STATUS.READY,
    fetchHackerInfo: STATUS.READY,
    searchParticipant: STATUS.READY,
    updateChecklist: STATUS.READY,
    updateHackerConferenceCheckin: STATUS.READY,
    updateHackerSubmittedConsent: STATUS.READY,
    updateNote: STATUS.READY,
  },
};

const checkin = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_NOTE:
      return {
        ...state,
        status: {
          ...state.status,
          updateNote: action.payload.status,
        },
      };

    case types.UPDATE_CHECKLIST:
      return {
        ...state,
        status: {
          ...state.status,
          updateChecklist: action.payload.status,
        },
      };

    case types.FETCH_HACKER_INFO: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hacker: {
            ...initialState.hacker,
            ...payload.hacker,
          },
          status: {
            ...state.status,
            fetchHackerInfo: action.payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchHackerInfo: action.payload.status,
        },
      };
    }

    case types.FETCH_CHECKLIST: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          checklist: [...initialState.checklist, ...payload.checklist],
          status: {
            ...state.status,
            fetchChecklist: action.payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchChecklist: action.payload.status,
        },
      };
    }

    case types.SEARCH_PARTICIPANT: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          allHackers: [...initialState.allHackers, ...payload.allHackers],
          hacker: {
            ...initialState.hacker,
            ...payload.hacker,
          },
          status: {
            ...state.status,
            searchParticipant: action.payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          searchParticipant: action.payload.status,
        },
      };
    }

    case types.UPDATE_HACKER:
      return {
        ...state,
        hacker: {
          ...state.hacker,
          ...action.payload,
        },
      };

    case types.UPDATE_HACKER_SUBMITTED_CONSENT:
      return {
        ...state,
        status: {
          ...state.status,
          updateHackerSubmittedConsent: action.payload.status,
        },
      };

    case types.UPDATE_HACKER_CONFERENCE_CHECKIN:
      return {
        ...state,
        status: {
          ...state.status,
          updateHackerConferenceCheckin: action.payload.status,
        },
      };

    case types.LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
};

export default checkin;
