import { ACTION_STATUS as STATUS } from '../constants';
import * as types from '../constants/actions';

const initialState = {
  overallScore: 0,
  questions: [],
  reviews: {
    currentPage: 1,
    byPage: [],
    total: 0,
    totalPages: 0,
  },
  stats: {
    expected: 0,
    received: 0,
  },
  status: {
    fetchFeedback: STATUS.READY,
    fetchReviews: STATUS.READY,
    fetchStats: STATUS.READY,
    sendReminderEmails: STATUS.READY,
  },
};

const feedback = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_FEEDBACK: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          overallScore: payload.overallScore,
          questions: [...initialState.questions, ...payload.questions],
          status: {
            ...state.status,
            fetchFeedback: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchFeedback: payload.status,
        },
      };
    }

    case types.FETCH_REVIEWS: {
      const { payload } = action;
      const { currentPage, items, totalPages, total } = payload;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          reviews: {
            currentPage,
            byPage: {
              ...state.byPage,
              [currentPage]: [...items],
            },
            totalPages,
            total,
          },
          status: {
            ...state.status,
            fetchReviews: payload.status,
          },
        };
      }

      // Request
      return {
        ...state,
        status: {
          ...state.status,
          fetchReviews: payload.status,
        },
      };
    }

    case types.FETCH_FEEDBACK_STATS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          stats: payload.data,
          status: {
            ...state.status,
            fetchStats: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchStats: payload.status,
        },
      };
    }

    case types.SEND_FEEDBACK_REMINDER_EMAILS:
      return {
        ...state,
        status: {
          ...state.status,
          sendReminderEmails: action.payload.status,
        },
      };

    default:
      return state;
  }
};

export default feedback;
