/* eslint-disable prefer-destructuring */

export const API_ROOT = process.env.API_ROOT ?? '';
export const BASE_URL = process.env.BASE_URL ?? '';
export const NODE_ENV = process.env.NODE_ENV ?? '';
export const APP_ENV = process.env.APP_ENV ?? '';
export const SENTRY_DSN = process.env.SENTRY_DSN ?? '';
export const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY ?? '';
export const SPACES_BASE_URL = process.env.SPACES_BASE_URL ?? '';
export const GOOGLE_PLACES_KEY = process.env.GOOGLE_PLACES_KEY ?? '';
export const EXTERNAL_EMAIL_SEND_URL = process.env.EXTERNAL_EMAIL_SEND_URL ?? '';
export const DEVFOLIO_BASE = process.env.DEVFOLIO_BASE ?? '';
export const LAUNCH_DARKLY_CLIENT_ID = process.env.LAUNCH_DARKLY_CLIENT_ID ?? '';
export const GRAPHQL_WEBSOCKET_ROOT = process.env.GRAPHQL_WEBSOCKET_ROOT ?? '';
export const GRAPHQL_ROOT = process.env.GRAPHQL_ROOT ?? '';
export const API_WEBSOCKET_ROOT = process.env.API_WEBSOCKET_ROOT ?? '';
const ENVIRONMENT = {
  API_ROOT,
  BASE_URL,
  NODE_ENV,
  APP_ENV,
  SENTRY_DSN,
  RECAPTCHA_KEY,
  SPACES_BASE_URL,
  GOOGLE_PLACES_KEY,
  EXTERNAL_EMAIL_SEND_URL,
  DEVFOLIO_BASE,
  LAUNCH_DARKLY_CLIENT_ID,
  GRAPHQL_WEBSOCKET_ROOT,
  GRAPHQL_ROOT,
  API_WEBSOCKET_ROOT,
};

export default ENVIRONMENT;
