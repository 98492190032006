import { Flex } from '@devfolioco/genesis';
import styled, { css } from 'styled-components';

export const BoardWrapper = styled(Flex)`
  flex-direction: column;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-align: start;
  scroll-margin-top: 130px;
  scroll-snap-type: x proximity;
`;

export const BoardContainer = styled('div')<{ sticky?: boolean; cols: number }>(
  ({ cols }) => `
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${cols}, minmax(400px, 1fr));
  gap: 20px;
`
);

export const BoardScrollSnapPoint = styled.div`
  scroll-snap-align: start;
  min-width: 1px;
  height: 1px;
`;

export const BoardColumn = styled(Flex)(
  ({ theme }) => css`
    flex-direction: column;
    gap: 32px;
    width: 100%;
    gap: 32px;
    padding-right: 20px;
    border-right: 1px solid ${theme.color.grey[3]};
  `
);

export const BoardColumnHeader = styled(Flex)<{ noBackground?: boolean }>(
  ({ theme, noBackground }) => css`
    width: 100%;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    ${!noBackground ? `background-color: ${theme.color.grey[6]};` : ''}
    color: white;
    border-radius: 8px;
  `
);

export const BoardColumnAddButton = styled(Flex)(
  ({ theme }) => css`
    width: 100%;
    min-height: 64px;
    background-color: ${theme.color.grey[3]};
    color: white;
    border-radius: 8px;
    transition: 0.2s all linear;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: ${theme.color.grey[4]};
      color: ${theme.color.blue[4]};
    }
  `
);

export const BoardColumnBody = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 32px;
  height: 100%;
`;

export const BoardHeaderEditableField = styled('input')(
  ({ theme }) => css`
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 2px solid transparent;
    color: white;
    text-align: center;
    font-family: ${theme.typography.fontStyle({
      font: 'secondary',
    })};
    transition: 0.2s all linear;

    &:focus {
      border-bottom: 2px solid white;
    }
  `
);

export const AddEventCard = styled(Flex)(
  ({ theme }) => css`
    height: 198px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: ${theme.color.grey[3]};
    transition: 0.2s all linear;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
      scale: 1.01;
      box-shadow: ${theme.shadow[3]};
    }
  `
);
