import * as d3 from 'd3';
import invert from 'lodash/invert';
import mapKeys from 'lodash/mapKeys';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import moment from 'moment-timezone';
import orderBy from 'lodash/orderBy';
import qs from 'query-string';
import { GroupedEventI } from '@components/ScheduleManager/ScheduleManager';
import { BASE_URL, DEVFOLIO_BASE, NODE_ENV } from 'constants/environment';

import { isValidEmail, isValidURL, getDevfolioUserCookie } from '@devfolioco/helpers';
import { EventGroupI } from '../views/HackathonSetup/components/Events';
import { API } from '../api';
import {
  CLIENT_PARAM,
  GENDER,
  APPLY_STATUS,
  UNITS,
  HOURS,
  DAYS,
  HACKATHON_PROPERTIES,
  HACKATHON_SETTINGS_PROPERTIES,
  AMZ_ACL,
  AMZ_HEADER,
  TAB_TO_ROUTE,
} from '../constants';
import { history } from './history';
import { logOut } from '../actions/authentication';
import store from '../store';
import { logger } from './logger';

export const mapToClient = object => mapKeys(object, (value, key) => CLIENT_PARAM[key]);

/**
 * Returns the error object when found in the API response,
 * else returns the raw response data.
 * @param {*} error The response object received from the API.
 */
export const getError = error => {
  const responseData = error?.response?.data;
  return responseData?.error || responseData;
};

/**
 * From a user object which has firstName, lastName and username
 * return a display name. The first name and last name might be null,
 * in that case return the username else return the item that is not
 * null. If both are present, return a concatenated string. If nothing,
 * return am empty string
 *
 * @param {string} user.first_name
 * @param {string} user.last_name
 * @param {string} user.username
 * @param {string} user.status
 * @returns {string} the display name
 */
export const getDisplayName = user => {
  if (isUserDeleted(user)) {
    return 'Deleted User';
  }

  if (typeof user.username !== 'string') {
    return ` `;
  }

  return typeof user.first_name === 'string' || typeof user.last_name === 'string'
    ? `${user?.first_name || ``} ${user?.last_name || ``}`.trim()
    : `${user.username}`;
};

export const getShortDisplayName = user => {
  if (isUserDeleted(user)) {
    return 'Deleted User';
  }

  if (typeof user.username !== 'string') {
    return ` `;
  }

  return user.first_name || user.last_name || user.username;
};

export const getDisplayMembersName = teamMembers => {
  return (
    (Array.isArray(teamMembers) &&
      teamMembers.length &&
      teamMembers.map(member => getShortDisplayName(member.user)).join(', ')) ||
    ''
  );
};

/**
 * Checks if the user has deleted account using the user
 * object
 *
 * @param {string} user.status Indicates whether the user has deleted account or not
 * @returns {boolean} Indicating whether the user has deleted account
 */
export const isUserDeleted = user => user?.status === 'delete';

/**
 * Encodes an object to a base64 string
 *
 * @param {object} obj the object to be encoded
 * @returns {string} base64 string
 */
export const encodeObjectToBase64 = obj => Buffer.from(JSON.stringify(obj)).toString('base64');

/**
 * Returns a humanized time stamp from a given time
 *
 * @param {*} time time or date object or string
 * @returns {string} humanized time
 */
export const timeAgo = time => moment(time).fromNow();

/**
 * Generates a new string query from the given query and a new query
 * name and value. The old query can be an object or a stringified
 * object. Can be used in manipulating the URL based on filter changes.
 * Incase the query is invalid, return undefined.
 *
 * @param {string} queryObjectName new query name to be updated/inserted
 * @param {string} queryObjectValue new query value to be updated/inserted
 * @param {string|object} oldQuery old query object or string
 * @returns {string} new string query
 */
export const generateQueryString = (queryObjectName, queryObjectValue, oldQuery) => {
  try {
    let newQueryObject = {};

    if (typeof oldQuery === 'string') {
      newQueryObject = qs.parse(oldQuery);
    } else if (typeof oldQuery === 'object') {
      newQueryObject = oldQuery;
    } else {
      return undefined;
    }

    newQueryObject[queryObjectName] = queryObjectValue;
    return qs.stringify(newQueryObject);
  } catch (error) {
    return undefined;
  }
};

/**
 * Build an object to be sent in a filter API request body. Please
 * note that the object must be spread before sending inside the
 * body. Incase the query is invalid, return undefined.
 *
 * @param {string|object} URLQuery the current filter URL
 * @returns {object} filter body object
 */
export const generateAPIQueryObject = (URLQuery, view = 'all') => {
  let queryObj = {};

  try {
    if (typeof URLQuery === 'string') {
      queryObj = qs.parse(URLQuery);
    } else if (typeof URLQuery === 'object') {
      queryObj = URLQuery;
    } else {
      return undefined;
    }

    // Only load users whose status is active
    queryObj.user_status = 'active';

    // Setting the default page value as 1, since it's required
    if (queryObj.page === undefined) {
      queryObj.page = 1;
    }

    const query = {};

    const user = {};
    const college = {};
    const company = {};
    const address = {};
    const team = {};

    Object.entries(queryObj).forEach(([key, value]) => {
      switch (key) {
        case 'email':
        case 'first_name':
        case 'last_name':
        case 'username':
          user[key] = { ilike: `%${value}%` };
          query.user = encodeObjectToBase64(user);
          break;
        case 'user_status':
          user.status = 'active';
          query.user = encodeObjectToBase64(user);
          break;
        case 'college':
          college.name = { in: value.split(',') };
          query.college = encodeObjectToBase64(college);
          break;
        case 'company_name':
          company.name = { ilike: `%${value}%` };
          query.company = encodeObjectToBase64(company);
          break;
        case 'team_name':
          if (view === 'team') {
            query.name = value;
          } else {
            team.name = { $eq: `${value}` };
            query.team = encodeObjectToBase64(team);
          }
          break;
        case 'payment_mode':
          query.payment_mode = value;
          break;
        case 'city':
        case 'state':
          address[key] = { in: value.split(',') };
          query.address = encodeObjectToBase64(address);
          break;
        case 'country':
        case 'notCountry':
          address.country = {
            ...(typeof queryObj.country === 'string' && { in: queryObj.country.split(',') }),
            ...(typeof queryObj.notCountry === 'string' && { notIn: queryObj.notCountry.split(',') }),
          };
          query.address = encodeObjectToBase64(address);
          break;
        case 'gender': {
          let valueArray = getFilterValuesFromURL(Array.isArray(value) ? value : value.split(','));

          if (valueArray.includes('all')) {
            valueArray = Object.keys(GENDER);
          }
          user[key] = { in: valueArray };
          query.user = encodeObjectToBase64(user);
          break;
        }
        case 'status': {
          const valueArray = getFilterValuesFromURL(Array.isArray(value) ? value : value.split(','));
          let statusValue = [];
          let queryObject = {};

          if (view === 'all' && valueArray.includes(APPLY_STATUS.SUBMIT)) {
            const indexOfNull = valueArray.indexOf('null');
            if (indexOfNull !== -1) {
              valueArray[indexOfNull] = 'not_submit';
            }

            query.status = valueArray.join(',');
          } else {
            statusValue = valueArray.filter(val => val !== 'accept_all' && val !== 'reject_all');

            let nonNullStatusesArray = [];
            queryObject = { in: statusValue };

            if (statusValue.includes('null')) {
              nonNullStatusesArray = statusValue.filter(val => val !== 'null');

              queryObject = {
                $or: [{ $eq: null }, { in: nonNullStatusesArray }],
              };
            }

            if (statusValue.length > 0) query.status = encodeObjectToBase64(queryObject);
          }
          break;
        }
        case 'ai_assisted_status': {
          const valueArray = getFilterValuesFromURL(Array.isArray(value) ? value : value.split(','));
          query[key] = encodeObjectToBase64({ in: valueArray });
          break;
        }
        case 'role': {
          const valueArray = getFilterValuesFromURL(Array.isArray(value) ? value : value.split(','));

          if (valueArray.length > 0) query[key] = encodeObjectToBase64({ in: valueArray });
          break;
        }
        case 'page':
        case 'age':
          query[key] = value;
          break;
        default:
          break;
      }
    });

    return query;
  } catch (error) {
    return undefined;
  }
};

// Map the keys in the URL to internal state values
const MAP = {
  accept_sent: 'accept_sent',
  accept: 'accept',
  check_in: 'check_in',
  in_progress: 'null',
  reimburse: 'reimburse',
  reject_sent: 'reject_sent',
  reject: 'reject',
  rsvp: 'rsvp',
  submit: 'submit',
  waitlist_sent: 'waitlist_sent',
  waitlist: 'waitlist',
  withdraw: 'withdraw',
};

const INVERSE_MAP = invert(MAP);

export const getFilterValuesFromURL = valueArray => {
  return (
    valueArray?.map(value => {
      return MAP?.[value] || value;
    }) || []
  );
};

export const getFilterStateFromURL = url => {
  const queryObj = qs.parse(url);

  Object.entries(queryObj).forEach(([key, value]) => {
    switch (key) {
      case 'city':
      case 'college':
      case 'country':
      case 'notCountry':
      case 'gender':
        queryObj[key] = value.split(',');
        break;

      case 'status': {
        queryObj[key] = getFilterValuesFromURL(value.split(','));
        break;
      }

      case 'ai_assisted_status': {
        queryObj[key] = getFilterValuesFromURL(value.split(','));
        break;
      }

      default:
        break;
    }
  });

  return queryObj;
};

export const getURLFromFilterValues = valueArray => {
  return (
    valueArray?.map(value => {
      return INVERSE_MAP?.[value] || value;
    }) || []
  );
};

/**
 *
 * Obtains the DO temporary resume link, and opens the PDF
 * in a new tab
 * @param {*} username
 * @param {*} resumeURL
 */
export const openResumeInNewTab = async (username, hackathonSlug, resumeURL) => {
  const resumeWindow = window.open('', '_blank');

  try {
    const response = await API.organizer.getParticipantResumeViewURL(username, hackathonSlug, resumeURL);
    resumeWindow.location = response.data.signedUrl;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Could not open resume ☹ - ', error);
  }
};

/**
 *
 * Obtains the DO temporary resume link, and opens the PDF
 * in a new tab
 * @param {*} username
 * @param {*} resumeURL
 */
export const rootOpenResumeInNewTab = async (username, resumeURL) => {
  const resumeWindow = window.open('', '_blank');

  try {
    const response = await API.user.getResumeViewURL(username, resumeURL);
    resumeWindow.location = response.data.signedUrl;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Could not open resume ☹ - ', error);
  }
};

/**
 *
 * Obtains the ticket link, and opens the PDF
 * in a new tab
 * @param {*} username
 * @param {*} ticketURL
 */
export const openTicketInNewTab = async (username, hackathonSlug, ticketURL) => {
  const ticket = window.open('', '_blank');

  try {
    const response = await API.organizer.getParticipantTicketViewURL(username, hackathonSlug, ticketURL);
    ticket.location = response.data.signedUrl;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Could not open ticket ☹ - ', error);
  }
};

export const getParticipantObject = object => {
  const { user_extra: userExtra, user_hackathons: userHackathons, user_hackathon_extras: extras, ...rest } = object;

  const { status, uuid, ...reimbursement } = userHackathons[0];

  return {
    ...rest,
    ...userExtra,
    extras,
    reimbursement,
    status,
    user_hackathon_id: uuid,
  };
};

export const getState = state => store.getState()[state];

export const updateSearchItem = (name, value) => {
  const { search, pathname } = history.location;
  const searchObj = qs.parse(search);
  searchObj[name] = value;

  history.push(`${pathname}?${qs.stringify(searchObj)}`);
};

/**
 * Checks the devfolio auth cookie and returns
 * whether the user has root user access or not
 * @returns {boolean} Whether the user is root or not
 */
export const isRoot = () => {
  const devfolioAuth = getDevfolioUserCookie();
  if (Array.isArray(devfolioAuth?.roles)) {
    const isRootUser = devfolioAuth.roles.some(({ name }) => name === 'root' || name === 'operations');
    return isRootUser;
  }
  return false;
};

export const getSlug = props => {
  const { hackathon_id: hackathonId } = qs.parse(props.location.search);
  return hackathonId;
};

export const generateFilterObject = (filterObject = {}) => {
  Object.keys(filterObject).forEach(filterName => {
    const value = filterObject[filterName];

    switch (filterName) {
      case 'isa': {
        if (value.length > 0) {
          if (value.length === 1) {
            filterObject.student = value.includes('Student') ? 1 : '0';
          } else {
            filterObject.student = '1,0';
          }
        }

        delete filterObject.isa;
        break;
      }

      case 'country': {
        filterObject.address = value;
        delete filterObject.country;
        break;
      }

      default:
        filterObject[filterName] = value;
        break;
    }
  });

  return filterObject;
};

export const numFormat = number => {
  return d3.format('.2~s')(number);
};

export const approximate = (number, percent = false) => {
  const approx = parseInt(number, 10) > 999 ? numFormat(number) : number;
  return percent ? `${approx}%` : approx;
};

export const routeToDefaultView = (hackathonSlug, tabs = []) => {
  const route = TAB_TO_ROUTE[tabs[0]];
  if (tabs.length) {
    history.replace(`/${hackathonSlug}/${route}`);
  } else {
    // eslint-disable-next-line no-console
    console.error('You do not have permission to view the organizer dashboard.');
    store.dispatch(logOut(true));
  }
};

export const canView = (tabs, tabName) => {
  return tabs?.includes(tabName);
};

export const formatMoney = (amount, currency = 'INR') =>
  parseFloat(amount, 10).toLocaleString('en', {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

export const formatLocation = ({ city = '', state = '', country = '' }) => {
  if (typeof city === 'string' && city.length) return city + (country.length ? `, ${country}` : ``);
  if (typeof state === 'string' && state.length) return state + (country.length ? `, ${country}` : ``);
  if (typeof country === 'string' && country.length) return country;
  return ``;
};

/**
 * See {@link https://momentjs.com/docs/#/parsing/string-format/} for format options.
 */

export const formatDate = (date, withTime = false) => {
  return moment(date)
    .tz(moment.tz.guess())
    .format(`Do MMMM, YYYY ${withTime ? '| HH:mm' : ''} z`);
};

export const convertToUTC = (date, timezone) => {
  const curTz = moment.tz(date.format('YYYY-MM-DD HH:mm'), timezone);
  return curTz.utc();
};

export const convertArrayToSelectObject = (arr, captalizeFirstCharacter = false) => {
  return arr.reduce(
    (a, el) => [
      ...a,
      { label: captalizeFirstCharacter ? el.charAt(0).toUpperCase() + el.slice(1) : String(el), value: el },
    ],
    []
  );
};

export const formatValue = (value, comparator) => {
  const isComparatorLike = comparator === 'like' || comparator === 'ilike';

  if (isValidEmail(value) && isComparatorLike) {
    const emailArr = value.split('@');
    const firstPart = emailArr[0];
    const secondPart = emailArr[1] !== undefined ? emailArr[1] : '';
    const parsedString = `%${firstPart}%@%${secondPart}%`;
    return { [comparator]: parsedString };
  }

  return { [comparator]: isComparatorLike ? `%${value}%` : value };
};

export const getAvailableTimeBefore = endTime => {
  const updatedDate = moment().add(1, 'd');
  const endTimeObj = moment(endTime);

  if (!endTimeObj.isAfter(updatedDate)) {
    let i = HOURS.length - 1;

    while (i >= 0) {
      if (endTimeObj.isAfter(moment().add(HOURS[i], 'h'))) break;
      i -= 1;
    }

    return {
      days: DAYS,
      units: UNITS.slice(0, 1),
      hours: HOURS.slice(0, i + 1),
    };
  }
  let i = DAYS.length - 1;

  while (i >= 0) {
    if (endTimeObj.isAfter(moment().add(DAYS[i], 'd'))) break;
    i -= 1;
  }

  return {
    hours: HOURS,
    units: UNITS,
    days: DAYS.slice(0, i + 1),
  };
};
/**
 * TODO: Refacotor and extract the query building logic
 * Build an object to be sent in a filter API request body. Incase
 * the query is invalid, return undefined.
 *
 * @param {string|object} URLQuery the current filter URL
 * @returns {object} filter body object
 */
export const generateAPIQueryFromTags = (tags, view = 'all') => {
  const queryObj = {};

  try {
    // Setting the default page value as 1, since it's required
    queryObj.page = 1;

    const user = {};
    const college = {};
    const company = {};
    const query = {};
    const address = {};
    const team = {};

    tags.forEach(tag => {
      const { apiKey, comparator, value } = tag;
      switch (tag.apiKey) {
        case 'email':
        case 'first_name':
        case 'last_name':
        case 'username':
          user[apiKey] = formatValue(value, comparator);
          query.user = encodeObjectToBase64(user);
          break;

        case 'college':
          college.name = { [comparator]: value.split(',') };
          query.college = encodeObjectToBase64(college);
          break;

        case 'company_name':
          company.name = formatValue(value, comparator);
          query.company = encodeObjectToBase64(company);
          break;

        case 'team_name':
          if (view === 'team') {
            query.name = encodeObjectToBase64(formatValue(value, comparator));
          } else {
            team.name = formatValue(value, comparator);
            query.team = encodeObjectToBase64(team);
          }
          break;

        case 'payment_mode':
          query.payment_mode = value;
          break;

        case 'city':
        case 'state':
        case 'country':
          address[apiKey] = { [comparator]: value.split(',') };
          query.address = encodeObjectToBase64(address);
          break;

        case 'gender': {
          let valueArray = getFilterValuesFromURL(Array.isArray(value) ? value : value.split(','));

          if (valueArray.includes('all')) {
            valueArray = Object.keys(GENDER);
          }

          query.user = encodeObjectToBase64({ gender: { [comparator]: valueArray } });
          break;
        }

        case 'status': {
          const valueArray = getFilterValuesFromURL(Array.isArray(value) ? value : value.split(','));
          let statusValue = [];
          let queryObject = {};

          if (view === 'all' && valueArray.includes(APPLY_STATUS.SUBMIT)) {
            const indexOfNull = valueArray.indexOf('null');
            if (indexOfNull !== -1) {
              valueArray[indexOfNull] = 'not_submit';
            }

            query.status = valueArray.join(',');
            statusValue = valueArray.filter(val => val !== 'accept_all' && val !== 'reject_all');

            let nonNullStatusesArray = [];
            queryObject = { [comparator]: statusValue };

            if (statusValue.includes('null')) {
              nonNullStatusesArray = statusValue.filter(val => val !== 'null');

              queryObject = {
                $or: [{ $eq: null }, { [comparator]: nonNullStatusesArray }],
              };
            }

            if (statusValue.length > 0) query[apiKey] = encodeObjectToBase64(queryObject);
          }

          break;
        }

        case 'ai_assisted_status': {
          const valueArray = getFilterValuesFromURL(Array.isArray(value) ? value : value.split(','));
          query[apiKey] = encodeObjectToBase64({ $in: valueArray });
          break;
        }

        case 'role': {
          const valueArray = getFilterValuesFromURL(Array.isArray(value) ? value : value.split(','));

          if (valueArray.length > 0) query[apiKey] = encodeObjectToBase64({ [comparator]: valueArray });
          break;
        }

        case 'page':
        case 'age':
          query[apiKey] = value;
          break;
        default:
          break;
      }
    });

    return query;
  } catch (error) {
    return undefined;
  }
};

export const matchStatus = (prevProps, newProps, keys, status) => {
  return keys.reduce((res, key) => res || (prevProps[key] !== newProps[key] && newProps[key] === status), false);
};

/**
 * Get the hacker's application status
 *
 * @param {*} userHackathon the user hackathon details object containing status and team
 * @returns
 */
export const getApplicationStatus = userHackathon => {
  // When the hacker is a part of a team and his individual status is submit, we show the
  // team's status
  if (userHackathon?.status === APPLY_STATUS.SUBMIT && userHackathon?.team?.status !== undefined) {
    return userHackathon.team.status;
  }

  // Otherwise we display the hacker's individual status
  return userHackathon?.status;
};

export const isShallowEqual = (v, o) => {
  for (const key in v) {
    if (!(key in o) || v[key] !== o[key]) return false;
  }

  for (const key in o) {
    if (!(key in v) || v[key] !== o[key]) return false;
  }

  return true;
};

export const isAuthenticated = () => {
  const authState = getDevfolioUserCookie();
  const isAuthStateValid = !!authState?.uuid;
  return isAuthStateValid;
};

const hostNamePattern = /(?:\w+\.)+\w+/i;
export const convertToSeconds = value => {
  const nums = value.split(' ');
  if (nums.length === 4) return parseInt(nums[0], 10) * 60 + parseInt(nums[2], 10);
  if (value.includes('second')) {
    return parseInt(nums[0], 10);
  }
  if (value.includes('minute')) {
    return parseInt(nums[0], 10) * 60;
  }
  return 0;
};

export const convertToMinutes = value => {
  const num = parseInt(value, 10);
  if (num < 60) {
    return `${num} seconds`;
  }
  if (num % 60 === 0) {
    return `${num / 60} minutes`;
  }

  return `${parseInt(num / 60, 10)} minutes ${num % 60} seconds`;
};

export const getDifferentKeys = (a, b) => {
  return reduce(
    a,
    (result, value, key) => {
      return isEqual(value, b[key]) ? result : result.concat(key);
    },
    []
  );
};

export const getDifferenceBykey = (minuend, subtrahend, key) => {
  return minuend.filter(minuendObj => !subtrahend.find(subtrahendObj => subtrahendObj[key] === minuendObj[key]));
};

/**
 * Returns the array difference.
 *
 * If any one of the arguments passed is null,
 * return an array with empty arrays as values.
 *
 * Since we return two values, here we return an array of empty arrays.
 *
 * @param {Array} oldArray The old version of the array.
 * @param {Array} newArray The new version of the array.
 * @param {string} key The key of the object in the array to be compared.
 *
 * @returns an array of arrays with `newItems` and `removedItems`.
 */
export const getArrayDifference = (oldArray, newArray, key) => {
  if (!Array.isArray(oldArray) || !Array.isArray(newArray)) {
    return [[], []];
  }

  const removedItems = oldArray.filter(item =>
    key ? !newArray.find(newArrayObj => newArrayObj[key] === item[key]) : !newArray.includes(item)
  );
  const newItems = newArray.filter(item =>
    key ? !oldArray.find(oldArrayObj => oldArrayObj[key] === item[key]) : !oldArray.includes(item)
  );
  return [newItems, removedItems];
};

/* Update the URL when any of the
filter keys specified in the keys array
changes
*/

export const getHostName = input => {
  /**
   * Returns the hostname from the input
   * Returns the input if value is not a URL
   */
  if (isValidURL(input)) {
    return hostNamePattern.exec(input).toString().replace(/^www./i, '');
  }

  return input;
};

export const openFileInNewTab = async (username, hackathonSlug, fieldId, fileURL) => {
  const fileWindow = window.open('', '_blank');

  try {
    const response = await API.organizer.getHackathonFileViewURL(username, hackathonSlug, fieldId, fileURL);
    fileWindow.location = response.data.signedUrl;
  } catch (error) {
    logger.error('api', error, { username, hackathonSlug, fieldId, fileURL });
  }
};

export const getBase64File = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(btoa(reader.result));
    reader.onerror = error => reject(error);
  });
};

export const getImageWithRandomNumber = image => `${image}?dummy=${Math.round(Math.random() * 1000000)}`;

export const hasHackathonProperties = hackathon => {
  const updateProperties = Object.keys(hackathon);
  return updateProperties.some(property => HACKATHON_PROPERTIES.indexOf(property) !== -1);
};

export const hasHackathonSettingsProperties = hackathon => {
  const updateProperties = Object.keys(hackathon);
  return updateProperties.some(property => HACKATHON_SETTINGS_PROPERTIES.indexOf(property) !== -1);
};

export const mapToRootHackathon = data =>
  data.map(hackathon => {
    const settings = hackathon?.hackathon_setting;
    return {
      ai_assisted_reviews_enabled: settings?.ai_assisted_reviews_enabled,
      showMoreHackathons: settings?.show_more_hackathons,
      allowTravelReimbursement: settings?.allow_travel_reimbursement || false,
      applicationsCloseAt: settings?.reg_ends_at || '',
      applicationsOpenAt: settings?.reg_starts_at || '',
      applyMode: hackathon?.apply_mode || '',
      approxParticipants: hackathon?.approx_participant || '',
      bountyProcessType: settings?.bounties_process_type || null,
      bountyProcessState: settings?.bounties_status || null,
      city: hackathon?.city,
      cocUrl: settings?.coc_url || '',
      contactEmail: settings?.contact_email || '',
      country: hackathon?.country,
      coverImageUrl: hackathon?.cover_img || '',
      desc: hackathon?.desc || '',
      devfolioOfficial: hackathon?.devfolio_official || false,
      discord: settings?.discord || null,
      edition: hackathon?.edition || null,
      editionName: hackathon?.edition_name || null,
      endsAt: hackathon?.ends_at || '',
      eligibleEntities: settings?.eligible_entities || [],
      schwagProcessState: settings?.schwags_process_state || null,
      facebook: settings?.facebook || null,
      featured: hackathon?.featured || false,
      fellowship: hackathon?.fellowship || false,
      featuredImage: settings?.featured_cover_img || '',
      hackathon_brand: hackathon?.hackathon_brand || null,
      instagram: settings?.instagram || null,
      isOnline: hackathon?.is_online || false,
      isPrivate: hackathon?.private || false,
      judgingEnabled: settings?.judging_enabled || false,
      onlineJudgingEnabled: settings?.online_judging_enabled || false,
      sponsorJudgingEnabled: settings?.sponsor_judging_enabled || false,
      onChainCredentialsEnabled: settings?.on_chain_credentials_enabled || false,
      kycVerified: settings?.kyc_verified || false,
      leaderboardEnabled: settings?.leaderboard_enabled || false,
      linkedin: settings?.linkedin || null,
      location: hackathon?.location || '',
      logo: settings?.logo || '',
      medium: settings?.medium || null,
      name: hackathon?.name || '',
      notAcceptedMessage: settings?.notaccepted_msg || null,
      paymentMode: settings?.payment_mode || null,
      primaryColor: settings?.primary_color || null,
      prizes: hackathon?.prizes || [],
      quadraticVotingEnabled: settings?.quadratic_voting_enabled || false,
      quadraticVotingEndsAt: settings?.quadratic_voting_ends_at || '',
      quadraticVotingResultsAnnounced: settings?.quadratic_voting_results_announced || false,
      quadraticVotingPrizePool: settings?.quadratic_voting_prize_pool_amount || 0,
      quadraticVotingStartsAt: settings?.quadratic_voting_starts_at || '',
      quadraticVotingChain: settings?.quadratic_voting_chain || 'ARBITRUM',
      quadraticVotingCurrency: settings?.quadratic_voting_currency || 'USD',
      razorpayDesc: settings?.razorpay_desc || '',
      razorpayTitle: settings?.razorpay_title || '',
      referralLeaderboardPrizeURL: settings?.referral_prize_doc_link || null,
      reminderEmailLastSent: settings?.reminder_email_sent_at || '',
      rsvpBeforeTime: hackathon?.hackathon_setting?.rsvp_time / 24 || 0,
      review: settings?.review || false,
      schwagWinnersCount: settings?.schwag_winners_count || 0,
      schwagOrganizersCount: settings?.schwag_organizers_count || 0,
      site: settings?.site || null,
      slack: settings?.slack || null,
      slug: hackathon?.slug || '',
      startsAt: hackathon?.starts_at || '',
      state: hackathon?.state,
      status: hackathon?.status || 'draft',
      subdomain: settings?.subdomain || '',
      type: hackathon?.type || null,
      tagline: hackathon?.tagline || '',
      minTeamSize: hackathon?.team_min || 2,
      maxTeamSize: hackathon?.team_size || 3,
      telegram: settings?.telegram || null,
      themed: hackathon?.themed || false,
      ticketFontColor: settings?.ticket_font_color || '',
      ticketImg: settings?.ticket_img || '',
      ticketLimit: settings?.ticket_limit || 0,
      ticketLimitFemale: settings?.ticket_limit_f || 0,
      ticketLimitMale: settings?.ticket_limit_m || 0,
      ticketMsg1: settings?.ticket_msg1 || '',
      ticketMsg2: settings?.ticket_msg2 || '',
      ticketRefundable: settings?.ticket_refundable || false,
      timezone: hackathon?.timezone || 'Asia/Calcutta',
      twitter: settings?.twitter || null,
      uuid: hackathon?.uuid || null,
      verified: hackathon?.verified || false,
      paid: settings?.paid || false,
      waitlistMessage: settings?.waitlist_msg || null,
      whitelistDomain: settings?.whitelist_domain || null,
      winnerAnnounceAt: settings?.winner_announce_at,
      womenOnly: settings?.women_only || false,
      discordGuildID: settings?.discord_guild_id || null,
      discordRole: settings?.discord_role || null,
      discordCheckinEnabled: settings?.discord_checkin_enable || false,
      discordBotChannelName: settings?.discord_bot_channel_name || null,
      midHackathonCheckinEnabled: settings?.mid_hackathon_checkin_enabled || false,
      midHackathonCheckinStartsAt: settings?.mid_hackathon_checkin_starts_at || '',
      midHackathonCheckinEndsAt: settings?.mid_hackathon_checkin_ends_at || '',
    };
  });

export const getHackathonPublicPage = subdomain => {
  const base = BASE_URL.replace(/https?:\/\/(www.)?/i, '');
  return `https://${subdomain}.${base}`;
};

export const generateAllWhitelistDomains = website => {
  if (website) {
    const trimmedWebsite = website.replace(/(^https?:\/\/.*?)(\/.*)/g, '$1');
    if (trimmedWebsite.match(/^https?:\/\/www\./g)) {
      return `${trimmedWebsite},${trimmedWebsite.replace(/(^https?:\/\/)(www\.)/g, '$1')}`;
    }
    return `${trimmedWebsite.replace(/(^https?:\/\/)/g, '$1www.')},${trimmedWebsite}`;
  }
  return undefined;
};

export const sortByDate = (data, key, reverse = true) =>
  // eslint-disable-next-line new-cap
  orderBy(data, [object => new moment(object[key])], [reverse ? 'desc' : 'asc']);

export const calculatePercentage = (category, total) => {
  return Math.round((category / total) * 100);
};

export const getRequiredSections = (sections, isOnline, isOnlineWithReview) => {
  const commonOptionalBasicFields = ['tagline', 'approxParticipants', 'city', 'state', 'country', 'themes', 'type'];
  const commonOptionalLinkFields = [
    'website',
    'linkedin',
    'facebook',
    'instagram',
    'twitter',
    'useDevfolioCoc',
    'discord',
    'slack',
    'telegram',
  ];

  if (isOnline) {
    return {
      basics: omit(sections.basics, [...commonOptionalBasicFields, 'location']),

      links: omit(sections.links, commonOptionalLinkFields),

      brand: sections.brand,

      dates: omit(sections.dates, ['rsvpBeforeTime']),
    };
  }
  if (isOnlineWithReview) {
    return {
      basics: omit(sections.basics, [...commonOptionalBasicFields, 'location']),

      links: omit(sections.links, commonOptionalLinkFields),

      brand: sections.brand,

      dates: omit(sections.dates, ['winnerAnnounceAt']),
    };
  }
  return {
    basics: omit(sections.basics, commonOptionalBasicFields),

    links: omit(sections.links, commonOptionalLinkFields),

    brand: sections.brand,

    dates: omit(sections.dates, ['winnerAnnounceAt']),
  };
};

export const getTotalKeys = obj => Object.keys(obj).length;

export const extractAddress = value => {
  let pinCode = '';
  let city = '';
  let state = '';
  let country = '';

  if (value !== undefined) {
    try {
      const address = value.gmaps.address_components;

      const postalCodeFilter = address.filter(x => x.types[0] === 'postal_code');
      const cityFilter = address.filter(x => x.types[0] === 'locality');
      const stateFilter = address.filter(x => x.types[0] === 'administrative_area_level_1');
      const countryFilter = address.filter(x => x.types[0] === 'country');

      if (countryFilter.length) country = countryFilter[0].long_name;

      if (stateFilter.length) {
        state = stateFilter[0].long_name;
      }

      if (cityFilter.length) {
        city = cityFilter[0].long_name;
      }

      if (postalCodeFilter.length) pinCode = postalCodeFilter[0].long_name;
    } catch (error) {
      return { city, state, country, pinCode };
    }
  }

  return { city, state, country, pinCode };
};

export const getFileType = file => {
  const mimeType = file.type;
  let type = mimeType.split('/');
  type = type[type.length - 1];
  return { type, mimeType };
};

export const swapElements = (items, firstIndex, secondIndex) => {
  const result = [...items];
  [result[firstIndex], result[secondIndex]] = [result[secondIndex], result[firstIndex]];
  return result;
};

export const injectScript = src => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', e => {
      reject(e);
    });
    document.body.appendChild(script);
  });
};

/**
 * Checks if the document's active element is an input or a text-area.
 * In such cases, we would want to prevent keyboard events from triggering any shortcuts.
 */
export const isActiveElementAnInput = () => {
  const { activeElement } = document;
  const inputElementTypes = ['input', 'textarea'];
  return activeElement !== null && inputElementTypes.includes(activeElement.tagName.toLowerCase());
};

/**
 * @reference https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 * @description This will convert a string in Base64 format to a string in UTF 8 format
 * @example if we pass str = "ZGV2Zm9saW8uY28="
 * The function will return us a string with value "devfolio.co"
 */

export const convertBase64ToUTF8 = str =>
  decodeURIComponent(
    atob(str)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

/**
 * This function checks for common API status codes
 * that can be used to set the severity level of errors
 * while pushing the logs to Sentry.
 */
export const apiErrorStatus = {
  internalServerError: error => {
    return error?.response?.status >= 500;
  },
  unprocessableRequestError: error => {
    return error?.response?.status === 422;
  },
};

/**
 * Downloads a file from a Blob
 * @reference https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
 * @param {Blob} blob The blob that needs to be downloaded
 * @param {string} fileName The name of the file that will be saved on the client's machine
 */
export const downloadFileFromBlob = (blob, fileName) => {
  // Generate the URL from the blob
  const url = window.URL.createObjectURL(new Blob([blob]));
  // Create an anchor tag with the download attribute and the name of the file
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * Makes a list in the Oxford comma style (eg "a, b, c, and d")
 * @param {[string]} arr Array of strings that need to be joined
 * @param {string} [conjunction="and"] The conjunction than will be used to join the last parts
 * @param {string} [ifEmpty=""] String to be returned when the array is empty
 * @returns {string} The oxfordJoined string
 *
 * @example
 * Examples with conjunction "and":
 * ["a"] -> "a"
 * ["a", "b"] -> "a and b"
 * ["a", "b", "c"] -> "a, b, and c"
 */
export const oxfordJoin = (arr, conjunction = 'and', ifEmpty = '') => {
  const array = arr?.slice();
  const length = array?.length || 0;
  if (length === 0) return ifEmpty;
  if (length < 2) return array[0];
  if (length < 3) return array.join(` ${conjunction} `);
  array[length - 1] = `${conjunction} ${array[length - 1]}`;
  return array.join(', ');
};

/**
 * Redirect to the user to the devfolio
 * sign in page when he needs to be
 * logged in
 * @param {boolean} [hasInsufficientAccess=false] Redirect to sigin with
 * insufficient access query param
 */
export const redirectToSignin = (hasInsufficientAccess = false) => {
  const currentURL = document.location.href;
  let additionalQueryParams = '';
  if (hasInsufficientAccess) {
    additionalQueryParams = '&insufficient_access=true';
  }
  // Add the current URL as a query param so that the user can be redirected once
  // he is logged in
  document.location.href = `${DEVFOLIO_BASE}signin?from=${encodeURIComponent(currentURL)}${additionalQueryParams}`;
};

/**
 * Returns the config with headers for file upload
 * @param {string} config.contentType The type of the file
 * @param {boolean} config.isPrivate Is the file public or private
 */
// TODO: This should be moved to devfolio helpers
export const getUploadFileConfig = ({ contentType, isPrivate }) => {
  return {
    headers: {
      'Content-Type': contentType,
      'Content-Disposition': 'inline',
      'Cache-Control': isPrivate ? 'max-age=0, must-revalidate' : 'public, max-age=31536000, immutable',
      [AMZ_HEADER]: isPrivate ? AMZ_ACL.PRIVATE : AMZ_ACL.PUBLIC_READ,
    },
  };
};

/**
 * This method checks if the position of the items provided by react
 * sortable has changed or not
 * @param {Items[]} updatedItems The udpated items provided by react sortable
 * @returns A boolean value indicating whether the position of items has updated
 */
export const hasSortablePositionChanged = updatedItems =>
  updatedItems.some((item, index) => item.position !== index + 1);

/**
 * Replaces the timezone of the given date and then converts it to
 * UTC
 *
 * @example
 * If this function gets the following date and timezone -
 * `Thu May 27 2021 10:30:00 GMT-0700`, `Asia/Calcutta`
 *
 * It first replaces the timezone with the timezone provided without
 * affecting date -
 * Thu May 27 2021 10:30:00 GMT+5:30
 *
 * Then it returns the corresponding value in UTC -
 * Thu May 27 2021 05:00:00 GMT+0000
 *
 * P.S. - The dates were coverted to string format in example for better readablity
 *
 *
 * @param {string} value The value of the date in ISO string to be converted
 * @param {string} timezone The value of the timezone
 * @returns ISO time string in UTC
 */
export const replaceTimezoneInUTC = (value, timezone) => moment(value).tz(timezone, true).utc().toISOString();

/**
 * Trims string values of an object
 * @param {object} obj The object from which the string values need to be trimmed
 * @returns A new object with trimmed string values
 */
export const trimAllStringValues = obj => {
  const trimmedObj = {};

  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  Object.keys(obj).forEach(key => {
    trimmedObj[key] = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
  });

  return trimmedObj;
};

/**
 * Returns the hackathon microsite URL
 * for given hackathon slug
 * @param hackathonSlug string
 * @returns Hackathon microsite URL
 */
export const getHackathonMicrosite = (hackathonSlug: string): string => {
  const base = BASE_URL.replace(/https?:\/\//i, '');
  return `http${NODE_ENV === 'production' ? 's' : ''}://${hackathonSlug}.${base}`;
};

/**
 * Formats a given number to USD currency
 * @param {number} amount The amount that needs to be formatted
 * @returns {string} The formatted amount
 */
export const formatUSDAmount = (amount: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(amount);
};

/**
 * Checks if the hackathon ends at date is past the allowed time period
 * which can be used to block certain edits for the hackathon
 * @param {string} endsAt The date after which the hackathon is over
 * @returns {boolean} Whether the hackathon end date is past the allowed time period
 */
export const shouldBlockHackathonEdits = endsAt => moment().isAfter(moment(endsAt).add(14, 'day'));

/**
 * Returns a formatted currency string according to
 * the given currency
 * @param amount The amount to be formatted
 * @param currency The currency to be used
 * @returns Formatted amount
 */
export const getFormattedAmount = (
  amount: number,
  currency?: 'INR' | 'USD' | 'SPORK' | 'ETH',
  precision?: number
): string => {
  const finalCurrency = currency === 'SPORK' ? 'USD' : currency || 'USD';
  const currencyFormat = finalCurrency === 'USD' || finalCurrency === 'ETH' ? 'en-US' : 'en-IN';
  const formattedAmmount = new Intl.NumberFormat(currencyFormat, {
    style: 'currency',
    currency: finalCurrency,
    maximumFractionDigits: precision ?? 0,
    minimumFractionDigits: 0,
    notation: 'compact',
    compactDisplay: 'short',
  }).format(precision ? amount : Math.round(amount));

  if (currency === 'SPORK') {
    return formattedAmmount.replace('$', '$SPORK ');
  }
  return formattedAmmount;
};

/**
 * Groups events by their associated event groups.
 * @param {Event[]} events - An array of events to be grouped.
 * @param {EventGroupI[]} eventGroups - An array of event groups to categorize events.
 * @returns {GroupedEventI[]} An array of grouped events, each with a corresponding event group.
 */

export const getGroupedEvents = (events, eventGroups: EventGroupI[]): GroupedEventI[] => {
  if (eventGroups.length === 0 && events.length === 0) {
    // no events (we'll create a new layout on server)
    return [];
  }

  const defaultGroups = [];
  const nonGroupedEvents = events.filter(event => typeof event.groupUUID !== 'string');

  if (nonGroupedEvents.length > 0) {
    // for backwards compatibility add events without group into default group
    defaultGroups.push({
      order: 1,
      name: 'Timeline',
      uuid: 'default',
      events: nonGroupedEvents.map(event => ({ ...event, groupUUID: 'default' })),
    });
  }

  return [
    ...defaultGroups,
    ...eventGroups.map(group => ({
      ...group,
      events: events.filter(event => event.groupUUID === group.uuid),
    })),
  ];
};

/**
 * @description Disabled input change on scroll on chromium
 * @reference https://linear.app/devfolio/issue/PRO-3916/remove-scroll-to-change-number-on-input-type=number-on-chromium
 * @note should eventually be restricted from <Textfield/> component in genesis
 */

export const disableInputChangeOnWheel = e => {
  // Prevent the input value change
  e.target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();
};
