import axios, { AxiosError } from 'axios';
import { logger } from '../helpers/logger';

/**
 * Handles error occoured in axios interceptors
 * @param {AxiosError} error The error caught in interceptor
 */
export const errorHandler = (error: AxiosError): unknown => {
  // We have an error response, catch and log it appropriately.
  if (error?.response && error?.config) {
    const { response, config } = error;

    if (config.method === 'PUT') {
      logger.error('api', error, {
        data: {
          response: response?.data,
          status: response?.status,
          statusText: response?.statusText,
        },
      });
    }

    return Promise.reject(error);
  }

  // The request was made but we do not have a response, a network error
  // has likely occured in this case.
  if (error?.request) {
    const { request } = error;
    logger.error('api', error, { data: { request } });
    return Promise.reject(error);
  }

  // Something happened in setting up the request that triggered an Error.
  logger.error('api', error);
  return Promise.reject(error);
};

/**
 * Axios instance for handling external API requests
 * @type {AxiosInstance}
 */
export const externalAxios = axios.create();

externalAxios.interceptors.request.use(
  config => {
    const requestConfig = config;

    // Delete the access_token header for an external API request.
    delete requestConfig.headers.access_token;

    return Promise.resolve(requestConfig);
  },
  error => Promise.reject(error)
);

externalAxios.interceptors.response.use(response => response, errorHandler);
