import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';

const initialState = {
  myHackathons: [],
  currentHackathon: {},
  searchKeys: {
    admin: {
      key: '',
      createdAt: '',
    },
    volunteer: {
      key: '',
      createdAt: '',
    },
    review: {
      key: '',
      createdAt: '',
    },
  },
  status: {
    fetchHackathonData: STATUS.READY,
    fetchHackathons: STATUS.READY,
    sendReminderEmails: STATUS.READY,
    fetchSearchKeys: STATUS.READY,
  },
};

const hackathon = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_HACKATHON_DATA_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          fetchHackathonData: STATUS.FAILURE,
        },
      };
    }
    case types.FETCH_HACKATHON_DATA_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          fetchHackathonData: STATUS.REQUEST,
        },
      };
    }
    case types.FETCH_HACKATHON_DATA_SUCCESS: {
      return {
        ...state,
        currentHackathon: action.payload,
        status: {
          ...state.status,
          fetchHackathonData: STATUS.SUCCESS,
        },
      };
    }

    case types.CHANGE_APPLICATION_DEADLINE: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          currentHackathon: {
            ...state.currentHackathon,
            hackathon_setting: {
              ...state.currentHackathon.hackathon_setting,
              reg_ends_at: payload.regEndsAt,
            },
          },
        };
      }
      return state;
    }

    case types.EXTEND_SUBMISSION_DEADLINE: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          currentHackathon: {
            ...state.currentHackathon,
            ends_at: payload.endsAt,
          },
        };
      }
      return state;
    }

    case types.SEND_REMINDER_EMAILS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          currentHackathon: {
            ...state.currentHackathon,
            hackathon_setting: {
              ...state.currentHackathon.hackathon_setting,
              reminder_email_sent_at: payload.lastReminderEmailsSent,
            },
          },
          status: {
            ...state.status,
            sendReminderEmails: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          sendReminderEmails: payload.status,
        },
      };
    }

    case types.FETCH_ORGANIZER_HACKATHONS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          myHackathons: [...initialState.myHackathons, ...action.payload.data],
          status: {
            ...state.status,
            fetchHackathons: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchHackathons: payload.status,
        },
      };
    }

    case types.DELETE_HACKATHON: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          myHackathons: [
            ...state.myHackathons.filter(currentHackathon => currentHackathon.uuid !== payload.hackathonUuid),
          ],
        };
      }
      return state;
    }

    case types.FETCH_SEARCH_KEYS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          searchKeys: {
            ...state.searchKeys,
            ...payload.keyData,
          },
          status: {
            ...state.status,
            fetchSearchKeys: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchSearchKeys: payload.status,
        },
      };
    }

    case types.LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
};

export default hackathon;
