import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';

const initialState = {
  currentQuiz: {
    uuid: '',
    name: '',
    desc: '',
    totalQuestions: 0,
    totalMarks: 0,
    cutOff: 0,
    skill: '',
    code: {
      beginner: 0,
      intermediate: 0,
      advanced: 0,
    },
    theory: {
      beginner: 0,
      intermediate: 0,
      advanced: 0,
    },
    publish: false,
  },
  currentSkillQuestionCount: {
    code: {
      beginner: 0,
      intermediate: 0,
      advanced: 0,
    },
    theory: {
      beginner: 0,
      intermediate: 0,
      advanced: 0,
    },
  },
  quizzes: {
    data: [],
    count: 0,
    pages: 0,
    currentPage: 0,
  },
  error: '',
  questions: {
    data: [],
    count: 0,
    pages: 0,
    currentPage: 0,
  },
  resources: {
    data: [],
    count: 0,
    pages: 0,
    currentPage: 0,
  },
  status: {
    addQuestion: STATUS.READY,
    fetchQuestions: STATUS.READY,
    fetchQuizzes: STATUS.READY,
    updateQuestion: STATUS.READY,
    updateQuiz: STATUS.READY,
    createResource: STATUS.READY,
    updateResource: STATUS.READY,
    fetchResources: STATUS.READY,
  },
};

const quiz = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_QUESTION: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          questions: {
            ...state.questions,
            data: [...state.questions.data, payload.question],
          },
          status: {
            ...state.status,
            addQuestion: action.payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          addQuestion: action.payload.status,
        },
      };
    }
    case types.FETCH_QUESTIONS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          questions: {
            ...state.questions,
            count: payload.count,
            data: payload.currentPage === 1 ? payload.questions : [...state.questions.data, ...payload.questions],
            pages: payload.pages,
          },
          status: {
            ...state.status,
            fetchQuestions: payload.status,
          },
        };
      }
      return {
        ...state,
        questions: {
          ...state.questions,
          currentPage: payload.currentPage || 0,
        },
        status: {
          ...state.status,
          fetchQuestions: payload.status,
        },
      };
    }

    case types.UPDATE_QUESTION: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          questions: {
            ...state.questions,
            data: state.questions.data.map(question => {
              if (question.uuid === payload.updatedQuestion.uuid) {
                return payload.updatedQuestion;
              }
              return question;
            }),
          },
          status: {
            ...state.status,
            updateQuestion: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateQuestion: payload.status,
        },
      };
    }

    case types.UPDATE_QUIZ: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        const elemIndex = state.quizzes.data.findIndex(({ uuid }) => uuid === payload.quiz.uuid);
        return {
          ...state,
          error: '',
          quizzes: {
            ...state.quizzes,
            data:
              elemIndex === -1
                ? [...state.quizzes.data, payload.quiz]
                : [...state.quizzes.data.slice(0, elemIndex), payload.quiz, ...state.quizzes.data.slice(elemIndex + 1)],
          },
          status: {
            ...state.status,
            updateQuiz: payload.status,
          },
        };
      }
      return {
        ...state,
        error: payload?.error || '',
        status: {
          ...state.status,
          updateQuiz: payload.status,
        },
      };
    }

    case types.UPDATE_CURRENT_QUIZ: {
      return {
        ...state,
        currentQuiz: action.payload.quiz || initialState.currentQuiz,
        currentSkillQuestionCount: action.payload.currentSkillQuestionCount || initialState.currentSkillQuestionCount,
      };
    }

    case types.FETCH_QUIZZES: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          quizzes: {
            ...state.quizzes,
            count: payload.count,
            data: payload.currentPage === 1 ? payload.quizzes : [...state.quizzes.data, ...payload.quizzes],
            pages: payload.pages,
          },
          status: {
            ...state.status,
            fetchQuizzes: payload.status,
          },
        };
      }
      return {
        ...state,
        quizzes: {
          ...state.quizzes,
          currentPage: payload.currentPage || 0,
        },
        status: {
          ...state.status,
          fetchQuizzes: payload.status,
        },
      };
    }

    case types.CREATE_RESOURCE: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          resources: {
            ...state.resources,
            data: [...state.resources.data, payload.resource],
          },
          status: {
            ...state.status,
            createResource: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          createResource: payload.status,
        },
      };
    }

    case types.UPDATE_RESOURCE: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        const resourceIndex = state.resources.data.findIndex(({ uuid }) => uuid === payload.resource.uuid);
        return {
          ...state,
          resources: {
            ...state.resources,
            data: [
              ...state.resources.data.slice(0, resourceIndex),
              payload.resource,
              ...state.resources.data.slice(resourceIndex + 1),
            ],
          },
          status: {
            ...state.status,
            updateResource: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateResource: payload.status,
        },
      };
    }

    case types.FETCH_RESOURCES: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          resources: {
            ...state.resources,
            data: payload.currentPage === 1 ? payload.resources : [...state.resources.data, ...payload.resources],
            count: payload.count,
          },
          status: {
            ...state.status,
            fetchResources: payload.status,
          },
        };
      }
      return {
        ...state,
        resources: {
          ...state.resources,
          currentPage: payload.currentPage || 0,
        },
        status: {
          ...state.status,
          fetchResources: payload.status,
        },
      };
    }
    default:
      return state;
  }
};

export default quiz;
