import { NODE_ENV } from 'constants/environment';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { getDevfolioUserCookie } from '@devfolioco/helpers';
import { initialState as initialAuthState } from './reducers/authentication';
import rootReducer from './reducers';

const devfolioAuth = getDevfolioUserCookie();
const initialState =
  devfolioAuth !== null ? { authentication: { ...initialAuthState, ...devfolioAuth, isAuthenticated: true } } : {};
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));

export default store;
