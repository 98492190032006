import { ACTION_STATUS as STATUS, SCOPES, SAVE, ACTION_STATUS } from '../constants';
import * as types from '../constants/actions';

const initialState = {
  hackathonSetup: {
    name: '',
    tagline: '',
    about: '',
    approxParticipants: '',
    location: '',

    rsvpBeforeTime: '',
    minTeamSize: '',
    maxTeamSize: '',

    contactEmail: '',
    contactEmailVerified: false,
    facebook: '',
    twitter: '',
    instagram: '',
    website: '',
    linkedin: '',
    coc: '',
    useDevfolioCoc: false,
    discord: '',
    slack: '',
    telegram: '',
    farcaster: '',

    primaryColor: '',
    logo: '',
    favicon: '',
    cover: '',

    timezone: '',
    applicationsOpenAt: '',
    applicationsCloseAt: '',
    startsAt: '',
    endsAt: '',
    winnerAnnounceAt: '',

    themes: [],
    tracks: [],
    events: [],
    faqs: [],
    judges: [],
    sponsors: [],
    judgesLater: false,
    faqsLater: false,
    sponsorsLater: false,

    application: {
      [SCOPES.ABOUT]: [],
      [SCOPES.EDUCATION]: false,
      [SCOPES.SKILLS]: [],
      [SCOPES.LINKS]: [],
      [SCOPES.CONTACT]: [],
      [SCOPES.EXTRA_FIELDS]: [],
      [SCOPES.USER_HACKATHON]: [],
    },

    slug: '',
    uuid: '',
    subdomain: '',
    isOnline: false,
  },
  hasModifiedWinnersWithoutCheckingPublish: false,
  status: {
    fetchHackathon: STATUS.READY,
    createHackathon: STATUS.READY,
    updateHackathon: STATUS.READY,
    deleteHackathon: STATUS.READY,

    fetchExtraFields: STATUS.READY,
    addExtraField: STATUS.READY,
    deleteExtraField: STATUS.READY,

    uploadCoverImage: STATUS.READY,
    uploadLogo: STATUS.READY,
    uploadFavicon: STATUS.READY,
    deleteImage: STATUS.READY,

    fetchFaq: STATUS.READY,
    addFaq: STATUS.READY,
    updateFaq: STATUS.READY,
    deleteFaq: STATUS.READY,

    addWinner: STATUS.READY,
    deleteWinner: STATUS.READY,

    addEvent: STATUS.READY,
    updateEvent: STATUS.READY,
    deleteEvent: STATUS.READY,

    addEventGroup: STATUS.READY,
    updateEventGroup: STATUS.READY,
    deleteEventGroup: STATUS.READY,

    addJudge: STATUS.READY,
    updateJudge: STATUS.READY,
    deleteJudge: STATUS.READY,

    fetchSponsors: STATUS.READY,
    addSponsorTier: STATUS.READY,
    addSponsor: STATUS.READY,
    deleteSponsor: STATUS.READY,
    deleteSponsorTier: STATUS.READY,
    updateSponsorPosition: STATUS.READY,
    updateItemPosition: STATUS.READY,
    sendContactEmailVerificationOTP: STATUS.READY,
    verifyContactEmail: STATUS.READY,
    toggleWinnersAnnounced: STATUS.READY,
  },
  addJudgeError: '',
  createHackathonError: '',
  saveStatus: SAVE.SUCCESS,

  // contains the id of the newly created id of event group. We use it to scroll to the new element
  lastEventGroupCreatedUUID: null,
  lastEventCreatedUUID: null,
  lastEventUpdatedUUID: null,
};

export type OrganizerStoreI = typeof initialState;

const organizer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPLOAD_COVER: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            cover: payload.cover,
          },
          status: {
            ...state.status,
            uploadCoverImage: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          uploadCoverImage: payload.status,
        },
      };
    }

    case types.UPLOAD_LOGO: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            logo: payload.logo,
          },
          status: {
            ...state.status,
            uploadLogo: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          uploadLogo: payload.status,
        },
      };
    }

    case types.UPLOAD_FAVICON: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            favicon: payload.favicon,
          },
          status: {
            ...state.status,
            uploadFavicon: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          uploadFavicon: payload.status,
        },
      };
    }

    case types.DELETE_IMAGE: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            [payload.image]: null,
          },
          status: {
            ...state.status,
            deleteImage: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          deleteImage: payload.status,
        },
      };
    }

    case types.FETCH_FAQS: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            fetchFaq: payload.status,
          },
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            faqs: payload.faqs,
          },
        }
      );
    }

    case types.ADD_FAQ: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            addFaq: payload.status,
          },
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            faqs: [...state.hackathonSetup.faqs, payload.faq],
          },
        }
      );
    }

    case types.UPDATE_FAQ: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            updateFaq: payload.status,
          },
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            faqs: state.hackathonSetup.faqs.map(faq => {
              if (faq.uuid === payload.faq.uuid) {
                return payload.faq;
              }
              return faq;
            }),
          },
        }
      );
    }

    case types.DELETE_FAQ: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            deleteFaq: payload.status,
          },
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            faqs: [...state.hackathonSetup.faqs.filter(faq => faq.uuid !== payload.faqUUID)],
          },
        }
      );
    }

    case types.ADD_JUDGE: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            addJudge: payload.status,
          },
          addJudgeError: payload?.error,
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            judges: [...state.hackathonSetup.judges, payload.judge],
          },
        }
      );
    }

    case types.UPDATE_JUDGE: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            updateJudge: payload.status,
          },
          addJudgeError: payload?.error,
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            judges: state.hackathonSetup.judges.map(judge => {
              if (judge.uuid === payload.judge.uuid) {
                return payload.judge;
              }

              return judge;
            }),
          },
        }
      );
    }

    case types.DELETE_JUDGE: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            deleteJudge: payload.status,
          },
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            judges: [...state.hackathonSetup.judges.filter(judge => judge.uuid !== payload.judgeId)],
          },
        }
      );
    }

    case types.CREATE_HACKATHON: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            createHackathon: payload.status,
          },
          createHackathonError: payload?.error || '',
        },
        payload.status !== STATUS.FAILURE && {
          hackathonSetup: {
            ...initialState.hackathonSetup,
            ...payload.hackathon,
          },
        }
      );
    }

    case types.UPDATE_HACKATHON: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            updateHackathon: payload.status || STATUS.READY,
          },
        },
        payload.updateOnSuccess &&
          payload.status !== STATUS.FAILURE && {
            hackathonSetup: {
              ...state.hackathonSetup,
              ...payload.hackathon,
            },
          }
      );
    }

    case types.FETCH_HACKATHON: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            fetchHackathon: payload.status,
          },
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            ...payload.hackathon,
          },
        }
      );
    }

    case types.SEND_CONTACT_EMAIL_VERIFICATION_OTP: {
      const { payload } = action;

      return {
        ...state,
        status: {
          ...state.status,
          sendContactEmailVerificationOTP: payload.status,
          verifyContactEmail: STATUS.READY,
        },
      };
    }

    case types.VERIFY_CONTACT_EMAIL: {
      const { payload } = action;

      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            contactEmail: payload.email,
            contactEmailVerified: true,
          },
          status: {
            ...state.status,
            verifyContactEmail: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          verifyContactEmail: payload.status,
        },
      };
    }

    case types.CLEAR_HACKATHON_SETUP:
      return {
        ...state,
        hackathonSetup: initialState.hackathonSetup,
        status: {
          ...state.status,
          updateHackathon: STATUS.READY,
        },
      };

    case types.DELETE_HACKATHON:
      return {
        ...state,
        status: {
          ...state.status,
          deleteHackathon: action.payload.status,
        },
      };

    case types.LOGOUT_SUCCESS:
      return { ...initialState };

    case types.FETCH_EXTRA_FIELDS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            application: {
              ...state.hackathonSetup.application,
              extraFields: payload.data,
            },
          },
          status: {
            ...state.status,
            fetchExtraFields: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchExtraFields: payload.status,
        },
      };
    }

    case types.ADD_EXTRA_FIELD: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            application: {
              ...state.hackathonSetup.application,
              extraFields: payload.data,
            },
          },
          status: {
            ...state.status,
            addExtraField: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          addExtraField: payload.status,
        },
      };
    }

    case types.DELETE_EXTRA_FIELD: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            application: {
              ...state.hackathonSetup.application,
              extraFields: payload.data,
            },
          },
          status: {
            ...state.status,
            deleteExtraField: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          deleteExtraField: payload.status,
        },
      };
    }

    case types.FETCH_SPONSORS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            sponsors: payload.data,
          },
          status: {
            ...state.status,
            fetchSponsors: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchSponsors: payload.status,
        },
      };
    }

    case types.ADD_SPONSOR_TIER: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        const stateSponsorTiers = state.hackathonSetup.sponsors;
        let sponsorTiers = [];
        if (!payload.tierAdded) {
          sponsorTiers = stateSponsorTiers.map(tier => {
            if (tier.uuid === payload.data.uuid) {
              return {
                ...tier,
                ...payload.data,
              };
            }
            return tier;
          });
        } else {
          sponsorTiers = [...stateSponsorTiers, payload.data];
        }
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            sponsors: sponsorTiers,
          },
          status: {
            ...state.status,
            addSponsorTier: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          addSponsorTier: payload.status,
        },
      };
    }

    case types.ADD_SPONSOR: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        const currentTier = { ...state.hackathonSetup.sponsors.find(tier => tier.uuid === payload.tierUUID) };
        if (payload.sponsorAdded) {
          currentTier.sponsors = currentTier?.sponsors?.length
            ? [...currentTier.sponsors, payload.data]
            : [payload.data];
        } else {
          currentTier.sponsors = currentTier.sponsors.map(sponsor => {
            if (sponsor.uuid === payload.data.uuid) {
              return {
                ...sponsor,
                ...payload.data,
              };
            }
            return sponsor;
          });
        }
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            sponsors: state.hackathonSetup.sponsors.map(tier => {
              if (tier.uuid === payload.tierUUID) {
                return currentTier;
              }
              return tier;
            }),
          },
          status: {
            ...state.status,
            addSponsor: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          addSponsor: payload.status,
        },
      };
    }

    case types.DELETE_SPONSOR: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        const currentTier = { ...state.hackathonSetup.sponsors.find(({ uuid }) => uuid === payload.tierUUID) };
        currentTier.sponsors = currentTier.sponsors.filter(({ uuid }) => uuid !== payload.sponsorUUID);
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            sponsors: state.hackathonSetup.sponsors.map(tier => {
              if (tier.uuid === currentTier.uuid) {
                return currentTier;
              }
              return tier;
            }),
          },
          status: {
            ...state.status,
            deleteSponsor: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          deleteSponsor: payload.status,
        },
      };
    }

    case types.DELETE_SPONSOR_TIER: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            sponsors: state.hackathonSetup.sponsors.filter(({ uuid }) => uuid !== payload.tierUUID),
          },
          status: {
            ...state.status,
            deleteSponsorTier: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          deleteSponsorTier: payload.status,
        },
      };
    }
    case types.UPDATE_SPONSOR_POSITION: {
      const { payload } = action;
      if (payload.status === STATUS.REQUEST) {
        const currentTier = { ...state.hackathonSetup.sponsors.find(({ uuid }) => uuid === payload.tierUUID) };
        currentTier.sponsors = payload.updatedSponsors;
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            sponsors: state.hackathonSetup.sponsors.map(tier => {
              if (tier.uuid === currentTier.uuid) {
                return currentTier;
              }
              return tier;
            }),
          },
          status: {
            ...state.status,
            updateSponsorPosition: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateSponsorPosition: payload.status,
        },
      };
    }

    case types.UPDATE_SAVE_STATUS:
      return {
        ...state,
        saveStatus: action.payload,
      };

    case types.SWAP_TIERS: {
      const { payload } = action;
      if (payload.status === STATUS.REQUEST) {
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            sponsors: payload.updatedTiers,
          },
          status: {
            ...state.status,
            swapTiers: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          swapTiers: payload.status,
        },
      };
    }

    case types.UPDATE_ITEM_POSITION: {
      const { payload } = action;
      if (payload.status === STATUS.REQUEST) {
        if (payload.itemKey === 'extra_questions') {
          return {
            ...state,
            hackathonSetup: {
              ...state.hackathonSetup,
              application: {
                ...state.hackathonSetup.application,
                [SCOPES.EXTRA_FIELDS]: payload.updatedItems,
              },
            },
            status: {
              ...state.status,
              updateItemPosition: payload.status,
            },
          };
        }
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            [payload.itemKey]: payload.updatedItems,
          },
          status: {
            ...state.status,
            updateItemPosition: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateItemPosition: payload.status,
        },
      };
    }

    case types.ADD_EVENT: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            addEvent: payload.status,
          },
          lastEventCreatedUUID: payload?.event?.uuid ?? null,
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            events: [...state.hackathonSetup.events, payload.event].sort(
              (a, b) => new Date(a.startsAt) - new Date(b.startsAt)
            ),
          },
        }
      );
    }

    case types.UPDATE_EVENT: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            updateEvent: payload.status,
          },
          lastEventUpdatedUUID: payload?.event?.uuid ?? null,
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            events: state.hackathonSetup.events
              .map(event => {
                if (event.uuid === payload.event.uuid) {
                  return payload.event;
                }

                return event;
              })
              .sort((a, b) => new Date(a.startsAt) - new Date(b.startsAt)),
          },
        }
      );
    }

    case types.DELETE_EVENT: {
      const { payload } = action;
      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            deleteEvent: payload.status,
          },
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            events: [...state.hackathonSetup.events.filter(event => event.uuid !== payload.eventUUID)],
          },
        }
      );
    }

    case types.ADD_EVENT_GROUP: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            addEventGroup: payload.status,
          },
          lastEventGroupCreatedUUID: payload?.eventGroup?.uuid ?? null,
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            eventGroups: [...state.hackathonSetup.eventGroups, payload.eventGroup],
          },
        }
      );
    }

    case types.UPDATE_EVENT_GROUP: {
      const { payload } = action;
      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            updateEventGroup: payload.status,
          },
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            eventGroups: state.hackathonSetup.eventGroups.map(group => {
              if (group.uuid === payload.eventGroup.uuid) {
                // only update the changed fields
                return {
                  ...group,
                  ...payload.eventGroup,
                };
              }

              return group;
            }),
          },
        }
      );
    }

    case types.DELETE_EVENT_GROUP: {
      const { payload } = action;
      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            deleteEventGroup: payload.status,
          },
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            eventGroups: [
              ...state.hackathonSetup.eventGroups.filter(eventGroup => eventGroup.uuid !== payload.eventGroupUUID),
            ],
          },
        }
      );
    }

    case types.ADD_TRACK: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            addTrack: payload.status,
          },
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            tracks: [...state.hackathonSetup.tracks, payload.track],
          },
        }
      );
    }

    case types.UPDATE_TRACK: {
      const { payload } = action;

      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            updateTrack: payload.status,
          },
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            tracks: state.hackathonSetup.tracks.map(track => {
              if (track.uuid === payload.track.uuid) {
                return payload.track;
              }

              return track;
            }),
          },
        }
      );
    }

    case types.DELETE_TRACK: {
      const { payload } = action;
      return Object.assign(
        {
          ...state,
          status: {
            ...state.status,
            deleteTrack: payload.status,
          },
        },
        payload.status === STATUS.SUCCESS && {
          hackathonSetup: {
            ...state.hackathonSetup,
            tracks: [...state.hackathonSetup.tracks.filter(track => track.uuid !== payload.trackUUID)],
          },
        }
      );
    }

    case types.TOGGLE_WINNERS_PUBLISH_CHECK: {
      const { payload } = action;

      return {
        ...state,
        hasModifiedWinnersWithoutCheckingPublish: payload,
      };
    }

    case types.TOGGLE_WINNERS_ANNOUNCED: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathonSetup: {
            ...state.hackathonSetup,
            winnersAnnounced: payload.data,
          },
          status: {
            ...state.status,
            toggleWinnersAnnounced: payload.status,
          },
          // Whenever winners are published/unpublished, we need to reset the flag
          hasModifiedWinnersWithoutCheckingPublish: false,
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          toggleWinnersAnnounced: payload.status,
        },
      };
    }
    default:
      return state;
  }
};

export default organizer;
