const logger = console;
export const clearStorage = () => localStorage.clear();

export const getItemFromStorage = key => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (err) {
    logger.error(`Error getting item ${key} from localStorage`, err);
  }
  return null;
};

export const storeItem = (key, item) => {
  try {
    return localStorage.setItem(key, JSON.stringify(item));
  } catch (err) {
    logger.error(`Error storing item ${key} to localStorage`, err);
  }
  return null;
};

export const removeItemFromStorage = key => {
  try {
    return localStorage.removeItem(key);
  } catch (err) {
    logger.error(`Error removing item ${key} from localStorage`, err);
  }
  return null;
};
