import React from 'react';
import { Dialog, Paragraph as P } from '@devfolioco/genesis';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '@devfolioco/helpers';
import { deleteEvent } from '../../../actions/organizer';
import { ACTION_STATUS as STATUS } from '../../../constants';

const DeleteEventModal = ({ hackathonUUID, eventUUID, onClose }) => {
  const dispatch = useDispatch();
  const deleteEventStatus = useSelector(state => state.organizer.status.deleteEvent);
  const prevDeleteEventStatus = usePrevious(deleteEventStatus);

  React.useEffect(() => {
    if (deleteEventStatus !== prevDeleteEventStatus && deleteEventStatus === STATUS.SUCCESS) {
      onClose?.();
    }
  }, [onClose, prevDeleteEventStatus, deleteEventStatus]);

  const handleDeleteEvent = () => {
    dispatch(deleteEvent(hackathonUUID, eventUUID));
  };

  return (
    <Dialog
      title="Are you sure you want to remove this event?"
      primaryAction={{
        label: 'Delete',
        loading: deleteEventStatus === STATUS.REQUEST,
        onAction: handleDeleteEvent,
      }}
      destructive
      cancelAction={{ label: 'Cancel', onAction: onClose }}
      noCancel={false}
      onClose={onClose}
    >
      <P style={{ textAlign: 'center' }}>Clicking Delete will permanently delete this event.</P>
    </Dialog>
  );
};

export default DeleteEventModal;
