import React from 'react';
import { FixedSizeList as List } from 'react-window';

const height = 50;

export default class MenuList extends React.Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
  }

  componentDidUpdate() {
    const { children } = this.props;

    /**
     * enables scrolling on key down arrow
     *
     * note: prevents scrolling on index 0 and 1 to avoid
     * returning to top of menu when it remains open after selecting
     */
    const currentIndex = this.getFocusIndex(children);
    if (currentIndex > 1) {
      this.listRef.current.scrollToItem(currentIndex);
    }
  }

  getFocusIndex = children => {
    const isArray = children instanceof Array;
    children = isArray ? children : [children];
    return Math.max(
      children.findIndex(({ props: { isFocused } = {} } = {}) => {
        return isFocused === true;
      }),
      0
    );
  };

  render() {
    const { children, maxHeight } = this.props;

    return (
      <List
        ref={this.listRef}
        height={Math.min(maxHeight, (children.length || 1) * height)}
        itemCount={children.length}
        itemSize={height}
        overscanCount={5}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}
