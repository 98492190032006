import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';
import { mapToRootHackathon } from '../helpers';

const initialState = {
  hackathonData: {},
  tickets: [],
  ticketStats: {},
  status: {
    fetchHackathonData: STATUS.READY,
    fetchTicketsData: STATUS.READY,
    updateHackathonTicketInfo: STATUS.READY,
    uploadTicketImg: STATUS.READY,
    addTicket: STATUS.READY,
    deleteTicket: STATUS.READY,
    fetchTicketStats: STATUS.READY,
    refundTicket: STATUS.READY,
  },
  refundError: '',
};

const ticket = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_HACKATHON_DATA_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          fetchHackathonData: STATUS.FAILURE,
        },
      };
    }
    case types.FETCH_HACKATHON_DATA_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          fetchHackathonData: STATUS.REQUEST,
        },
      };
    }
    case types.FETCH_HACKATHON_DATA_SUCCESS: {
      return {
        ...state,
        hackathonData: mapToRootHackathon([action.payload])[0],
        status: {
          ...state.status,
          fetchHackathonData: STATUS.SUCCESS,
        },
      };
    }

    case types.FETCH_TICKETS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          tickets: payload.data,
          status: {
            ...state.status,
            fetchTicketsData: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchTicketsData: payload.status,
        },
      };
    }

    case types.UPDATE_HACKATHON_TICKET_INFO: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathonData: { ...state.hackathonData, ...payload.hackathonTicketObj },
          status: {
            ...state.status,
            updateHackathonTicketInfo: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateHackathonTicketInfo: payload.status,
        },
      };
    }

    case types.UPLOAD_TICKET_IMAGE: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathonData: { ...state.hackathonData, ticketImg: payload.ticketImg },
          status: {
            ...state.status,
            uploadTicketImg: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          uploadTicketImg: payload.status,
        },
      };
    }

    case types.ADD_TICKET: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          tickets: payload.tickets,
          status: {
            ...state.status,
            addTicket: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          addTicket: payload.status,
        },
      };
    }

    case types.DELETE_TICKET: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          tickets: state.tickets.filter(({ uuid }) => payload.ticketUUID !== uuid),
          status: {
            ...state.status,
            deleteTicket: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          deleteTicket: payload.status,
        },
      };
    }

    case types.FETCH_TICKETS_STATS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          ticketStats: payload.data,
          status: {
            ...state.status,
            fetchTicketStats: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchTicketStats: payload.status,
        },
      };
    }

    case types.REFUND_TICKETS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          ticketStats: payload.data,
          status: {
            ...state.status,
            refundTicket: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          refundTicket: payload.status,
        },
        refundError: payload?.error || '',
      };
    }

    default:
      return state;
  }
};

export default ticket;
