import React from 'react';
import { Column, Row } from 'hedron';

import Loader from '../Loader';

const Loading = React.memo(props => {
  if (props.isLoading) {
    if (props.timedOut) {
      return (
        <Row divisions={2} style={{ maxWidth: 1200, margin: '0 auto', marginTop: 24 }}>
          <Column xs={2} sm={2} md={1} lg={1} style={{ justifyContent: 'flex-end' }}>
            <div>Try again!</div>
          </Column>
        </Row>
      );
    }
    if (props.pastDelay) {
      return <Loader />;
    }
    return null;
  }
  if (props.error) {
    return (
      <Row divisions={2} style={{ maxWidth: 1200, margin: '0 auto', marginTop: 24 }}>
        <Column xs={2} sm={2} md={1} lg={1} style={{ justifyContent: 'flex-end' }}>
          <div>Error! Couldn&apos;t load</div>
        </Column>
      </Row>
    );
  }
  return null;
});

export default Loading;
