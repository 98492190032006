import camelCase from 'lodash/camelCase';
import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';

const initialState = {
  skills: {
    query: '',
    data: [],
    count: 0,
  },
  colleges: {
    query: '',
    data: [],
    count: 0,
    currentPage: 0,
  },
  hackathons: {
    query: '',
    data: [],
    count: 0,
    currentPage: 0,
  },
  projects: {
    query: '',
    data: [],
    count: 0,
    currentPage: 0,
  },
  hashtags: {
    query: '',
    data: [],
    count: 0,
    currentPage: 0,
  },
  companies: {
    query: '',
    data: [],
    count: 0,
    currentPage: 0,
    pages: 0,
  },
  ideas: {
    query: '',
    data: [],
    pages: 0,
    currentPage: 0,
  },
  userMailingList: {
    data: [],
  },
  mailingLists: {
    data: [],
    count: 0,
    currentPage: 0,
  },
  currentUser: null,
  sponsorPrizeTemplate: null,
  status: {
    fetchColleges: STATUS.READY,
    fetchHackathons: STATUS.READY,
    fetchSkills: STATUS.READY,
    fetchHashtags: STATUS.READY,
    fetchCompanies: STATUS.READY,
    sendEmails: STATUS.READY,
    fetchUserMailingList: STATUS.READY,
    updateMailingList: STATUS.READY,
    fetchMailingLists: STATUS.READY,
    updateHackathonDetail: STATUS.READY,
    fetchExtraFields: STATUS.READY,
    addExtraField: STATUS.READY,
    deleteExtraField: STATUS.READY,
    addCollege: STATUS.READY,
    fetchIdeas: STATUS.READY,
    updateIdea: STATUS.READY,
    deleteIdea: STATUS.READY,
    makeHackathonOfficialAndFeatured: STATUS.READY,
    makeHackathonFellowship: STATUS.READY,
    fetchEmailTemplates: STATUS.READY,
    updateEmailTemplates: STATUS.READY,
    fetchProjects: STATUS.READY,
    deleteProject: STATUS.READY,
    fetchAllUserInfo: STATUS.READY,
    togglePaidHackathon: STATUS.READY,
    fetchSponsorPrizeTemplate: STATUS.READY,
    updateSponsorPrizeTemplate: STATUS.READY,
    updateFeaturedImage: STATUS.READY,
    addHackathonEdition: STATUS.READY,
    deleteHackathonEdition: STATUS.READY,
    updateHackathonMailSubscription: STATUS.READY,
  },
  updateHackathonError: '',
  updatingHackathonUUID: null,
};

const root = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SKILLS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        const oldData = payload.query === state.skills.query ? [...state.skills.data] : [];
        return {
          ...state,
          skills: {
            ...state.skills,
            count: payload.count,
            data: [
              ...oldData,
              ...payload?.skills.map(skill => ({
                ...skill,
                verified: skill.status === 'verified',
                updateStatus: STATUS.READY,
                deleteStatus: STATUS.READY,
              })),
            ],
            query: payload.query,
          },
          status: {
            ...state.status,
            fetchSkills: payload.status,
          },
        };
      }

      return {
        ...state,
        skills: {
          ...state.skills,
          currentPage: payload?.currentPage || 0,
        },
        status: {
          ...state.status,
          fetchSkills: payload.status,
        },
      };
    }

    case types.FETCH_PROJECTS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        const oldData = payload.currentPage === 1 ? [] : [...state.projects.data];

        return {
          ...state,
          projects: {
            data: [...oldData, ...payload?.projects],
            query: payload?.query,
            currentPage: payload?.currentPage,
            count: payload?.count,
            pages: payload?.pages,
          },
          status: {
            ...state.status,
            fetchProjects: payload?.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchProjects: payload.status,
        },
      };
    }

    case types.DELETE_PROJECT: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          projects: {
            ...state.projects,
            data: state.projects.data.filter(({ uuid }) => payload.projectUUID !== uuid),
            count: state.projects.count - 1,
            pages: Math.ceil((state.projects.count - 1) / 5),
          },
          status: {
            ...state.status,
            deleteProject: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          deleteProject: payload.status,
        },
      };
    }

    case types.FETCH_COLLEGES: {
      const { payload } = action;
      const oldData = payload.query === state.colleges.query ? [...state.colleges.data] : [];

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          colleges: {
            ...state.colleges,
            count: payload.count,
            data: [
              ...oldData,
              ...payload?.colleges.map(college => ({
                ...college,
                updateStatus: STATUS.READY,
                deleteStatus: STATUS.READY,
              })),
            ],
            query: payload.query,
          },
          status: {
            ...state.status,
            fetchColleges: payload.status,
          },
        };
      }

      return {
        ...state,
        colleges: {
          ...state.colleges,
          currentPage: payload?.currentPage || 0,
        },
        status: {
          ...state.status,
          fetchColleges: payload.status,
        },
      };
    }

    case types.UPDATE_SKILL: {
      const { payload } = action;

      return {
        ...state,
        skills: {
          ...state.skills,
          data: state.skills.data.map(skill => {
            if (skill.uuid === payload.skill.uuid) {
              return {
                ...payload.skill,
                updateStatus: payload.status,
              };
            }

            return skill;
          }),
        },
      };
    }

    case types.DELETE_SKILL: {
      const { payload } = action;

      return {
        ...state,
        skills: {
          ...state.skills,
          data: state.skills.data.map(skill => {
            if (skill.uuid === payload.uuid) {
              if (payload.status === STATUS.SUCCESS) {
                return {
                  ...skill,

                  // Setting this as false because the behaviour of delete is similar.
                  verified: false,

                  deleteStatus: payload.status,
                };
              }

              return {
                ...skill,
                deleteStatus: payload.status,
              };
            }

            return skill;
          }),
        },
      };
    }

    case types.UPDATE_COLLEGE: {
      const { payload } = action;
      const isNewCollege = !state.colleges.data.find(({ uuid }) => uuid === payload.college.uuid);
      if (isNewCollege) {
        if (payload.status === STATUS.SUCCESS) {
          return {
            ...state,
            colleges: {
              ...state.colleges,
              data: [payload.college, ...state.colleges.data],
            },
            status: {
              ...state.status,
              addCollege: payload.status,
            },
          };
        }
        return {
          ...state,

          status: {
            ...state.status,
            addCollege: payload.status,
          },
        };
      }

      return {
        ...state,
        colleges: {
          ...state.colleges,
          data: state.colleges.data.map(college => {
            if (college.uuid === payload.college.uuid) {
              return {
                ...payload.college,
                updateStatus: payload.status,
              };
            }

            return college;
          }),
        },
      };
    }

    case types.DELETE_COLLEGE: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          colleges: {
            ...state.colleges,
            data: state.colleges.data.filter(college => college.uuid !== payload.uuid),
            count: state.colleges.count - 1,
          },
        };
      }

      return {
        ...state,
        colleges: {
          ...state.colleges,
          data: state.colleges.data.map(college => {
            if (college.uuid === payload.uuid) {
              return {
                ...college,
                deleteStatus: payload.status,
              };
            }

            return college;
          }),
        },
      };
    }

    case types.FETCH_HACKATHONS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        const oldData = payload.currentPage === 1 ? [] : [...state.hackathons.data];
        return {
          ...state,
          hackathons: {
            ...state.hackathons,
            count: payload.count,
            data: [
              ...oldData,
              ...payload?.hackathons.map(hackathon => ({
                ...hackathon,
                verifyStatus: STATUS.READY,
                privatizeStatus: STATUS.READY,
              })),
            ],
            currentPage: payload.currentPage,
            query: payload.query,
            pages: payload.pages,
          },
          status: {
            ...state.status,
            fetchHackathons: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchHackathons: payload.status,
        },
      };
    }

    case types.FETCH_COMPANIES: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        const oldData = payload.query === state.companies.query ? [...state.companies.data] : [];
        return {
          ...state,
          companies: {
            ...state.companies,
            count: payload.count,
            data: [...oldData, ...payload?.companies],
            query: payload.query,
            pages: payload.pages,
          },
          status: {
            ...state.status,
            fetchCompanies: payload.status,
          },
        };
      }
      return {
        ...state,
        companies: {
          ...state.companies,
          currentPage: payload?.currentPage || 0,
        },
        status: {
          ...state.status,
          fetchCompanies: payload.status,
        },
      };
    }

    case types.UPDATE_COMPANY_FAVICON: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          companies: {
            ...state.companies,
            data: state.companies.data.map(company => {
              if (company.uuid === payload.companyUUID) {
                return {
                  ...company,
                  favicon: payload.favicon,
                  updateStatus: payload.status,
                };
              }

              return company;
            }),
          },
        };
      }

      return {
        ...state,
        companies: {
          ...state.companies,
          data: state.companies.data.map(company => {
            if (company.uuid === payload.companyUUID) {
              return {
                ...company,
                updateStatus: payload.status,
              };
            }
            return company;
          }),
        },
      };
    }

    case types.FETCH_HASHTAGS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        const oldData = payload.query === state.hashtags.query ? [...state.hashtags.data] : [];
        return {
          ...state,
          hashtags: {
            ...state.hashtags,
            count: payload.count,
            data: [
              ...oldData,
              ...payload?.hashtags.map(hashtag => ({
                ...hashtag,
                updateStatus: STATUS.READY,
                deleteStatus: STATUS.READY,
              })),
            ],
            query: payload.query,
          },
          status: {
            ...state.status,
            fetchHashtags: payload.status,
          },
        };
      }
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          currentPage: payload?.currentPage || 0,
        },
        status: {
          ...state.status,
          fetchHashtags: payload.status,
        },
      };
    }

    case types.UPDATE_HASHTAG: {
      const { payload } = action;
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          data: state.hashtags.data.map(hashtag => {
            if (hashtag.uuid === payload.hashtag.uuid) {
              return {
                ...hashtag,
                ...payload.hashtag,
                updateStatus: payload.status,
              };
            }
            return hashtag;
          }),
        },
      };
    }

    case types.DELETE_HASHTAG: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hashtags: {
            ...state.hashtags,
            data: state.hashtags.data.filter(hashtag => hashtag.uuid !== payload.uuid),
          },
        };
      }
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          data: state.hashtags.data.map(hashtag => {
            if (hashtag.uuid === payload.uuid) {
              return {
                ...hashtag,
                deleteStatus: payload.status,
              };
            }
            return hashtag;
          }),
        },
      };
    }

    case types.VERIFY_HACKATHON: {
      const { payload } = action;

      return {
        ...state,
        hackathons: {
          ...state.hackathons,
          data: state.hackathons.data.map(hackathon => {
            if (hackathon.slug === payload.slug) {
              if (payload.status === STATUS.REQUEST) {
                hackathon.verified = payload.verified;
                hackathon.verifyStatus = STATUS.REQUEST;
              }

              return {
                ...hackathon,
                verifyStatus: payload.status,
              };
            }

            return hackathon;
          }),
        },
      };
    }

    case types.PRIVATIZE_HACKATHON: {
      const { payload } = action;

      return {
        ...state,
        hackathons: {
          ...state.hackathons,
          data: state.hackathons.data.map(hackathon => {
            if (hackathon.slug === payload.slug) {
              if (payload.status === STATUS.REQUEST) {
                hackathon.isPrivate = payload.isPrivate;
                hackathon.privatizeStatus = STATUS.REQUEST;
              }

              return {
                ...hackathon,
                privatizeStatus: payload.status,
              };
            }

            return hackathon;
          }),
        },
      };
    }

    case types.TOGGLE_PAID_HACKATHON: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathons: {
            ...state.hackathons,
            data: state.hackathons.data.map(hackathon => {
              if (hackathon.slug === payload.slug) {
                return {
                  ...hackathon,
                  paid: payload.paid,
                };
              }
              return hackathon;
            }),
          },
          status: {
            ...state.status,
            togglePaidHackathon: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          togglePaidHackathon: payload.status,
        },
      };
    }

    case types.UPDATE_HACKATHON_DETAIL: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathons: {
            ...state.hackathons,
            data: state.hackathons.data.map(hackathon => {
              if (hackathon.slug === payload.slug) {
                return {
                  ...hackathon,
                  ...payload.hackathon,
                };
              }
              return hackathon;
            }),
          },
          status: {
            ...state.status,
            updateHackathonDetail: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateHackathonDetail: payload.status,
        },
        updateHackathonError: payload.error || '',
      };
    }

    case types.MAKE_HACKATHON_OFFICIAL_AND_FEATURED: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathons: {
            ...state.hackathons,
            data: state.hackathons.data.map(hackathon => {
              if (hackathon.slug === payload.slug) {
                return {
                  ...hackathon,
                  devfolioOfficial: payload.devfolioOfficial,
                  featured: payload.featured,
                };
              }
              return hackathon;
            }),
          },
          status: {
            ...state.status,
            makeHackathonOfficialAndFeatured: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          makeHackathonOfficialAndFeatured: payload.status,
        },
      };
    }

    case types.MAKE_HACKATHON_FELLOWSHIP: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathons: {
            ...state.hackathons,
            data: state.hackathons.data.map(hackathon => {
              if (hackathon.slug === payload.hackathonSlug) {
                return {
                  ...hackathon,
                  fellowship: payload.fellowship,
                };
              }
              return hackathon;
            }),
          },
          status: {
            ...state.status,
            makeHackathonFellowship: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          makeHackathonFellowship: payload.status,
        },
      };
    }

    case types.FETCH_EXTRA_FIELDS: {
      const { payload } = action;
      const hackathonKey = !payload.isChecklist ? 'extraFields' : 'checklists';
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathons: {
            ...state.hackathons,
            data: state.hackathons.data.map(hackathon => {
              if (hackathon.slug === payload.slug) {
                return {
                  ...hackathon,
                  [hackathonKey]: payload.data,
                };
              }
              return hackathon;
            }),
          },
          status: {
            ...state.status,
            fetchExtraFields: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchExtraFields: payload.status,
        },
      };
    }

    case types.ADD_EXTRA_FIELD: {
      const { payload } = action;
      const hackathonKey = !payload.isChecklist ? 'extraFields' : 'checklists';
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathons: {
            ...state.hackathons,
            data: state.hackathons.data.map(hackathon => {
              if (hackathon.slug === payload.slug) {
                return {
                  ...hackathon,
                  [hackathonKey]: payload.data,
                };
              }
              return hackathon;
            }),
          },
          status: {
            ...state.status,
            addExtraField: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          addExtraField: payload.status,
        },
      };
    }

    case types.DELETE_EXTRA_FIELD: {
      const { payload } = action;
      const hackathonKey = !payload.isChecklist ? 'extraFields' : 'checklists';
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathons: {
            ...state.hackathons,
            data: state.hackathons.data.map(hackathon => {
              if (hackathon.slug === payload.slug) {
                return {
                  ...hackathon,
                  [hackathonKey]: hackathon[hackathonKey].filter(({ uuid }) => payload.extraFieldUUID !== uuid),
                };
              }
              return hackathon;
            }),
          },
          status: {
            ...state.status,
            deleteExtraField: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          deleteExtraField: payload.status,
        },
      };
    }

    case types.FETCH_EMAIL_TEMPLATES: {
      const { payload } = action;
      return {
        ...state,
        hackathons: {
          ...state.hackathons,
          data: state.hackathons?.data?.map(hackathon => {
            if (hackathon.uuid === payload.uuid) {
              return {
                ...hackathon,
                emailTemplates: payload.data,
              };
            }
            return hackathon;
          }),
        },
        status: {
          ...state.status,
          fetchEmailTemplates: payload.status,
        },
      };
    }

    case types.UPDATE_EMAIL_TEMPLATE: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathons: {
            ...state.hackathons,
            data: state.hackathons?.data?.map(hackathon => {
              if (hackathon.uuid === payload.uuid) {
                return {
                  ...hackathon,
                  emailTemplates: payload.data,
                };
              }
              return hackathon;
            }),
          },
          status: {
            ...state.status,
            updateEmailTemplates: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateEmailTemplates: payload.status,
        },
        updatingHackathonUUID: payload?.hackathonUUID || null,
      };
    }

    case types.ROOT_SEND_EMAILS:
      return {
        ...state,
        status: {
          ...state.status,
          sendEmails: action.payload.status,
        },
      };

    case types.FETCH_MAILING_LISTS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          mailingLists: {
            ...state.mailingLists,
            data: payload.data,
          },
          status: {
            ...state.status,
            fetchMailingLists: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchMailingLists: payload.status,
        },
      };
    }

    case types.LOGOUT_SUCCESS:
      return { ...initialState };

    case types.FETCH_IDEAS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          ideas: {
            data: payload.currentPage === 1 ? payload.data.result : [...state.ideas.data, ...payload.data.result],
            currentPage: payload.currentPage,
            query: payload.query,
            pages: payload.data.pages,
          },
          status: {
            ...state.status,
            fetchIdeas: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchIdeas: payload.status,
        },
      };
    }
    case types.UPDATE_IDEA: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          ideas: {
            ...state.ideas,
            data: state.ideas.data.map(currentIdea => {
              if (currentIdea.uuid === payload.idea.uuid) {
                return payload.idea;
              }
              return currentIdea;
            }),
          },
          status: {
            ...state.status,
            updateIdea: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateIdea: payload.status,
        },
      };
    }
    case types.DELETE_IDEA: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          ideas: {
            ...state.ideas,
            data: state.ideas.data.filter(currentIdea => currentIdea.uuid !== payload.ideaUUID),
          },
          status: {
            ...state.status,
            deleteIdea: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          deleteIdea: payload.status,
        },
      };
    }

    case types.FETCH_ALL_USER_INFO: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          currentUser: payload.user,
          status: {
            ...state.status,
            fetchAllUserInfo: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchAllUserInfo: payload.status,
        },
      };
    }

    case types.FETCH_SPONSOR_PRIZE_TEMPLATE: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          sponsorPrizeTemplate: { value: payload.sponsorPrizeTemplate, subject: payload.subject },
          status: {
            ...state.status,
            fetchSponsorPrizeTemplate: STATUS.SUCCESS,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchSponsorPrizeTemplate: payload.status,
        },
      };
    }

    case types.UPDATE_SPONSOR_PRIZE_TEMPLATE: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          sponsorPrizeTemplate: payload.sponsorPrizeTemplate,
          status: {
            ...state.status,
            updateSponsorPrizeTemplate: STATUS.SUCCESS,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          updateSponsorPrizeTemplate: payload.status,
        },
      };
    }

    case types.UPDATE_FEATURED_IMAGE: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathons: {
            ...state.hackathons,
            data: state.hackathons.data.map(hackathon => {
              if (hackathon.slug === payload.slug) {
                return {
                  ...hackathon,
                  featuredImage: payload.featuredImage,
                };
              }
              return hackathon;
            }),
          },
          status: {
            ...state.status,
            updateFeaturedImage: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateFeaturedImage: payload.status,
        },
      };
    }

    case types.ADD_HACKATHON_EDITION: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathons: {
            ...state.hackathons,
            data: state.hackathons.data.map(hackathon => {
              if (hackathon?.uuid === payload.hackathonEdition.hackathonUUID) {
                return {
                  ...hackathon,
                  hackathon_brand: { name: payload.hackathonBrand.name, uuid: payload.hackathonBrand.uuid },
                  edition: payload.hackathonEdition.editionValue,
                  editionName: payload.hackathonEdition.editionName,
                };
              }
              return hackathon;
            }),
          },
          status: {
            ...state.status,
            addHackathonEdition: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          addHackathonEdition: payload.status,
        },
      };
    }

    case types.DELETE_HACKATHON_EDITION: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathons: {
            ...state.hackathons,
            data: state.hackathons.data.map(hackathon => {
              if (hackathon?.uuid === payload.hackathonUUID) {
                return {
                  ...hackathon,
                  edition: null,
                  editionName: null,
                  hackathon_brand: null,
                };
              }
              return hackathon;
            }),
          },
          status: {
            ...state.status,
            deleteHackathonEdition: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          deleteHackathonEdition: payload.status,
        },
      };
    }

    case types.FETCH_USER_MAILING_LIST: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          userMailingList: {
            ...state.userMailingList,
            data: payload.data.filter(item => item.mailing_list.type === 'normal'),
          },
          status: {
            ...state.status,
            fetchUserMailingList: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchUserMailingList: payload.status,
        },
      };
    }

    case types.UPDATE_MAILING_LIST: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          userMailingList: {
            ...state.userMailingList,
            data: state.userMailingList.data.map(item => {
              if (item.uuid === payload.data.mailingListID) {
                return {
                  ...item,
                  subscribed: payload.data.subscribed,
                };
              }
              return item;
            }),
          },
          status: {
            ...state.status,
            updateMailingList: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateMailingList: payload.status,
        },
      };
    }

    case types.UPDATE_PARTICIPANT_STATUS_SUCCESS: {
      const { payload } = action;
      if (payload.view === 'individual') {
        return {
          ...state,
          currentUser: state.currentUser
            ? {
                ...state.currentUser,
                hackathons: state.currentUser?.hackathons?.map(hackathon => {
                  if (hackathon.uuid === payload.hackathonID) {
                    return {
                      ...hackathon,
                      status: payload.status,
                    };
                  }
                  return hackathon;
                }),
              }
            : null,
        };
      }
      return state;
    }

    case types.UPDATE_HACKATHON_MAIL_SUBSCRIPTIONS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          currentUser: {
            ...state.currentUser,
            hackathons: state.currentUser?.hackathons?.map(hackathon => {
              if (hackathon.slug === payload.hackathonUUID) {
                return {
                  ...hackathon,
                  mailingLists: {
                    ...hackathon.mailingLists,
                    ...Object.keys(payload.subscriptionData).reduce((acc, key) => {
                      return {
                        ...acc,
                        [camelCase(key)]: payload.subscriptionData[key],
                      };
                    }, {}),
                  },
                };
              }
              return hackathon;
            }),
          },
          status: {
            ...state.status,
            updateHackathonMailSubscription: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateHackathonMailSubscription: payload.status,
        },
      };
    }

    case types.UPDATE_USER_BAN_STATUS: {
      const { payload } = action;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          status: payload.user.status,
        },
      };
    }
    default:
      return state;
  }
};

export default root;
