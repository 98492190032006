/* eslint-disable max-len */
export const ERRORS = {
  /**
   * These are the error messages we display on the client side
   */
  noSocialLinks: 'At least one social link is required',
  dateInvalid: "Please make sure you're entering a valid date.",
  emailDirty: "This doesn't appear to be a valid email address. Please try a different email address.",
  emailDisposable: "We don't allow signup via disposable email service. Please try a different email address.",
  emailDuplicate: 'This email address is already linked to a different account.',
  emailEmpty: "The email address can't be left empty.",
  emailInvalid: 'Please enter a valid email address. For eg. example@email.com',
  emailNotFound: "Sorry, we couldn't find an account with that email address.",
  emailUnavailable: 'Someone (maybe you?) has already created an account using this email.',
  emailUnverified: "You haven't verified your email yet!",
  emailVerificationFailed: "Sorry, we couldn't verify your email address. Please try again.",
  fieldRequired: 'This field is required.',
  firstNameEmpty: "First name can't be left blank.",
  googleAuthenticationError: "Sorry, we couldn't connect with Google. Please try again.",
  invalidURL: 'Please enter a valid URL.',
  passwordIncorrect: "Sorry, that password isn't right.",
  passwordRateLimitExceeded: 'The maximum number of login attempts has been reached. Please try again in an hour.',
  loginFailed:
    "Sorry, we're unable to login you right now. If the problem persists, please drop us an email at help@devfolio.co.",
  passwordResetFailed:
    "Sorry, we couldn't reset password. We're looking into this, right away! Please try again later.",
  usernameNotFound: "Sorry, we couldn't find an account with that username.",
  userNotOrganizer: 'Sorry, cannot sign in. You are not an organizer.',
  invalidDate: 'Please enter a valid date',
  hackathonAlreadyExists: 'This hackathon name already exists.',
  csvNotAvailableOnline: 'No hackers have made a project submission yet at your hackathon.',
  csvNotAvailableOffline: 'No hackers have RSVPd yet to attend your hackathon. Please wait for them to do so.',
  teamJoiningErrors: {
    /* These are the errors which would be shown to the user when the team and hacker are in different states.
    The first status denotes the status of the hacker and the second status is the status of the team */
    'null submit, null accept, null waitlist, null reject, null waitlist_sent':
      'This team is already being reviewed. Please join a different team or submit your individual profile for review.',
    'null accept_sent, null rsvp, null check_in, null reimburse':
      'This team’s already been accepted. Only accepted hackers can join accepted teams. Please join a different team or submit your individual profile for review',
    'null reject_sent, reimburse reject_sent':
      'Oh darn! This team didn’t make the cut. You can join a different team or submit your individual profile for review.',
    'rsvp null, check_in null, reimburse null':
      'This team has not been submitted for review. Accepted hackers (you) can join only accepted teams.',
    'rsvp submit, rsvp accept, rsvp waitlist, rsvp reject, check_in submit, check_in accept, check_in waitlist, check_in reject, reimburse submit, reimburse accept, reimburse waitlist, reimburse reject':
      'This team is still being reviewed. Accepted hackers (you) can join only accepted teams.',
    'rsvp accept_sent':
      'This team hasn’t confirmed their presence yet. You can join a different team or ask the members of this team to RSVP first.',
    'rsvp reject_sent, check_in reject_sent':
      'Oh darn! This team didn’t make the cut. Please try joining a different team.',
    'rsvp check_in, rsvp reimburse': 'Please check in at the venue before joining this team.',
    'check_in accept_sent, check_in rsvp, reimburse accept_sent, reimburse rsvp':
      'Please wait till all the members of this team have checked in at the venue.',
  },
  addSquadMemberErrors: {
    MAX_ORGANIZER_LIMIT_REACHED: 'You have added the maximum number of organizers.',
  },
  judgeErrors: {
    judgeAlreadyExists: 'A judge with that email already exists.',
    userHackathonAlreadyExistsErrors: {
      organizer:
        'The user associated with this email is an organizer of this hackathon. Please use another email to access judging',
      hacker:
        'The user associated with this email is participating as a hacker in this hackathon. Please use another email to access judging',
    },
  },
};

export const SERVER_ERRORS = {
  /**
   * These are the error messages the server could throw. We check them, and
   * display the client ERROR accordingly.
   */
  accessTokenRequired: 'access_token is required',
  accountNotFound: 'Account with this email or username does not exists',
  emailDuplicate: 'email already in use',
  passwordInvalid: 'Invalid Password',
  tokenExpired: 'Token expired',
  tokenInvalid: 'invalid token',
  unprocessableRequest: 'The server received a request that could not be processed.',
  usernameInvalid: 'Invalid username',
  userNotFound: 'user not found',
  passwordRateLimitExceeded: 'Login attempts over',
  hackathonAlreadyExists: 'hackathon name already exists.',
  csvNotAvailable: 'No participants data found',
  judgeAlreadyExists: 'Judge with email already exists',
  judgeAlreadyPresentInARoom: 'Judge already present in room',
  judgeUserHackathonAlreadyExists: 'USER_HACKATHON_ALREADY_EXISTS',
  accountAlreadyConnected: 'ACCOUNT_ALREADY_CONNECTED',
};
