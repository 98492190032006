import { combineReducers } from 'redux';
import { searchReducer } from '../helpers/searchFactory';

export const allergiesSearch = combineReducers({
  search: searchReducer('allergies'),
});

export const checkedInParticipantsSearch = combineReducers({
  search: searchReducer('checkedInParticipants'),
});

export const underageParticipantsSearch = combineReducers({
  search: searchReducer('underageParticipants'),
});

export const individualsSearch = combineReducers({
  search: searchReducer('individuals'),
});

export const teamsSearch = combineReducers({
  search: searchReducer('teams'),
});

export const allParticipantsSearch = combineReducers({
  search: searchReducer('allParticipants'),
});

export const unreviewedParticipantsSearch = combineReducers({
  search: searchReducer('unreviewedParticipants'),
});

export const allUsersSearch = combineReducers({
  search: searchReducer('allUsers'),
});
