import moment from 'moment';

export const getAge = date => {
  return moment().diff(date, 'years');
};

export const dateFormat = date => {
  return moment(date).format('DD-MM-YYYY');
};

export const isBetween = (startDate, endDate, date) => {
  return moment(date).isBetween(startDate, endDate);
};

export const getFormattedEventTime = (startsAt?: string, endsAt?: string): string => {
  if (typeof startsAt !== 'string' || typeof endsAt !== 'string') {
    return '';
  }

  const startDate = moment(startsAt, moment.ISO_8601);
  const endDate = moment(endsAt, moment.ISO_8601);

  /**
   * If the event start time and and end time are in the same part of the day
   * 4 PM - 6 PM   //true
   * 5 AM - 6 AM   //true
   * 4 AM - 6 PM   //false
   */
  const isSamePartOfTheDay = startDate.format('a') === endDate.format('a');

  // If the event start time and and end time are the same hour
  const isSameHour = startDate.format('hm') === endDate.format('hm');

  // If the time is 8:00 AM, we don't need to show the minutes (00).
  // If it is something like 1:26 PM, we do need to show the minutes.
  const hourFormatString = (date: moment.Moment) => (date.format('mm') === '00' ? 'h' : 'h:mm');

  return `${startDate.format(
    isSamePartOfTheDay ? hourFormatString(startDate) : `${hourFormatString(startDate)} a`
  )}${endDate.format(isSameHour ? 'a' : ` - ${hourFormatString(endDate)} a`)}`;
};

export const getFormattedEventDate = (startsAt?: string, endsAt?: string): string => {
  if (typeof startsAt !== 'string') {
    return '';
  }

  const startDate = moment(startsAt, moment.ISO_8601);

  // If end date is not defined
  if (!endsAt || typeof endsAt !== 'string') {
    return `${startDate.format('MMM D, y')}`;
  }

  const endDate = moment(endsAt, moment.ISO_8601);

  // If the event start year and event end year date are different show
  // the year and months like so: Dec 30, 2020 - Jan 2, 2021
  if (startDate.format('y') !== endDate.format('y')) {
    return `${startDate.format('MMM D, y')} - ${endDate.format('MMM D, y')} `;
  }

  // If the event start month and event end month date are different show
  // the year and months like so: Mar 30 - Apr 1, 2021
  if (startDate.format('MM') !== endDate.format('MM')) {
    return `${startDate.format('MMM D')} - ${endDate.format('MMM D, y')}`;
  }

  // Else, for same month, different day display it like: Apr 2-4, 2021
  // For same month, same day display it like: Apr 2, 2021
  const isSameDay = startDate.format('D') === endDate.format('D');
  return `${startDate.format('MMM D')}${endDate.format(isSameDay ? ', y' : ' - D, y')}`;
};

export const doesEventFinishOnSameDay = (startsAt?: string, endsAt?: string): boolean => {
  if (typeof startsAt !== 'string' || typeof endsAt !== 'string') {
    return false;
  }
  const startDate = moment(startsAt, moment.ISO_8601);
  const endDate = moment(endsAt, moment.ISO_8601);
  return startDate.format('DD/MM/yyyy') === endDate.format('DD/MM/yyyy');
};
