// import { NODE_ENV } from 'constants/environment';

// Define the app wide settings

// Default page items limit
export const PAGE_LIMIT = 200;

// Default sort option
export const SORT = 'ASC';

// Wait time for debounce fn (in ms)
export const WAIT_TIME = 400;

// Wait time for debounce fn (in ms)
export const SEARCH_WAIT_TIME = 600;

// Value for the underage search filter
export const UNDERAGE = 18;

// Miscellaneous
export const MISC = {
  instantSearch: false,
};
