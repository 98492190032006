import React from 'react';
import styled from 'styled-components';
import { Card, Text, Markdown, Pill, PillProps, Button, IconButton } from '@devfolioco/genesis';
import { getFormattedEventTime, getFormattedEventDate, doesEventFinishOnSameDay } from '@helpers/date';

const getSpeakerConjunction = (totalSpeakers, currentIndex) => {
  if (currentIndex === totalSpeakers - 1) {
    return '';
  }
  switch (totalSpeakers) {
    case 1:
      return '';
    case 2:
      return ' & ';
    default:
      return ', ';
  }
};

const infoPillStyle: PillProps = {
  background: 'grey-1',
  color: 'grey-7',
  size: 'medium',
};

export const Hr = styled.hr`
  margin-top: 32px;
  margin-bottom: 16px;
  border: none;
  border-top: 1px solid #e0e4e5;
`;

const EventCard = ({ event, onUpdateClick, onDeleteClick }) => {
  const { title, description, type, speakers, startsAt, endsAt } = event;
  return (
    <Card
      id={`event-${event.uuid}`}
      style={{ scrollMarginTop: '200px', transition: '200ms all ease-in', width: '100%' }}
    >
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '24px', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Text size={24} weight="bold" upgrade>
              {title}
            </Text>
            {Array.isArray(speakers) && speakers.length > 0 ? (
              <Text>
                By{' '}
                {speakers.map((speaker, index) => {
                  return (
                    <React.Fragment key={`${speaker.title}${speaker.uuid}`}>
                      <a href={speaker.twitter || speaker.instagram || speaker.linkedin || speaker.profile}>
                        {speaker.name}
                      </a>
                      <span>{getSpeakerConjunction(speakers.length, index)}</span>
                    </React.Fragment>
                  );
                })}
              </Text>
            ) : (
              <Text size={17} color="grey-5" upgrade>
                No speakers added yet
              </Text>
            )}
            <div style={{ marginTop: '8px' }}>
              <Markdown>{description}</Markdown>
            </div>
            <div style={{ display: 'flex', gap: '8px', marginTop: '8px', flexWrap: 'wrap' }}>
              <Pill {...infoPillStyle} label={type} />
              <Pill {...infoPillStyle} icon="calendar" label={getFormattedEventDate(startsAt, endsAt)} />
              {doesEventFinishOnSameDay(startsAt, endsAt) && (
                <Pill {...infoPillStyle} icon="clock" label={getFormattedEventTime(startsAt, endsAt)} />
              )}
            </div>
          </div>
        </div>

        <div>
          <Hr />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton color="red-4" icon="trash" size="medium" onClick={onDeleteClick} />
            <Button appearance="secondary" onClick={onUpdateClick} size="medium">
              Update
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EventCard;
