import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { Text } from '@devfolioco/genesis';
import { getGroupedEvents, getHackathonMicrosite } from '@helpers/index';
import { ScheduleManager } from '@components/ScheduleManager/index';
import { useDispatch } from 'react-redux';
import { initializeEventGroup } from '@actions/organizer';
import { AnyAction } from 'redux';
import AddEventModal from './AddEventModal';
import DeleteEventModal from './DeleteEventModal';
import { Hackathon_Event_Type_Enum } from '../../../interfaces/graphql';

export interface Event {
  uuid: string;
  title: string;
  description: string;
  url: string;
  meetingUrl: string;
  location: string;
  googleMapsLocationURL: string;
  type: string;
  startsAt: string;
  endsAt: string;
  speakers: {
    label: string;
    value: string;
  }[];
  groupUUID?: string;
}

export interface EventGroupI {
  uuid: string;
  name: string;
  order: number;
}

const EMPTY_EVENT: Event = {
  uuid: '',
  title: '',
  description: '',
  url: '',
  meetingUrl: '',
  type: '',
  startsAt: '',
  endsAt: '',
  googleMapsLocationURL: '',
  location: '',
  speakers: [],
};

const HACKATHON_EVENT_TYPES: Array<keyof typeof Hackathon_Event_Type_Enum> = [
  'None',
  'Talk',
  'Workshop',
  'Panel',
  'Discussion',
  'Brainstorming',
];

export const EVENT_TYPES = HACKATHON_EVENT_TYPES.map(type => ({
  label: type,
  value: type,
}));

const Events = ({
  events: { allEvents, eventGroups },
  hackathonUuid,
  formikValues: inheritedFormikValues,
  hackathonSlug,
}): JSX.Element => {
  const dispatch = useDispatch();

  const groupedEvents = getGroupedEvents(allEvents, eventGroups);
  const { timezone } = inheritedFormikValues;
  const validTimezone = timezone || moment.tz.guess();

  const DEFAULT_EVENT = {
    ...EMPTY_EVENT,
    type: EVENT_TYPES[0].value,
  };

  const [addEventModalOpen, setAddEventModalOpen] = React.useState(false);
  const [deleteEventModalOpen, setDeleteEventModalOpen] = React.useState(false);
  const [currentEvent, setCurrentEvent] = React.useState(DEFAULT_EVENT);

  useEffect(() => {
    if (allEvents.length === 0 && eventGroups.length === 0) {
      // initalize new empty layout
      dispatch((initializeEventGroup(hackathonUuid) as unknown) as AnyAction);
    }
  }, [allEvents.length, dispatch, eventGroups.length, hackathonUuid]);

  const onEventUpdateClick = uuid => {
    const eventClicked = allEvents.find(event => event.uuid === uuid);
    if (!eventClicked) return;

    if (typeof eventClicked !== 'undefined') {
      // Transform the event to match the formik values
      const transformedEvent = {
        ...eventClicked,
        groupUUID: eventClicked.groupUUID ?? 'default',
        speakers: eventClicked.speakers.map(speaker => ({
          label: speaker.name,
          value: speaker.uuid,
        })),
      };

      // Set the current event
      setCurrentEvent(transformedEvent);

      // Open the modal to update the event
      setAddEventModalOpen(true);
    }
  };

  const onEventDeleteClick = uuid => {
    // Get the event that was clicked
    const eventClicked = allEvents.find(event => event.uuid === uuid);

    if (!eventClicked) return;

    // Set the current event
    setCurrentEvent(eventClicked);

    // Open the modal to confirm deleting the event
    setDeleteEventModalOpen(true);
  };

  const handleAddClick = groupUUID => {
    setCurrentEvent({ ...DEFAULT_EVENT, groupUUID });
    setAddEventModalOpen(true);
  };

  return (
    <React.Fragment>
      <Text
        upgrade
        size={20}
        weight="regular"
        color="grey-7"
        style={{ maxWidth: '75%', display: 'block', marginBottom: '48px' }}
      >
        The events or workshops you add in the schedule will be visible on the{' '}
        <a href={`${getHackathonMicrosite(hackathonSlug)}/schedule`} target="_blank" rel="noreferrer">
          microsite
        </a>
        . Different columns here will be visible as different tabs on the schedule.
      </Text>

      <ScheduleManager
        hackathonUUID={hackathonUuid}
        groupedEvents={groupedEvents}
        onEventUpdateButtonClick={onEventUpdateClick}
        onEventDeleteButtonClick={onEventDeleteClick}
        onEventCreateButtonClick={handleAddClick}
      />

      {addEventModalOpen && (
        <AddEventModal
          event={currentEvent}
          eventGroups={eventGroups}
          hackathonUUID={hackathonUuid}
          timezone={validTimezone}
          onClose={() => setAddEventModalOpen(false)}
        />
      )}

      {deleteEventModalOpen && (
        <DeleteEventModal
          hackathonUUID={hackathonUuid}
          eventUUID={currentEvent.uuid}
          onClose={() => setDeleteEventModalOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

export default Events;
