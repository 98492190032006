import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Icon, IconButton, Text } from '@devfolioco/genesis';
import { useDispatch, useSelector } from 'react-redux';
import { addEventGroup, deleteEventGroup, updateEventGroup } from '@actions/organizer';
import { AnyAction } from 'redux';
import { OrganizerStoreI } from 'reducers/organizer';
import { ACTION_STATUS } from '@constants/index';
import EventCard from '../../views/HackathonSetup/components/Event';
import * as S from './ScheduleManager.style';

export interface GroupedEventI {
  uuid: string;
  name: string;
  order: number;
  events: {
    uuid: string;
    title: string;
    description: string;
    url: string;
    meetingUrl: string;
    type: string;
    startsAt: string;
    endsAt: string;
    speakers: {
      name: string;
      uuid: string;
      twitter?: string;
      instagram?: string;
      linkedin?: string;
      profile?: string;
    }[];
    groupUUID: number;
  }[];
}

interface ScheduleManagerProps {
  hackathonUUID: string;
  groupedEvents: GroupedEventI[];
  onEventUpdateButtonClick: (eventUUID: string) => void;
  onEventDeleteButtonClick: (eventUUID: string) => void;
  onEventCreateButtonClick: (groupUUID: string) => void;
}

export const ScheduleManager = ({
  hackathonUUID,
  groupedEvents,
  onEventUpdateButtonClick,
  onEventDeleteButtonClick,
  onEventCreateButtonClick,
}: ScheduleManagerProps) => {
  const dispatch = useDispatch();
  const addEventGroupStatus = useSelector(
    (state: { organizer: OrganizerStoreI }) => state.organizer.status.addEventGroup
  );

  const addEventStatus = useSelector((state: { organizer: OrganizerStoreI }) => state.organizer.status.addEvent);
  const updateEventStatus = useSelector((state: { organizer: OrganizerStoreI }) => state.organizer.status.updateEvent);

  const lastEventCreatedUUID = useSelector(
    (state: { organizer: OrganizerStoreI }) => state.organizer.lastEventCreatedUUID
  );

  const lastEventUpdatedUUID = useSelector(
    (state: { organizer: OrganizerStoreI }) => state.organizer.lastEventUpdatedUUID
  );

  const containerRef = useRef<HTMLDivElement | null>(null);

  /**
   * This handles micro interactions post event creation
   */

  const scrollToEvent = (eventUUID: string) => {
    const eventElement = document.getElementById(`event-${eventUUID}`);

    if (eventElement) {
      // show a micro-animation to hint the newly created event
      eventElement.style.opacity = '0.5';
      eventElement.style.scale = '0.8';

      // scroll the new event card in to the view
      eventElement.scrollIntoView({
        behavior: 'smooth',
      });

      setTimeout(() => {
        eventElement.style.opacity = '1';
        eventElement.style.scale = '1';
      }, 200);
    }
  };

  // scroll to event when event is created or updated
  useLayoutEffect(() => {
    if (addEventStatus === ACTION_STATUS.SUCCESS && lastEventCreatedUUID) {
      // new event created, scroll to the created event
      scrollToEvent(lastEventCreatedUUID);
    }

    if (updateEventStatus === ACTION_STATUS.SUCCESS && lastEventUpdatedUUID) {
      // event updated, scroll to the updated event
      scrollToEvent(lastEventUpdatedUUID);
    }
  }, [addEventStatus, lastEventCreatedUUID, updateEventStatus, lastEventUpdatedUUID]);

  useEffect(() => {
    if (addEventGroupStatus === ACTION_STATUS.SUCCESS && containerRef.current) {
      // new event group created, scroll to the create group button
      containerRef.current.scrollLeft = containerRef.current?.scrollWidth;
    }
  }, [addEventGroupStatus]);

  const updateEventGroupHandler = (uuid: string, updatedValues: { name: string }) => {
    dispatch(
      (updateEventGroup(hackathonUUID, {
        uuid,
        ...updatedValues,
      }) as unknown) as AnyAction
    );
  };

  const createEventGroupHandler = () => {
    const newGroupCount =
      groupedEvents.filter(grp => {
        const name = grp.name.split(' ');
        return name[0] === 'New' && name[1] === 'Group';
      }).length + 1;

    dispatch(
      (addEventGroup(hackathonUUID, {
        name: `New Group ${newGroupCount}`,
        order: (groupedEvents[groupedEvents.length - 1].order ?? 0) + 1,
      }) as unknown) as AnyAction
    );
  };

  const deleteEventGroupHandler = (eventGroupUUID: string) => {
    if (groupedEvents.length <= 1) {
      // Minimum 1 group is required
      return;
    }
    dispatch((deleteEventGroup(hackathonUUID, eventGroupUUID) as unknown) as AnyAction);
  };

  // update html style
  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    const oldStyle = html.style.scrollSnapType;
    html.style.scrollSnapType = 'y proximity';

    return () => {
      html.style.scrollSnapType = oldStyle;
    };
  }, []);

  /**
   * Adjust the board layout dimensions, position.
   * This is required because by default our view is render inside a limited space
   * container and we want the schedule board to be visible in full width
   * for using max available space.
   */
  useEffect(() => {
    const adjustBoardLayout = () => {
      if (!containerRef.current) return;

      const { parentElement } = containerRef.current;
      if (!parentElement) return;

      const { width: containerWidth } = parentElement.getBoundingClientRect();
      const { width: bodyWidth } = document.getElementsByTagName('body')[0]?.getBoundingClientRect() ?? 0;

      const autoMargin = (bodyWidth - containerWidth) / 2;
      containerRef.current.style.position = `relative`;
      containerRef.current.style.left = `-${autoMargin}px`;
      containerRef.current.style.width = `100vw`;
      containerRef.current.style.paddingLeft = `${autoMargin}px`;
    };

    adjustBoardLayout();

    window.addEventListener('resize', adjustBoardLayout);

    return () => {
      window.removeEventListener('resize', adjustBoardLayout);
    };
  }, []);

  return (
    <S.BoardWrapper ref={containerRef}>
      <S.BoardContainer cols={groupedEvents.length + 1}>
        {groupedEvents.map(group => {
          return (
            <BoardColumn
              key={group.uuid}
              group={group}
              onEventCreate={() => onEventCreateButtonClick(group.uuid)}
              onEventDelete={() => deleteEventGroupHandler(group.uuid)}
              onEventUpdate={updatedFields => updateEventGroupHandler(group.uuid, updatedFields)}
            />
          );
        })}

        <S.BoardColumn>
          <S.BoardColumnAddButton onClick={createEventGroupHandler}>
            <Icon color="grey-7" name="plus" size="medium" />
          </S.BoardColumnAddButton>
        </S.BoardColumn>
      </S.BoardContainer>
      <S.BoardContainer cols={groupedEvents.length + 1}>
        {groupedEvents.map(group => {
          return (
            <S.BoardColumn key={group.uuid} padding="32px 0 0 0" data-id={group.uuid}>
              <S.BoardColumnBody>
                {group.events.length === 0 && (
                  <S.AddEventCard onClick={() => onEventCreateButtonClick(group.uuid)} role="button">
                    <Icon color="grey-7" name="plus" size="medium" />
                    <Text size={18} weight="medium" upgrade color="grey-7">
                      Add an event or workshop
                    </Text>
                  </S.AddEventCard>
                )}
                {group.events.map(event => (
                  <EventCard
                    key={event.uuid ?? event.title}
                    event={event}
                    onUpdateClick={() => onEventUpdateButtonClick(event.uuid)}
                    onDeleteClick={() => onEventDeleteButtonClick(event.uuid)}
                  />
                ))}
              </S.BoardColumnBody>
            </S.BoardColumn>
          );
        })}
        <S.BoardColumn />
      </S.BoardContainer>
    </S.BoardWrapper>
  );
};

interface BoardColumnPropsI {
  group: GroupedEventI;
  onEventCreate: () => void;
  onEventDelete: () => void;
  onEventUpdate: (updatedField: { name: string }) => void;
}

const BoardColumn = ({ group, onEventCreate, onEventUpdate, onEventDelete }: BoardColumnPropsI) => {
  const [isEditable, setEditable] = useState(false);
  const [eventGroupName, setEventGroupName] = useState(group.name);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const saveHandler = useCallback(() => {
    setEditable(false);
    onEventUpdate({ name: eventGroupName });
  }, [eventGroupName, onEventUpdate]);

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
    setEventGroupName(e.target.value);
  };

  useEffect(() => {
    if (isEditable) inputRef.current?.focus();
  }, [isEditable]);

  return (
    <S.BoardColumn key={group.uuid}>
      <S.BoardColumnHeader>
        <S.BoardHeaderEditableField
          ref={inputRef}
          value={eventGroupName}
          onChange={changeHandler}
          placeholder="Group Name *"
          style={{ flexGrow: 1 }}
          disabled={!isEditable}
        />

        {!isEditable ? (
          <IconButton icon="edit" color="white-0" background="grey-6" size="small" onClick={() => setEditable(true)} />
        ) : (
          <IconButton icon="check" color="white-0" background="grey-6" onClick={saveHandler} />
        )}

        {/* Delete icon when group empty */}
        {group.events.length === 0 ? (
          <IconButton icon="trash" color="white-0" background="grey-6" onClick={() => onEventDelete()} />
        ) : (
          <IconButton icon="plus" color="white-0" background="grey-6" onClick={() => onEventCreate()} />
        )}
      </S.BoardColumnHeader>
    </S.BoardColumn>
  );
};
