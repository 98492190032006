import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';

import { ACTION_STATUS as STATUS } from '../constants';
import { getParticipantObject } from '../helpers';
import * as types from '../constants/actions';

const initialState = {
  allIndividuals: {},
  allTeams: {},
  currentPage: 0,
  currentParticipant: {},
  currentParticipantPastStats: {},
  currentTeam: {},
  currentParticipantOrTeamReviewHistory: [],
  individualCount: 0,
  individuals: [],
  pageTotal: 0,
  query: {},
  teamCount: 0,
  teams: [],
  status: {
    fetchParticipantInfo: STATUS.READY,
    fetchTeamInfo: STATUS.READY,
    sendEmails: STATUS.READY,
    updateParticipantStatus: STATUS.READY,
    bulkUpdateParticipantActionStatus: STATUS.READY,
    currentParticipantPastStatsStatus: STATUS.READY,
    currentParticipantOrTeamReviewHistoryStatus: STATUS.READY,
  },
};

const participant = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_ALL_TEAMS: {
      const { page, result } = action.payload;
      return {
        ...state,
        currentPage: page,
        teams: result,
        allTeams: {
          ...state.allTeams,
          [page]: result,
        },
      };
    }

    case types.UPDATE_ALL_INDIVIDUALS: {
      const { page, result } = action.payload;
      return {
        ...state,
        currentPage: page,
        individuals: result,
        allIndividuals: {
          ...state.allIndividuals,
          [page]: result,
        },
      };
    }

    case types.UPDATE_INDIVIDUALS: {
      return {
        ...state,
        individuals: action.payload.individuals,
        currentPage: action.payload.page || state.page,
      };
    }

    case types.UPDATE_TEAMS: {
      return {
        ...state,
        teams: action.payload.team,
        currentPage: action.payload.page || state.page,
      };
    }

    case types.FETCH_TEAMS_REQUEST:
    case types.FETCH_PARTICIPANTS_REQUEST: {
      const { page } = action.payload;
      return {
        ...state,
        currentPage: Number.parseInt(page, 10),
        currentParticipant: {},
        currentTeam: {},
        query: action.payload,
      };
    }

    case types.FETCH_PARTICIPANTS_SUCCESS: {
      const { count, pages, result } = action.payload;
      return {
        ...state,
        individualCount: count,
        individuals: result,
        pageTotal: pages,
        allIndividuals: {
          [state.currentPage]: result,
        },
      };
    }

    case types.FETCH_TEAMS_SUCCESS: {
      const { count, pages, result } = action.payload;
      return {
        ...state,
        pageTotal: pages,
        teamCount: count,
        teams: result,
        allTeams: {
          [state.currentPage]: result,
        },
      };
    }

    case types.FETCH_PARTICIPANT_INFO_REQUEST: {
      return {
        ...state,
        currentParticipant: {},
        currentTeam: {},
        status: {
          ...state.status,
          fetchParticipantInfo: STATUS.REQUEST,
        },
      };
    }

    case types.FETCH_PARTICIPANT_INFO_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          fetchParticipantInfo: STATUS.FAILURE,
        },
      };
    }

    case types.FETCH_PARTICIPANT_INFO_SUCCESS: {
      return {
        ...state,
        currentParticipant: action.payload,
        status: {
          ...state.status,
          fetchParticipantInfo: STATUS.SUCCESS,
        },
      };
    }

    case types.FETCH_TEAM_INFO: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          currentTeam: {
            ...initialState.currentTeam,
            ...payload.team,
            members: payload.members,
          },
          status: {
            ...state.status,
            fetchTeamInfo: payload.status,
          },
        };
      }

      if (payload.status === STATUS.REQUEST) {
        return {
          ...state,
          currentTeam: {
            ...payload.team,
            members: initialState.members,
          },
          status: {
            ...state.status,
            fetchTeamInfo: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchTeamInfo: payload.status,
        },
      };
    }

    case types.UPDATE_CURRENT_PARTICIPANT: {
      return {
        ...state,
        currentParticipant: getParticipantObject(state.currentTeam.members[action.payload]),
      };
    }

    case types.UPDATE_PARTICIPANT_STATUS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          updateParticipantStatus: STATUS.REQUEST,
        },
      };
    }
    case types.UPDATE_PARTICIPANT_STATUS_SUCCESS: {
      if (action.payload.view === 'team') {
        return {
          ...state,
          status: {
            ...state.status,
            updateParticipantStatus: STATUS.SUCCESS,
          },
          currentTeam: {
            ...state.currentTeam,
            status: action.payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          updateParticipantStatus: STATUS.SUCCESS,
        },
        currentParticipant: {
          ...state.currentParticipant,
          status: action.payload.status,
        },
      };
    }

    case types.UPDATE_USER_STATUS: {
      const { payload } = action;
      if (state.currentParticipant.username === payload.username && payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          currentParticipant: {
            ...state.currentParticipant,
            status: payload.participantStatus,
            applicationStatus: payload.participantStatus,
          },
        };
      }

      return {
        ...state,
      };
    }

    case types.UPDATE_PARTICIPANT_STATUS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          updateParticipantStatus: STATUS.FAILURE,
        },
      };
    }

    case types.SEND_EMAILS: {
      return {
        ...state,
        status: {
          ...state.status,
          sendEmails: action.payload,
        },
      };
    }

    case types.UPDATE_FILTER:
    case types.UPDATE_FILTER_ITEM: {
      return {
        ...state,
        allIndividuals: {},
        allTeams: {},
      };
    }

    case types.BULK_UPDATE_PARTICIPANT_STATUS: {
      return {
        ...state,
        status: {
          ...state.status,
          bulkUpdateParticipantActionStatus: action.payload.status,
        },
      };
    }

    case types.FETCH_BUILDER_STATS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          currentParticipantPastStats: {
            pastHackathonPrizesWon: payload.data.pastHackathonPrizesWon,
            pastHackathonsAttended: payload.data.pastHackathonsAttended,
            pastHackathonStats: payload.data.pastHackathonStats,
            acceptedFellowships: payload.data.acceptedFellowships,
            acceptedGrants: payload.data.acceptedGrants,
          },
          status: {
            ...state.status,
            currentParticipantPastStatsStatus: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          currentParticipantPastStatsStatus: payload.status,
        },
      };
    }

    case types.UPDATE_NOTE: {
      const { payload } = action;
      const { status } = payload;

      if (status === STATUS.REQUEST) {
        const { note, username } = payload;
        const { currentParticipant, currentTeam } = state;

        if (!isEmpty(currentParticipant) && currentParticipant.username === username) {
          return {
            ...state,
            currentParticipant: {
              ...currentParticipant,
              note,
            },
          };
        }

        if (!isEmpty(currentTeam)) {
          return {
            ...state,
            currentTeam: {
              ...currentTeam,
              members: currentTeam.members.map(member => {
                if (member.username === username) {
                  return {
                    ...member,
                    note,
                  };
                }

                return member;
              }),
            },
          };
        }
      }

      return state;
    }

    case types.FETCH_CURRENT_PARTICIPANT_OR_TEAM_REVIEW_HISTORY: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          currentParticipantOrTeamReviewHistory: payload.data,
          status: {
            ...state.status,
            fetchCurrentParticipantOrTeamReviewHistory: payload.status,
          },
        };
      }
      return {
        ...state,
        currentParticipantOrTeamReviewHistory: [],
        status: {
          ...state.status,
          fetchCurrentParticipantOrTeamReviewHistory: payload.status,
        },
      };
    }

    case types.UPDATE_CHECKLIST:
      if (action.payload.status === STATUS.SUCCESS) {
        const { currentParticipant } = state;
        const { checklistId, checked, updatedAt } = action.payload;
        return {
          ...state,
          currentParticipant: {
            ...currentParticipant,
            checklist:
              findIndex(currentParticipant.checklist, { uuid: checklistId }) === -1 &&
              typeof currentParticipant.checklist !== 'undefined'
                ? [...currentParticipant?.checklist, { uuid: checklistId, value: checked, updatedAt }]
                : currentParticipant?.checklist?.map(item => {
                    if (item.uuid === checklistId) {
                      return {
                        ...item,
                        value: checked,
                        updatedAt,
                      };
                    }

                    return item;
                  }),
          },
        };
      }
      return state;

    case types.LOGOUT_SUCCESS:
      return { ...initialState };
    default:
      return state;
  }
};

export default participant;
