import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Donut = styled.div`
  animation: ${rotate360} 1.2s linear infinite;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3770ff;
  border-radius: 50%;
  display: inline-block;
  height: 48px;
  width: 48px;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  justify-content: center;
`;
