import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { Router } from 'react-router';
import { ThemeProvider } from '@devfolioco/genesis';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

import 'modern-normalize/modern-normalize.css';

import * as Sentry from './helpers/sentry';
import { history } from './helpers/history';
import ErrorBoundary from './components/ErrorBoundary';
import OfflinePlugin from './components/OfflinePlugin';
import Routes from './routes';
import store from './store';

Sentry.init();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <ThemeProvider>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <div css="min-width:1200px; @media screen and (max-width: 768px){ min-width: unset; }">
              <OfflinePlugin>
                <Routes />
              </OfflinePlugin>
            </div>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </Router>
  </Provider>
);

render(<App />, document.getElementById('app'));
