import { Switch, Route, Redirect } from 'react-router';
import Loadable from 'react-loadable';
import React from 'react';
import Title from 'react-document-title';

import { isAuthenticated, isRoot, redirectToSignin } from 'helpers';

import { useIdentifyUser } from '@hooks/useIdentifyUser';
import Loader from '@components/Loader';
import { DEVFOLIO_BASE } from '@constants/environment';
import Loading from './components/Loading';

const Hackathons = Loadable({
  loader: () => import('./views/Hackathons'),
  loading: Loading,
});

const NotFound = Loadable({
  loader: () => import('./views/NotFound'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const Root = Loadable({
  loader: () => import('./views/Root'),
  loading: Loading,
});

const HackathonSetup = Loadable({
  loader: () => import('./views/HackathonSetup'),
  loading: Loading,
});

const VerifyHackathon = Loadable({
  loader: () => import('./views/VerifyHackathon'),
  loading: Loading,
});

const TicketDashboard = Loadable({
  loader: () => import('./views/TicketDashboard'),
  loading: Loading,
});

const EmailEditor = Loadable({
  loader: () => import('./views/EmailEditor'),
  loading: Loading,
});

const SponsorEmails = Loadable({
  loader: () => import('./views/SponsorEmails/SponsorEmails'),
  loading: Loading,
});

/**
 * Use this Route to redirect the user to teh devfolio signin page
 * when he doesn't have required authentication
 */
const RouteToSign = () => {
  redirectToSignin();
  return null;
};

const RedirectToBase = ({ authType }) => {
  React.useEffect(() => {
    document.location.href = `${DEVFOLIO_BASE}${authType}?from=${encodeURIComponent(window.location.origin)}`;
  }, [authType]);
  return null;
};

const RouteProtected = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={props => (isAuthenticated() ? <Component {...props} /> : <RouteToSign />)} />;
};

const RouteRoot = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (isAuthenticated() && isRoot() ? <Component {...props} /> : <Redirect to="/" />)} />
);

const NotFoundWithTitle = () => (
  <Title title="404 | Devfolio">
    <NotFound />
  </Title>
);

const RootWithTitle = props => (
  <Title title="Admin | Devfolio">
    <Root {...props} />
  </Title>
);

const DashboardWithTitle = props => (
  <Title title="Organizer Dashboard | Devfolio">
    <Dashboard {...props} />
  </Title>
);

const CreateHackathonWithTitle = props => (
  <Title title="Create a hackathon | Devfolio">
    <HackathonSetup {...props} />
  </Title>
);

const UpdateHackathonWithTitle = props => (
  <Title title="Update hackathon | Devfolio">
    <HackathonSetup {...props} />
  </Title>
);

const VerifyHackathonWithTitle = props => (
  <Title title="Verify hackathon | Devfolio">
    <VerifyHackathon {...props} />
  </Title>
);

const HackathonsWithTitle = props => (
  <Title title="Organizer Dashboard | Devfolio">
    <Hackathons {...props} />
  </Title>
);

const TicketDashboardWithTitle = props => (
  <Title title="Tickets | Devfolio">
    <TicketDashboard {...props} />
  </Title>
);

const EmailEditorWithTitle = props => (
  <Title title="Emails | Devfolio">
    <EmailEditor {...props} />
  </Title>
);

const SponsorEmailsWithTitle = props => (
  <Title title="Sponsor Emails | Devfolio">
    <SponsorEmails {...props} />
  </Title>
);

const Routes = () => {
  const isUserLoading = useIdentifyUser();

  if (isUserLoading) {
    return <Loader style={{ height: '100vh' }} />;
  }

  return (
    <Switch>
      <RouteProtected exact path="/" component={HackathonsWithTitle} />
      <Route path="/404" render={() => <NotFoundWithTitle />} />
      <Route exact path="/signin" render={() => <RedirectToBase authType="signin" />} />
      <Route exact path="/signup" render={() => <RedirectToBase authType="signup" />} />

      <RouteProtected exact path="/new/:hackathonUuid" component={CreateHackathonWithTitle} />
      <RouteProtected exact path="/update/:hackathonUuid" component={UpdateHackathonWithTitle} />
      <RouteProtected exact path="/verify/:hackathonUuid" component={VerifyHackathonWithTitle} />

      <RouteRoot exact path="/emails" component={EmailEditorWithTitle} />
      <RouteRoot exact path="/sponsor-emails" component={SponsorEmailsWithTitle} />
      <RouteRoot path="/admin" component={RootWithTitle} />
      <RouteRoot path="/admin/:view" component={RootWithTitle} />
      <RouteRoot path="/tickets/:hackathonSlug" component={TicketDashboardWithTitle} />
      <RouteProtected path="/:hackathonSlug" component={DashboardWithTitle} />
      <Route component={NotFoundWithTitle} />
    </Switch>
  );
};

export default Routes;
