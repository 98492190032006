import * as Yup from 'yup';
import moment from 'moment';
import { ERRORS } from './errors';

const eventValidationSchema = Yup.object().shape({
  title: Yup.string().trim().required('Please enter a title.'),
  description: Yup.string().trim(),
  location: Yup.string().trim(),
  url: Yup.string().trim().url('Please enter a valid URL.'),
  googleMapsLocationURL: Yup.string().trim().url('Please enter a valid URL.'),
  type: Yup.string().trim().required('Please select an event type.'),
  groupUUID: Yup.string().trim().required('Please select a group.'),
  startsAt: Yup.date().required(ERRORS.invalidDate),
  endsAt: Yup.date().when('startsAt', (date, schema) => {
    if (date) {
      return schema.min(date, 'Event end date must be greater than event start date.');
    }
    return schema.min(moment(), 'Event end date must be greater than today.');
  }),
});

export default eventValidationSchema;
