import {
  DateTimePicker as AKDateTimePicker,
  DateTimePickerProps as AKDateTimePickerProps,
} from '@atlaskit/datetime-picker';
import moment from 'moment-timezone';
import { PARTIAL_TIME_FORMAT_MATCH_REGEX, TIME_PICKER_TIMES, DATE_TIME_PICKER_FORMAT } from '@constants';
import React from 'react';
import './style.css';

const getCreateLabel = (input: string) => {
  const formatLabel = 'HH:MM';
  const formatHint = formatLabel.length > input.length ? ' (24 hours)' : '';
  if (isPartialTimeMatching(input)) {
    return (
      <>
        <strong>{input}</strong>
        <span style={{ color: 'rgba(0,0,0,0.5)' }}>{`${formatLabel.slice(input.length)}${formatHint}`}</span>
      </>
    );
  }
  return <span style={{ color: 'rgba(0,0,0,0.5)' }}>HH:MM (24 hours)</span>;
};

const isPartialTimeMatching = (input: string) => input.match(PARTIAL_TIME_FORMAT_MATCH_REGEX)?.[0] !== '';

const getDateTimePickerProps = (isError: boolean, isDisabled: boolean): AKDateTimePickerProps => ({
  dateFormat: DATE_TIME_PICKER_FORMAT.date,
  timeFormat: DATE_TIME_PICKER_FORMAT.time,
  datePickerSelectProps: {
    placeholder: 'DD/MM/YYYY',
  },
  timePickerSelectProps: {
    placeholder: 'HH:MM(24hrs)',
    createOptionPosition: 'first',
    formatCreateLabel: input => getCreateLabel(input),
  },
  times: TIME_PICKER_TIMES,
  innerProps: {
    style: Object.assign(
      {
        backgroundColor: isError ? '#F4E7E7' : '#FCFCFC',
        borderRadius: 4,
        color: '#273339',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: 18,
        fontWeight: 400,
        height: 48,
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 3,
        border: '2px solid #E4EAEB',
      },
      isDisabled && {
        backgroundColor: '#E4EAEB',
      },
      isError && {
        border: '2px solid #FF3838',
      }
    ),
  },
});

interface DateTimePickerProps {
  /** The change handler for date time picker */
  onChange?: (name: string, dateTime: string) => void;

  /** The blur handler for the picker */
  onBlur?: (name: string) => void;

  /** Name of the component */
  name: string;

  /** The date time value */
  value: string;

  /** The timezone the date is in */
  timezone: string;

  /** Whether the picker is disabled or not */
  disabled?: boolean;

  /** Show error state */
  isInvalid?: boolean;
}

const DateTimePicker = ({
  isInvalid,
  name,
  value,
  timezone,
  disabled,
  onChange,
  onBlur,
}: DateTimePickerProps): React.ReactElement => {
  const handleChange = (dateTime: string) => {
    if (typeof onChange === 'function' && dateTime.length > 0) {
      onChange(name, dateTime);
    }
  };

  const handleBlur = () => {
    if (typeof onBlur === 'function') {
      onBlur(name);
    }
  };

  /**
   * The date picker automatically converts the date to the current timezone, so to accommodate for the
   * change we convert the UTC date received via props first to the given timezone, and
   * then replace it with the user's timezone without updating the date.
   * @example
   * If we the get following date and timezone -
   * `Thu May 27 2021 10:30:00 GMT-0700`, `Asia/Calcutta`
   *
   * First convert the date to the given timezone
   * Thu May 27 2021 23:00:00 GMT+5:30
   *
   * Then replace the it with the user's timezone (let's assume its America/New York)
   * without changing the date -
   * Thu May 27 2021 23:00:00 GMT-4:00
   *
   * P.S. - The dates were converted to string format in example for better readability
   */
  const datePickerValue = value ? moment(value).tz(timezone).tz(moment.tz.guess(), true).toISOString(true) : '';

  return (
    <AKDateTimePicker
      id={name}
      value={datePickerValue}
      onChange={handleChange}
      onBlur={handleBlur}
      timeIsEditable
      {...getDateTimePickerProps(isInvalid ?? false, !!disabled)}
      isDisabled={!!disabled}
    />
  );
};

export default DateTimePicker;
