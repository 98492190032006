import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';

const initialState = {
  user: {
    displayName: null,
    displayShortName: null,
    email: null,
    firstName: null,
    lastName: null,
    profileImageUrl: null,
    username: null,
    uuid: null,
  },
  hackathons: [],
  status: {
    fetchUserInfo: STATUS.READY,
  },
};

const hackathon = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USER_INFO: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          user: { ...initialState?.user, ...payload?.user },
          status: {
            ...state.status,
            fetchUserInfo: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchUserInfo: payload.status,
        },
      };
    }

    case types.FETCH_USER_HACKATHONS: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hackathons: [...initialState?.hackathons, ...payload?.hackathons],
          status: {
            ...state.status,
            fetchUserHackathons: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchUserHackathons: payload.status,
        },
      };
    }

    // For showing the 500 page
    case types.SERVER_ERROR: {
      return {
        ...state,
        serverError: true,
      };
    }

    case types.LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
};

export default hackathon;
