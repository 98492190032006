/**
 * @author gitryder
 *
 * We could write the themes constant like this in the
 * future, but currently since we just need the name and uuid,
 * using the object-like structure will help with typing
 * function parameters.

  interface HackathonTheme {
    uuid: string;
    name: string;
    verified: boolean;
  }

  const HACKATHON_THEMES: HackathonTheme[] = [
    { uuid: '', name: 'Blockchain', verified: true },
  ];
*/

const DEFAULT_HACKATHON_THEME_INDEX = 0;

const HACKATHON_THEMES = {
  'No Restrictions': 'a15061e97ec148009d7e3993eafe047b',
  Blockchain: '106931c0a88e4e4893333524fe66ef5d',
  Design: '6d18c73b2609447098e347382e471d44',
  FinTech: '10f3c52e650b4229a2dc2edcebff20db',
  'AI/ML': '9d287d0c0870485a838efa02d5ecc06b',
  HealthTech: '75d24005cd344ddba77d53051adc4f2f',
  'VR/AR': 'fbbfe4bb3c6b44b8bae959ffe8851b9d',
  'Future Mobility': '7804fc6c034546d593c419e8f792c7f8',
  'IoT/Hardware': '37d469ffc8b247108c14ffacc203d6bb',
} as const;

type HackathonThemeName = keyof typeof HACKATHON_THEMES;

/**
 * @returns All available hackathon themes for organizers to choose from.
 */
export const getThemeOptions = (): string[] => {
  return Object.keys(HACKATHON_THEMES);
};

/**
 * @returns The default hackathon theme.
 */
export const getDefaultTheme = (): string => {
  return Object.keys(HACKATHON_THEMES)[DEFAULT_HACKATHON_THEME_INDEX];
};

/**
 *
 * @param theme The name of the theme that you want the UUID of.
 * @returns A string representing the UUID of the theme specified.
 */
export const getThemeUUIDFromName = (theme: HackathonThemeName): string => HACKATHON_THEMES[theme];
