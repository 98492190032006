import React from 'react';

import { Donut, Container } from './style';

class Loader extends React.PureComponent {
  componentDidMount() {
    document.body.style.cursor = 'wait';
  }

  componentWillUnmount() {
    document.body.style.cursor = 'default';
  }

  render() {
    const { style } = this.props;
    return (
      <Container style={style}>
        <Donut />
      </Container>
    );
  }
}

export default Loader;
