/* eslint-disable max-len */
import invert from 'lodash/invert';

import Dribbble from 'public/assets/dribbble.png';
import GitHub from 'public/assets/github.png';
import Instagram from 'public/assets/instagram.png';
import Kaggle from 'public/assets/kaggle.png';
import LinkedIn from 'public/assets/linkedin.png';
import Medium from 'public/assets/medium.png';
import StackOverflow from 'public/assets/stackoverflow.png';
import Twitter from 'public/assets/twitter.png';

import { API_ROOT, EXTERNAL_EMAIL_SEND_URL, NODE_ENV } from 'constants/environment';

export const STATE = {
  ADMIN: 'admin',
  ANALYTICS: 'analytics',
  AUTHENTICATION: 'authentication',
  CHECKIN: 'checkin',
  FEEDBACK: 'feedback',
  FILTER: 'filter',
  HACKATHON: 'hackathon',
  ORGANIZER: 'organizer',
  PARTICIPANT: 'participant',
  REGISTRATION: 'registration',
  SQUAD: 'squad',
  USER: 'user',
  ROOT: 'root',
};

export const STATUS_COLOR = {
  null: 'grey-6',
  accept_sent: 'white-0',
  accept: 'white-0',
  submit: 'white-0',
  reject: 'white-0',
  reject_sent: 'white-0',
  withdraw: 'grey-0',
  waitlist: 'white-0',
  waitlist_sent: 'white-0',
  rsvp: 'white-0',
  check_in: 'white-0',
  reimburse: 'white-0',
};

export const STATUS_BACKGROUND_COLOR = {
  null: 'grey-4',
  accept_sent: 'gold-0',
  accept: 'gold-0',
  submit: 'yellow-4',
  reject: 'red-4',
  reject_sent: 'red-4',
  withdraw: 'grey-7',
  waitlist: 'blue-4',
  waitlist_sent: 'blue-4',
  rsvp: 'blue-4',
  check_in: 'blue-4',
  reimburse: 'teal-4',
};

export const STATUS_LABEL = {
  null: 'In Progress',
  accept_sent: 'Accepted',
  accept: 'Accepted',
  submit: 'Submitted',
  reject: 'Rejected',
  reject_sent: 'Rejected',
  withdraw: 'Withdrawn',
  waitlist: 'Waitlisted',
  waitlist_sent: 'Waitlisted',
  rsvp: "RSVP'd",
  check_in: 'Checked In',
  reimburse: 'Reimbursed',
};

export const ONLINE_STATUS_COLOR = {
  submit: 'white-0',
  publish: 'white-0',
};

export const ONLINE_STATUS_BACKGROUND_COLOR = {
  submit: 'yellow-4',
  publish: 'blue-4',
};

export const ONLINE_STATUS_LABEL = {
  submit: 'Profile Submitted',
  publish: 'Project Submitted',
};

export const GENDER = {
  f: 'Female',
  m: 'Male',
  t: 'Transgender',
  nb: 'Non-binary, genderqueer, or gender non-conforming',
  n: 'Prefer not to say',
};

export const SHIRT_SIZE = {
  xs: 'XS',
  s: 'S',
  m: 'M',
  l: 'L',
  xl: 'XL',
  xxl: 'XXL',
  xxxl: 'XXXL',
};

export const DEGREE_TYPE = {
  'high school': 'High School',
  associate: 'Associate',
  bachelor: 'Bachelors',
  master: 'Masters',
  phd: 'PhD.',
};

export const HACKER_TYPE = {
  designer: 'Designer',
  developer: 'Developer',
  hardware: 'Hardware Engineer',
};

export const HACKATHON_TYPE = [
  {
    label: 'Offline',
    value: 'offline',
  },
  {
    label: 'Online',
    value: 'online',
  },
  {
    label: 'Online With Application Review',
    value: 'online_with_review',
  },
];

export const ALL_EMAIL_TEMPLATES = [
  'acceptance_online',
  'rejection',
  'team_acceptance_online',
  'rsvp',
  'acceptance_offline',
  'team_acceptance_offline',
  'submission_reminder_individual',
  'submission_reminder_team',
  'user_hackathon_reminder',
];

/**
 *
 * @param { This will be a boolean
 *  true: Online hackathon
 *  false: Offline hackathon
 * } isOnline
 * @returns { Array } An array of objects with 'label' and 'value' key as required by Select component from genesis
 */

export const EMAIL_TEMPLATES_SELECT_ARRAY = isOnline => {
  return [
    ...(isOnline
      ? [
          {
            label: 'Acceptance',
            value: 'acceptance_online',
          },

          {
            label: 'Team Acceptance',
            value: 'team_acceptance_online',
          },
        ]
      : [
          {
            label: 'Acceptance',
            value: 'acceptance_offline',
          },
          {
            label: 'Team Acceptance',
            value: 'team_acceptance_offline',
          },
        ]),
    {
      label: 'RSVP',
      value: 'rsvp',
    },
    {
      label: 'Rejection',
      value: 'rejection',
    },
    {
      label: 'Individual User reminder',
      value: 'submission_reminder_individual',
    },
    {
      label: 'Team reminder',
      value: 'submission_reminder_team',
    },
    {
      label: 'Upcoming Hackathon reminder',
      value: 'user_hackathon_reminder',
    },
  ];
};

export const SERVER_PARAM = {
  /**
   * Client to Server Param Mapping
   * e.g. firstName => first_name
   */
  accessToken: 'access_token',
  addressLineOne: 'line_1',
  addressLineTwo: 'line_2',
  bio: 'bio',
  city: 'city',
  country: 'country',
  degreeType: 'degree_type',
  dob: 'dob',
  educationID: 'education_id',
  educationInstitution: 'college',
  email: 'email',
  emergencyContactName: 'emergency_contact_name',
  emergencyContactNumber: 'emergency_contact_number',
  experience: 'experience',
  fieldOfStudy: 'education_field',
  firstName: 'first_name',
  gender: 'gender',
  gitHubProfile: 'github_profile',
  hackerType: 'domain_expertise',
  hasNoFormalEducation: 'is_education',
  isEmailVerified: 'email_verified',
  isPhoneNumberVerified: 'phone_number_verified',
  isStudent: 'current_college',
  lastName: 'last_name',
  monthOfGraduation: 'graduation_month',
  otherProfiles: 'profiles',
  phoneNumber: 'phone_number',
  pincode: 'zip',
  profileImageURL: 'profile_image',
  referralCode: 'ref_code',
  referralCount: 'ref_count',
  refreshToken: 'refresh_token',
  resumeURL: 'resume',
  shirtSize: 't_shirt_size',
  skills: 'user_skill',
  state: 'state',
  status: 'status',
  userID: 'user_id',
  username: 'username',
  yearOfGraduation: 'graduation_year',
  ethereumAddress: 'ethereum_address',
  discordChannelJoined: 'discord_channel_joined',
};

/**
 * Server to Client Param Mapping
 * e.g. first_name => firstName
 */
export const CLIENT_PARAM = invert(SERVER_PARAM);

export const INTEGRATION = {
  GITHUB: 'github_integration',
};

export const PROFILES = {
  BEHANCE: 'Behance',
  DRIBBBLE: 'Dribbble',
  FACEBOOK: 'Facebook',
  GITHUB: 'GitHub',
  KAGGLE: 'Kaggle',
  LINKEDIN: 'LinkedIn',
  OTHERS: 'other_',
  STACKOVERFLOW: 'Stack Overflow',
  TWITTER: 'Twitter',
};

export const APPLY_STATUS = {
  ACCEPT_SENT: 'accept_sent',
  ACCEPT: 'accept',
  CHECK_IN: 'check_in',
  NOT_SUBMIT: null,
  REIMBURSE: 'reimburse',
  REJECT_SENT: 'reject_sent',
  REJECT: 'reject',
  RSVP: 'rsvp',
  SUBMIT: 'submit',
  TEAM_NOT_FORMED: 'team_not_formed',
  WAITLIST_SENT: 'waitlist_sent',
  WAITLIST: 'waitlist',
  WITHDRAW: 'withdraw',
};

export const PROJECT_STATUS = {
  PROJECT_PUBLISHED: 'project_publish',
};

export const TEAM_INVITE_STATUS = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  REINVITE: 'reinvite',
};

export const ACTION_STATUS = {
  FAILURE: 'Request Failed',
  READY: 'Ready',
  REQUEST: 'In Process',
  SUCCESS: 'Request Successful',
};

export const STATS = {
  ACCEPT_NOT_SENT: 'accept',
  ACCEPT_SENT: 'accept_sent',
  ACCEPTED: 'accepted',
  APPLIED: 'applied',
  REJECT_NOT_SENT: 'reject',
  REJECT_SENT: 'reject_sent',
  REJECTED: 'rejected',
  UNREVIEWED: 'submit',
};

export const SERVER_INPUT_TYPE = {
  BOOL: 'bool',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
  FILE: 'file',
  INPUT: 'input',
  MULTI_SELECT: 'multi_select',
  TEXTAREA: 'textarea',
  TRUE_ONLY: 'true_only',
};

export const ATTRIBUTES = {
  address_attributes: 'zip,city,state,country',
  college_attributes: 'name',
  company_attributes: 'name',
  education_attributes: 'degree_type,graduation_year',
  education_field_attributes: 'name',
  experience_attributes: 'uuid',
  hackathon_checklist_attributes: 'name,uuid',
  hackathon_scope_extra_attributes: 'name',
  profile_attributes: 'name',
  skill_attributes: 'name',
  user_hackathon_checklist_attributes: 'value,note,updated_at',
  user_attributes:
    'first_name,last_name,username,dob,email,gender,phone_number,email_verified,phone_number_verified,bio,uuid,status',
  user_extra_attributes:
    't_shirt_size,domain_expertise,emergency_contact_number,emergency_contact_name,resume,is_education',
  user_hackathon_attributes:
    'uuid,status,ticket,is_travel,city,state,country,payment_mode,payment_mode_value,reimbursement_amount,currency',
  user_hackathon_extra_attributes: 'value',
};

export const VOLUNTEER_ATTRIBUTES = {
  hackathon_checklist_attributes: 'name,uuid',
  profile_attributes: 'name',
  user_attributes: 'first_name,last_name,username,dob,email,uuid,bio,status',
  user_extra_attributes: 'allergies,resume',
  user_hackathon_checklist_attributes: 'value',
  user_hackathon_attributes:
    'uuid,status,ticket,is_travel,city,state,country,payment_mode,payment_mode_value,reimbursement_amount,currency',
};

export const DETAIL_CARD_ATTRIBUTES = {
  profile_attributes: 'name',
  user_attributes: 'first_name,last_name,username,dob,email,uuid,bio,short_bio,status',
  user_extra_attributes: 'resume',
  user_hackathon_attributes: 'uuid,status,ticket,note,ai_assisted_score,ai_assisted_status,ai_assisted_reason',
  user_hackathon_extra_attributes: 'uuid,value',
  hackathon_scope_extra_attributes: 'uuid,name,required,desc,type',
};

export const ROLE = {
  ADMIN: 'admin',
  GUEST: 'guest',
  HACKER: 'hacker',
  ORGANIZER: 'organizer',
  ROOT: 'root',
  SQUAD: 'squad',
  VOLUNTEER: 'volunteer',
};

export const SCOPES = {
  ABOUT: 'user',
  EDUCATION: 'education',
  SKILLS: 'skills',
  LINKS: 'profiles',
  CONTACT: 'contact',
  EXTRA_FIELDS: 'extraFields',
  USER_HACKATHON: 'user_hackathon',
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const CSV_DATA = [
  {
    label: 'First Name',
    value: 'first_name',
  },
  {
    label: 'Last Name',
    value: 'last_name',
  },
  {
    label: 'T-shirt Size',
    value: 't_shirt_size',
  },
  {
    label: 'Gender',
    value: 'gender',
  },
  {
    label: 'College',
    value: 'college',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Team Name',
    value: 'team_name',
  },
  {
    label: 'Devfolio Profile',
    value: 'devfolio',
  },
  {
    label: 'Submission URL',
    value: 'submission_link',
  },
  {
    label: 'Tracks',
    value: 'tracks',
  },
  {
    label: 'Winners',
    value: 'winners',
  },
  {
    label: 'Custom Questions',
    value: 'extra_questions',
  },
  {
    label: 'Project Links',
    value: 'project_links',
  },
  {
    label: 'GitHub Profile Link',
    value: 'github_profile',
  },
];

export const UNITS = [
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
];

export const DAYS = [1, 2, 3, 4, 5, 6, 7];
export const HOURS = [4, 8, 12, 16, 20];
export const DEADLINE_EXTENSION_TIME = ['15 minutes', '30 minutes', '1 hour', '4 hours', '12 hours', '24 hours'];

// segment type for Online hacakthon
// Need to add Project submitted here
export const ONLINE_ANNOUNCEMENT_SEGMENTS = [
  {
    label: 'Applied',
    value: `${APPLY_STATUS.SUBMIT}`,
  },
  {
    label: 'Project Submitted',
    value: `${PROJECT_STATUS.PROJECT_PUBLISHED}`,
  },
];

export const ANNOUNCEMENT_SEGMENTS = [
  {
    label: 'Accepted',
    value: APPLY_STATUS.ACCEPT_SENT,
  },
  {
    label: 'Not Accepted',
    value: APPLY_STATUS.REJECT_SENT,
  },
  {
    label: 'Under Review',
    value: `${APPLY_STATUS.ACCEPT},${APPLY_STATUS.REJECT},${APPLY_STATUS.WAITLIST}`,
  },
  {
    label: 'Waitlisted',
    value: APPLY_STATUS.WAITLIST,
  },
  {
    label: 'Applied',
    value: `${APPLY_STATUS.SUBMIT}`,
  },
  {
    label: 'RSVPd',
    value: APPLY_STATUS.RSVP,
  },
  {
    label: 'Checked In',
    value: `${APPLY_STATUS.CHECK_IN},${APPLY_STATUS.REIMBURSE}`,
  },
  {
    label: 'Project Submitted',
    value: `${PROJECT_STATUS.PROJECT_PUBLISHED}`,
  },
];

export const PROFILE_LOGOS = [
  { name: 'LinkedIn', url: 'https://linkedin.com', logo: LinkedIn },
  { name: 'GitHub', url: 'https://github.com', logo: GitHub },
  { name: 'Medium', url: 'https://medium.com', logo: Medium },
  { name: 'Dribbble', url: 'https://dribbble.com', logo: Dribbble },
  { name: 'Kaggle', url: 'https://kaggle.com', logo: Kaggle },
  { name: 'StackOverflow', url: 'https://stackoverflow.com', logo: StackOverflow },
  { name: 'Instagram', url: 'https://instagram.com', logo: Instagram },
  { name: 'Twitter', url: 'https://twitter.com', logo: Twitter },
];

export const ORDER = {
  DESC: 'DESC',
  ASC: 'ASC',
};

export const TAB_TO_ROUTE = {
  Overview: 'overview',
  Review: 'review',
  Volunteer: 'checkins',
  Admin: 'admin',
  Logistics: 'logistics',
  Feedback: 'feedback',
  Judging: 'judging',
  Sponsor_Judging: 'sponsor-judging',
  Onchain_Creds: 'onchain-creds',
};

export const TAB_TO_LABEL = {
  Overview: 'Overview',
  Review: 'Review',
  Volunteer: 'Checkins',
  Admin: 'Admin',
  Logistics: 'Logistics',
  Feedback: 'Feedback',
  Judging: 'Judging',
  Sponsor_Judging: 'Sponsor Judging',
  Onchain_Creds: 'Onchain Credentials',
};

export const TABS_WITHOUT_ORGANIZER = [
  'Overview',
  'Review',
  'Volunteer',
  'Admin',
  'Logistics',
  'Feedback',
  'Judging',
  'Sponsor_Judging',
  'Onchain_Creds',
];
export const TABS_FOR_ONLINE_WITHOUT_REVIEW = [
  'Overview',
  'Review',
  'Admin',
  'Feedback',
  'Squad',
  'Logistics',
  'Judging',
  'Sponsor_Judging',
  'Onchain_Creds',
];

export const TABS = [...TABS_WITHOUT_ORGANIZER, 'Squad'];

export const TABS_DESCRIPTION = [
  {
    name: 'Overview',
    description: 'View general hackathon application and demographic data.',
  },
  {
    name: 'Judging',
    description: 'Look at the details of all the judges, the projects scored by the judges',
  },
  {
    name: 'Sponsor_Judging',
    description:
      'Look at the details of all the sponsor judges, partners and the projects marked as winners by the judges',
  },
  {
    name: 'Review',
    description:
      // eslint-disable-next-line max-len
      'Look at the details of all the applicants, review their profile and accept or reject from attending the hackathon.',
  },
  { name: 'Volunteer', description: 'Check-in teams and hackers on the day of the hackathon.' },
  { name: 'Admin', description: 'Perform hackathon administrative tasks.' },
  { name: 'Logistics', description: 'Details about hackathon logistics like T-shirt size etc.' },
  { name: 'Feedback', description: 'Look at the feedback received from the hackers.' },
  { name: 'Onchain_Creds', description: 'Look at the details of all the onchain credentials and other stats.' },
];

export const AMZ_ACL = {
  PRIVATE: 'private',
  PUBLIC_READ: 'public-read',
  PUBLIC_READ_WRITE: 'public-read-write',
  AUTHENTICATED_READ: 'authenticated-read',
};

export const CURRENCY = [
  {
    label: '₹ INR',
    value: 'INR',
  },
  {
    label: '$ USD',
    value: 'USD',
  },
];

export const URL = {
  CLEARBIT_AUTOCOMPLETE: `${API_ROOT}miscellaneous/clearbit/autocomplete/companies/suggest?query=`,
  EXTERNAL_EMAIL_SEND: EXTERNAL_EMAIL_SEND_URL,
};

export const DEVFOLIO_COC = 'https://devfolio.co/code-of-conduct';

export const SUBDOMAIN_REGEX = /^[A-Za-z](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])$/;

export const PARTIAL_TIME_FORMAT_MATCH_REGEX = /(?:[0-2]|$)(?:[0-9]|$)(?::|$)(?:[0-5]|$)(?:[0-9]|$)(?:\s|$)(?:[a,p]|$)(?:m|$)/;

/*
Generated using -
const getAllTimes = () => {
  let initialDate = moment('00:00', 'HH:mm');
  const times = [];
  while (initialDate.format('HH:mm') !== '23:45') {
    times.push(initialDate.format('HH:mm'));
    initialDate = moment(initialDate).add(15, 'minutes');
  }
  times.push('23:45');
  times.push('23:59');
  return times;
};
 */
export const TIME_PICKER_TIMES = [
  '00:00',
  '00:15',
  '00:30',
  '00:45',
  '01:00',
  '01:15',
  '01:30',
  '01:45',
  '02:00',
  '02:15',
  '02:30',
  '02:45',
  '03:00',
  '03:15',
  '03:30',
  '03:45',
  '04:00',
  '04:15',
  '04:30',
  '04:45',
  '05:00',
  '05:15',
  '05:30',
  '05:45',
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
  '23:15',
  '23:30',
  '23:45',
  '23:59',
];

export const DATE_TIME_PICKER_FORMAT = {
  date: 'DD/MM/YYYY',
  time: 'HH:mm',
};

export const EMAIL_TO_CSV = 'EMAIL_TO_CSV';

export const OPPORTUNITIES = [
  'Design',
  'Frontend Development',
  'Backend Development',
  'Mobile Development',
  'Blockchain',
];

export const EMAIL_ATTACHMENT_SIZE = 4194304;
export const QUIZ_STAGES = {
  INFO: 'INFO',
  QUESTIONS: 'QUESTIONS',
  CUTOFF: 'CUTOFF',
};

export const QUESTION_STATUS = {
  DRAFT: 'draft',
  PUBLISH: 'publish',
  BLACKLIST: 'blacklist',
};

export const SOURCE_TYPES = ['None', 'Article', 'Book', 'Video', 'Url'];

export const URL_SOURCE_TYPES = ['Article', 'Video', 'Url'];

export const HACKATHON_PROPERTIES = [
  'about',
  'applyMode',
  'approxParticipants',
  'city',
  'country',
  'cover',
  'endsAt',
  'isOnline',
  'location',
  'maxTeamSize',
  'minTeamSize',
  'name',
  'startsAt',
  'state',
  'status',
  'subdomain',
  'tagline',
  'timezone',
  'type',
];
export const HACKATHON_SETTINGS_PROPERTIES = [
  'logo',
  'primaryColor',
  'contactEmail',
  'applicationsOpenAt',
  'applicationsCloseAt',
  'allowTravelReimbursement',
  'paymentMode',
  'eligibleEntities',
  'schwagProcessState',
  'rsvpBeforeTime',
  'review',
  'website',
  'twitter',
  'facebook',
  'instagram',
  'linkedin',
  'discord',
  'medium',
  'slack',
  'telegram',
  'farcaster',
  'coc',
  'winnerAnnounceAt',
  'whitelistDomain',
  'womenOnly',
  'judgingEnabled',
  'leaderboardEnabled',
  'discordGuildID',
  'discordRole',
  'discordCheckinEnabled',
  'discordBotChannelName',
  'quadraticVotingEnabled',
  'quadraticVotingEndsAt',
  'quadraticVotingPrizePool',
  'quadraticVotingStartsAt',
  'quadraticVotingResultsAnnounced',
  'quadraticVotingChain',
  'quadraticVotingCurrency',
  'referralLeaderboardPrizeURL',
  'midHackathonCheckinEnabled',
  'midHackathonCheckinStartsAt',
  'midHackathonCheckinEndsAt',
];

export const EMPTY_EXTRA_FIELD = {
  name: '',
  type: 'input',
  value: '',
  desc: '',
  file_type: '',
  placeholder: '',
  required: false,
  role: 'hacker',
};

export const EMPTY_CHECKLIST_ITEM = {
  name: '',
  type: 'bool',
};

export const EXTRAFIELD_INPUT_TYPES = {
  BOOL: 'bool',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
  FILE: 'file',
  INPUT: 'input',
  MULTI_SELECT: 'multi_select',
  TEXTAREA: 'textarea',
  TRUE_ONLY: 'true_only',
};

export const FIELD_TYPES = [
  { label: 'Short answer text', value: 'input' },
  { label: 'Long answer text', value: 'textarea' },
  { label: 'File Upload', value: 'file' },
  { label: 'Radio Buttons', value: 'checkbox' },
  { label: 'Multi Select', value: 'multi_select' },
  { label: 'True/False Checkbox', value: 'bool' },
  // { label: 'True Only Checkbox', value: 'true_only' },
];

export const FILE_TYPES = [
  { label: 'PDF', value: 'pdf' },
  { label: 'PNG', value: 'png' },
  { label: 'JPEG', value: 'jpeg' },
  { label: 'JPG', value: 'jpg' },
];

export const TEAM_TAB_ROLE = 'ewogICJpbiI6IFsib3JnYW5pemVyIiwgInNxdWFkIl0KfQ==';

export const ELASTIC_SEARCH_INDEX = {
  PROD: {
    HACKATHONS: 'prod-hackathons',
    PROJECTS: 'prod-projects',
    SKILLS: 'prod-skills',
    COLLEGES: 'prod-colleges',
    PARTICIPANTS: 'prod-participants',
  },
  DEV: {
    HACKATHONS: 'dev-hackathons',
    PROJECTS: 'dev-projects',
    SKILLS: 'dev-skills',
    COLLEGES: 'dev-colleges',
    PARTICIPANTS: 'dev-participants',
  },
};

export const ELASTIC_SEARCH_SUGGEST = {
  SKILLS: 'skill_suggest',
  COLLEGES: 'full_suggest',
  HACKATHONS: 'full_suggest',
  PROJECTS: 'full_suggest',
  PARTICIPANTS: {
    TEAM: 'team_suggest',
    USER: 'user_suggest',
    FULL: 'full_suggest',
  },
};

export const IS_PROD = NODE_ENV === 'production';

export const OTHER_LINK = 'other';

export const APPLICATION_FIELDS = {
  [SCOPES.ABOUT]: {
    [SERVER_PARAM.firstName]: 'First and last name',
    [SERVER_PARAM.bio]: 'Bio',
    [SERVER_PARAM.gender]: 'Gender',
    // Removed DOB from profile fields
    // [SERVER_PARAM.dob]: 'Date of Birth',
    [SERVER_PARAM.shirtSize]: 'T-Shirt Size',
    [SERVER_PARAM.ethereumAddress]: 'Ethereum Wallet',
  },
  [SCOPES.EDUCATION]: 'All Education details',
  [SCOPES.SKILLS]: {
    [SERVER_PARAM.hackerType]: 'Domain Expertise',
    [SERVER_PARAM.skills]: 'Skills',
    [SERVER_PARAM.resumeURL]: 'Resume',
    [SERVER_PARAM.experience]: 'Work Experience',
  },
  [SCOPES.LINKS]: {
    GitHub: 'GitHub',
    Dribbble: 'Dribbble',
    Behance: 'Behance',
    'Stack Overflow': 'Stack Overflow',
    LinkedIn: 'LinkedIn',
    Kaggle: 'Kaggle',
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    Topcoder: 'Topcoder',
    [OTHER_LINK]: 'At least one link is required',
  },
  [SCOPES.CONTACT]: {
    [SERVER_PARAM.phoneNumber]: 'Phone Number',
    [SERVER_PARAM.email]: 'Email',
    [SERVER_PARAM.city]: 'City',
    [SERVER_PARAM.emergencyContactName]: 'Emergency Contact Details',
    // [SERVER_PARAM.emergencyContactNumber]: 'Emergency Contact Number',
  },
  [SCOPES.USER_HACKATHON]: {
    [SERVER_PARAM.discordChannelJoined]: 'Join Discord Server',
  },
};

export const APPLICATION_TITLE = {
  [SCOPES.ABOUT]: 'About',
  [SCOPES.EDUCATION]: 'Education',
  [SCOPES.SKILLS]: 'Experience',
  [SCOPES.LINKS]: 'Online Profiles',
  [SCOPES.CONTACT]: 'Contact',
  [SCOPES.USER_HACKATHON]: 'Other',
};

export const VERIFIED_STATUS = [
  {
    label: 'Verified',
    value: true,
  },
  {
    label: 'Unverified',
    value: false,
  },
  {
    label: 'All',
    value: 'none',
  },
];

export const AMZ_HEADER = 'x-goog-acl';

export const SAVE = {
  /**
   * Saving states. PROGRESS indicates the request has been
   * dispatched, and either SUCCESS or FAILURE could be expected
   */
  FAILURE: "Couldn't Save",
  PROGRESS: 'Saving Changes',
  SUCCESS: 'All Changes Saved',
};

// This is just a super higher limit of.
// If someone is hitting more than this then it's probably a spam.

export const MAX_EXTRA_FEILDS = 30;

export const MAX_PRIZES = 100;

export const MAX_JUDGES = 300;

export const MAX_SPONSOR_TIERS = 10;

export const MAX_SPONSORS = 50;

export const HACKATHON_PROJECT_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'publish',
};

export const SEARCH_KEYS = {
  ADMIN: 'admin',
  REVIEW: 'review',
  VOLUNTEER: 'volunteer',
};

export const EMPTY_EMAIL_TEMPLATES_OBJECT = {
  acceptance_offline: null,
  acceptance_offline_data: null,
  acceptance_offline_subject: null,
  acceptance_online: null,
  acceptance_online_data: null,
  acceptance_online_subject: null,
  rejection: null,
  rejection_data: null,
  rejection_subject: null,
  rsvp_data: null,
  rsvp_subject: null,
  team_acceptance_offline: null,
  team_acceptance_offline_data: null,
  team_acceptance_offline_subject: null,
  team_acceptance_online: null,
  team_acceptance_online_data: null,
  team_acceptance_online_subject: null,
};

export const OTP_LENGTH = 6;

export const PARTNER_PRIZES = {
  tiers: [
    {
      name: '',
      width: 200,
      name_empty: true,
      position: 1,
      sponsors: [
        {
          name: 'ETHIndia',
          position: 1,
          about: `ETHIndia is empowering the Ethereum Community through its various initiatives including hackathons, fellowships, grants, and more!`,
          logo: 'content/c7839676bfcc4363b7a42fcacb52eaf3/70095ac7-8485-49ab-9117-342196b48c67.png',
          company_name: 'ETHIndia',
          company_domain: 'https://ethindia.co/',
          tracks: [
            {
              name: 'Ethereum Track',
              description: "Select this track if you're building on Ethereum to be eligible for the prizes!",
              prizes: [
                {
                  name: 'Best hack built on Ethereum',
                  amount: 100,
                  quantity: 1,
                  desc: 'Winner of this track receives $100 in prizes from ETHIndia',
                  is_partner_prize: true,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const PARTNER_JUDGES = [];

export const LOCATION = { lat: 28.6448, lng: 77.216721 };
